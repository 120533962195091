import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WizardPortalService } from '@consumer-core/services/wizard-portal.service';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';

import { ClaimControlsModule } from '@shared/modules/claim-controls/claim-controls.module';
import {
  ContactMethodSelectComponent,
} from '@shared/standalone/claim-controls/contact-method-select/contact-method-select.component';
import {
  ContactTimeSelectNewComponent,
} from '@shared/standalone/claim-controls/contact-time-select-new/contact-time-select-new.component';
import {
  LanguageSelectNewComponent,
} from '@shared/standalone/claim-controls/language-select-new/language-select-new.component';

import { WizardStore } from '../../../../core/stores/wizard.store';
import { SubmitBtnDirective } from '../../../directives/submit-btn.directive';
import { EmailRadioGroupComponent } from '../../email-radio-group/email-radio-group.component';
import { FormInputNewComponent } from '../../form-controls/form-input-new/form-input-new.component';

interface IWizardContactInfoMobileState {
  contactPreferencesCollapsed: boolean;
}

@Component({
  selector: 'app-wizard-contact-info-mobile',
  standalone: true,
  imports: [
    CommonModule,
    FormInputNewComponent,
    ReactiveFormsModule,
    EmailRadioGroupComponent,
    MatButtonModule,
    PortalModule,
    MatTooltipModule,
    MatIconModule,
    SubmitBtnDirective,
    ClaimControlsModule,
    LanguageSelectNewComponent,
    ContactMethodSelectComponent,
    ContactTimeSelectNewComponent,
    LetDirective,
  ],
  templateUrl: './wizard-contact-info-mobile.component.html',
  styleUrls: ['./wizard-contact-info-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class WizardContactInfoMobileComponent {
  contactForm = this.store.form.get('consumer.info') as FormGroup;
  showContactPreferences = this.contactForm.get('preferredContactMethod');
  vm$ = this.state.select();

  @ViewChild(CdkPortal) set actionsPortal(actionsPortal: CdkPortal) {
    this.wizardPortalService.setActionsPortal(actionsPortal);
  }

  constructor(
    private readonly store: WizardStore,
    private readonly wizardPortalService: WizardPortalService,
    private readonly state: RxState<IWizardContactInfoMobileState>,
  ) {
    this.state.set({
      contactPreferencesCollapsed: true,
    });
  }

  goToAddress(isInvalid: boolean): void {
    if (isInvalid) {
      this.contactForm.markAllAsTouched();
      return;
    }
    this.store.wizardNavigationStore.setStep('homeAddress');
  }

  toggleContactPreferences(): void {
    this.state.set({
      contactPreferencesCollapsed: !this.state.get('contactPreferencesCollapsed'),
    });
  }
}
