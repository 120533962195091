import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WizardNavigationStore } from '@consumer-core/stores/wizard-navigation/wizard-navigation.store';

@Injectable()
export class WizardStore {
  form: FormGroup;

  constructor(
    public readonly wizardNavigationStore: WizardNavigationStore<string>,
  ) {
  }
}
