import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { map } from 'rxjs/operators';

import { Files } from '@core/interfaces/claims/files.interface';

import { RegisterFppClaimStore } from '../../../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import {
  BaseWizardPageComponent,
} from '../../../register-plan-consumer-mobile/components/base-wizard-page/base-wizard-page.component';
import { ProductFilesPromptComponent } from '../../components/product-files-prompt/product-files-prompt.component';

interface IFppClaimEntireProductFileUploadState {
  activeProductIndex: number;
  entireProductFilesForm: FormArray;
  entireProductFiles: Files[];
  heroTitle: string;
}

@Component({
  selector: 'app-fpp-claim-entire-product-file-upload',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    ProductFilesPromptComponent,
  ],
  templateUrl: './fpp-claim-entire-product-file-upload.component.html',
  styleUrls: ['./fpp-claim-entire-product-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class FppClaimEntireProductFileUploadComponent extends BaseWizardPageComponent {
  vm$ = this.state.select();

  constructor(
    private readonly store: RegisterFppClaimStore,
    private readonly state: RxState<IFppClaimEntireProductFileUploadState>,
  ) {
    super();
    this.state.connect('heroTitle', this.store.select$('activeProductIndex').pipe(
      map((activeProductIndex) => `We’ll need some photos of ${this.store.getProductName(activeProductIndex)}!`),
    ));
  }

  goToDamageFileUpload(): void {
    this.store.wizardNavigationStore.setStep('damageFile');
  }
}
