import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';

import { CrudApiBaseService } from '@core/api/crud-api.base';
import { PurchaseApiInterface } from '@core/interfaces/api/purchase-api.interface';
import { Files } from '@core/interfaces/claims/files.interface';
import {
  IConfirmAddress,
  IConfirmEmail,
  IConfirmPhoneNumber,
  IVerifyCode,
} from '@core/interfaces/confirmation.interface';
import { PurchaseDetails, PurchaseLookup } from '@core/interfaces/purchase/purchase.interface';
import { FileService } from '@core/services/file.service';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class MyPurchaseApiService extends CrudApiBaseService<any> implements PurchaseApiInterface {
  endpoint = 'mypurchase';

  constructor(private notification: NotificationService, private readonly fileService: FileService) {
    super();
  }

  getFiles(id: number) {
    return this.fileService.getFileViewerWidth().pipe(
      switchMap(width =>
        this.httpClient
          .get<Omit<Files, 'index'>[]>(this.getUrl(`${id}/files`), {
          params: {
            width,
          },
        })
          .pipe(
            map(files =>
              this.fileService.sortFiles(files).map((file, index) => ({
                ...file,
                index,
              })),
            ),
          ),
      ),
    );
  }

  detailed(id: number) {
    return this.httpClient.get<PurchaseDetails>(this.getUrl(`${id}/detailed`));
  }

  confirmationmail(data: IConfirmEmail) {
    return this.httpClient.post<boolean>(this.getUrl('confirmationmail'), data);
  }

  confirmPhoneNumber(data: IConfirmPhoneNumber) {
    return this.httpClient.post<PurchaseLookup & {message?: string}>(this.getUrl('confirm-phone-number'), data);
  }

  confirmAddress(data: IConfirmAddress) {
    return this.httpClient.post<PurchaseLookup & {message?: string}>(this.getUrl('confirm-address'), data);
  }

  verifycode(data: IVerifyCode) {
    return this.httpClient.post<PurchaseLookup>(this.getUrl('verifycode'), data);
  }

  confirmsearchemail(emailAddress: string) {
    return this.httpClient.post<void>(this.getUrl('confirmsearchemail'), {
      emailAddress,
    });
  }

  verifysearchcode(validationCode: string, emailAddress: string) {
    return this.httpClient.post<void>(this.getUrl('verifysearchcode'), {
      validationCode,
      emailAddress,
    });
  }

  isPurchaseOwnershipVerified(planNumber: string) {
    return this.httpClient.get<PurchaseLookup>(this.getUrl(`isPurchaseOwnershipVerified/${planNumber}`));
  }

  registerMyPurchase(data: any) {
    const formData: FormData = new FormData();

    formData.append('model', JSON.stringify(data));

    return this.httpClient.post<boolean>(this.getUrl('registermypurchase'), formData).pipe(
      tap(() => {
        // this.notification.next({
        // eslint-disable-next-line max-len
        //   message: `Your purchase has been submitted for processing. You will be notified as soon as it has been located. During time of high volume, this could take a few minutes. If you don't receive a notification, please check back later to find it under MY PLANS.`, // eslint-disable-line max-len
        //   type: NotificationType.Success,
        //   duration: 20000,
        // });
      }),
    );
  }

  findMyPlan(planNumber: string) {
    return this.httpClient.get<PurchaseLookup>(this.getUrl(`findMyPlan/${planNumber}`));
  }
}
