import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ForModule } from '@rx-angular/template/for';

import { BasePlanDetailsModule } from '@shared/modules/base-plan-details/base-plan-details.module';
import {
  CollapsibleDataBlockComponent,
} from '@shared/modules/collapsible/components/collapsible-data-block/collapsible-data-block.component';
import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';
import { AnchorNavComponent } from '@shared/modules/navigation/components/anchor-nav/anchor-nav.component';
import {
  ItemDetailsPaginationComponent,
} from '@shared/modules/side-dialog/components/item-details-pagination/item-details-pagination.component';
import {
  SideDialogHeaderComponent,
} from '@shared/modules/side-dialog/components/side-dialog-header/side-dialog-header.component';
import {
  SideDialogLoaderComponent,
} from '@shared/modules/side-dialog/components/side-dialog-loader/side-dialog-loader.component';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';
import { FormFilesDropAreaComponent } from '@shared/standalone/file-controls/form-files-drop-area/form-files-drop-area.component';
import { FilesTotalSizeErrorComponent } from '@shared/standalone/files-total-size-error/files-total-size-error.component';

import { ConsumerPlanDialogsService } from '../../../core/services/consumer-plan-dialogs.service';
import { MyProtectionPlanDetailsPopupCellComponent } from './my-protection-plan-details-popup-cell/my-protection-plan-details-popup-cell.component';

@NgModule({
  declarations: [MyProtectionPlanDetailsPopupCellComponent],
  imports: [
    AnchorNavComponent,
    BasePlanDetailsModule,
    CollapsibleDataBlockComponent,
    CustomOverlayScrollbarDirective,
    DialogCoreModule,
    FilesTotalSizeErrorComponent,
    FormFilesDropAreaComponent,
    ForModule,
    ItemDetailsPaginationComponent,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatLegacyInputModule,
    MatLegacyProgressSpinnerModule,

    SideDialogHeaderComponent,
    SideDialogLoaderComponent,
  ],
  providers: [ConsumerPlanDialogsService],
})
export class ConsumerPlanDetailsModule {}
