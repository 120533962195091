import { DatePipe, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input, isDevMode,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { Observable, Subject } from 'rxjs';
import { combineLatestWith, distinctUntilChanged, first, map, startWith } from 'rxjs/operators';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { DEFAULT_ERROR_MESSAGE_FN, DEFAULT_ERROR_MESSAGES } from '@core/constants/app.constants';
import { TDateFieldType } from '@core/enums/date/date-field-type.enum';
import { TPortalType } from '@core/enums/portal-type.enum';
import { APP_FORM_FIELD_PROVIDER } from '@core/providers/form-field.provider';
import { FormErrorComponent } from '@shared/components/form-error/form-error.component';
import { PipesModule } from '@shared/pipes/pipes.module';

import { LabelValueComponent } from '../../label-value/label-value.component';

@Component({
  selector: 'app-form-date-new',
  standalone: true,
  templateUrl: './form-date-new.component.html',
  styleUrls: ['./form-date-new.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    NgIf,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    ReactiveFormsModule,
    MatTooltipModule,
    LabelValueComponent,
    PipesModule,
    DatePipe,
    FormErrorComponent,
  ],
})
export class FormDateNewComponent extends BaseFormControlElement implements OnInit, AfterViewInit {

  @ViewChild(MatDatepicker) picker: MatDatepicker<string>;

  /**
   * Moment date format
   */
  @Input() outputFormat = 'YYYY-MM-DD';

  @Input() max: Date;

  @Input() min: Date;

  @Input() label = 'Date';

  @Input() placeholder: string;

  @Input() autocomplete: string;

  @Input() hint: string;

  @Input() readonly: boolean;

  @Input() type: TDateFieldType;

  @Input() required: boolean;

  @Input() touchUi = false;

  @Input() tooltipText = '';

  @Input() noPaddingBottom = false;

  @Input() icon: 'date-range' | 'schedule' | 'reschedule' = 'date-range';

  @Input() expandHint = false;

  tooltipAttentionText = '';

  TDateFieldType = TDateFieldType;
  TPortalType = TPortalType;

  customMaxDateValidation = false;
  customMinDateValidation = false;

  inputTouched$$ = new Subject<boolean>();
  inputTouched$ = this.inputTouched$$.asObservable();
  activeError$: Observable<unknown>;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Inject(APP_FORM_FIELD_PROVIDER) public readonly formFieldProvider: {showErrors: boolean},
  ) {
    super(ngControl);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @Input() datePickerFilter = (d: Date | null): boolean => true;

  /**
   * Format the value to the expected format right after the setup
   */
  // ngOnInit(): void {
  // this.customMaxDateValidation = [TDateFieldType.MinMaxDate, TDateFieldType.MyPlanPurchaseDate].includes(this.type);
  // this.customMinDateValidation = [TDateFieldType.MinMaxDate, TDateFieldType.MyPlanDeliveryDate].includes(this.type);
  // }

  ngAfterViewInit(): void {
    this.activeError$ = this.ngControl.control.valueChanges.pipe(
      startWith(this.ngControl.control.value),
      combineLatestWith(this.inputTouched$.pipe(first())),
      map(() => Object.keys(this.ngControl.control.errors || {})),
      distinctUntilChanged((a, b) => a.toString() === b.toString()),
      map(errorKeys => {
        if (errorKeys.length > 0) {
          const errorObj = this.ngControl.control.getError(errorKeys[0]).message;
          const errorMessage = errorObj.message;
          const errMessageFnByKey = DEFAULT_ERROR_MESSAGES[errorKeys[0]];
          const errorMessageFn = errMessageFnByKey || DEFAULT_ERROR_MESSAGE_FN;

          if (!errorMessage && !errMessageFnByKey && isDevMode()) {
            console.warn(`No error message found for error keys: ${Object.keys(this.ngControl.control.errors)}`);
          }

          return errorMessage || errorMessageFn(errorObj, { label: this.label }) || null;
        } else {
          return null;
        }
      }),
    );
  }

  reOpenCalendar(): void {
    if (this.readonly) {
      this.picker.open();
    }
  }

  /**
   * Formats and updates the formControl on field change
   */
  // private _updateDate(date: Moment, makeDirty?: boolean): void {
  //   const pristine = !this.formControl.dirty || !this.formControl.touched;
  //   const value = date.format(this.outputFormat);
  //
  //   if (makeDirty && pristine) {
  //     this.formControl.markAsDirty();
  //     this.formControl.markAsTouched();
  //   }
  //   this.formControl.setValue(value);
  // }
}
