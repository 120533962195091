<div class="claim-success__header">
  <p class="claim-success__title">Claim Submitted</p>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <p>Thanks for submitting your claim. Your claim number is <strong>{{data.data.claim.claimNumber}}</strong>. It may take a few minutes before it shows up in the portal.</p>
</mat-dialog-content>



