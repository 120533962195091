import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';

import { ConsumerClaimDialogService } from '../../../core/services/consumer-claim-dialog.service';

@Component({
  selector: 'app-plan-success',
  standalone: true,
  templateUrl: './plan-success.component.html',
  styleUrls: ['./plan-success.component.scss'],
  imports: [
    DialogCoreModule,
    MatButtonModule,
  ],
})
export class PlanSuccessComponent {
  data: {data: PlanDetails} = inject(MAT_DIALOG_DATA);

  constructor(
    private readonly dialogRef: MatDialogRef<any>,
    private readonly consumerClaimDialogService: ConsumerClaimDialogService,
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  registerClaim(): void {
    // Adjust redirect
    this.close();
    this.consumerClaimDialogService.openCreateFppClaimDialog(this.data.data);
  }
}
