<div class="consumer-mobile" *rxLet="vm$; let vm">
  <app-header-mobile [headerTitle]="vm.routeTitle">
    <ng-container leftControls>
      <button *rxIf="vm.showBackNavigation" (click)="backNav()" mat-icon-button>
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div *rxIf="vm.showProfile"
           class="consumer-mobile__notification-wrapper">
        <button [routerLink]="['app', 'notifications']"
                mat-icon-button
                class="consumer-mobile__notification-btn">
          <mat-icon svgIcon="mobile-notification"></mat-icon>
        </button>
        <p class="consumer-mobile__notification-count" *ngIf="vm.newNotificationsNumber > 0">{{vm.newNotificationsNumber}}</p>
      </div>
    </ng-container>
    <ng-container rightControls>
      <button *rxIf="vm.showCloseDialog" (click)="closeDialog()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
      <div *rxIf="vm.showProfile">
        <div class="consumer-mobile__header-logo" [matMenuTriggerFor]="menu">{{vm.userLogoText}}</div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [routerLink]="['app', 'user-profile']">Profile</button>
          <button mat-menu-item (click)="logOut()">Log Out</button>
        </mat-menu>
      </div>
    </ng-container>
  </app-header-mobile>
  <div class="consumer-mobile__content">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="vm.showFive9Chat" class="consumer-mobile__chat-wrapper">
    <button mat-flat-button (click)="openFive9Chat()">
      <span>Chat</span>
      <mat-icon>open_in_new</mat-icon>
    </button>
  </div>
  <div class="footer">
    <ng-template [cdkPortalOutlet]="vm.footerPortal"></ng-template>
    <div class="main-footer" *rxIf="vm.showFooter">
      <a [routerLink]="['/app/dashboard']" routerLinkActive="footer__item_active" class="footer__item">
        <mat-icon class="footer__item-icon" svgIcon="mobile-home"></mat-icon>
        Home
      </a>
      <a [routerLink]="['/app/my-plans']" routerLinkActive="footer__item_active" class="footer__item">
        <mat-icon class="footer__item-icon" svgIcon="mobile-plans"></mat-icon>
        Plans
      </a>
      <a [routerLink]="['/app/my-claims']" routerLinkActive="footer__item_active" class="footer__item">
        <mat-icon class="footer__item-icon" svgIcon="mobile-claims"></mat-icon>
        Claims
      </a>
      <a [routerLink]="['/app/contact-info']"
         routerLinkActive="footer__item_active"
         class="footer__item">
        <mat-icon class="footer__item-icon" svgIcon="mobile-contact"></mat-icon>
        <span>Contact</span>
      </a>
      <a [routerLink]="['/app/help']" routerLinkActive="footer__item_active" class="footer__item">
        <mat-icon class="footer__item-icon" svgIcon="mobile-help"></mat-icon>
        Help
      </a>
    </div>
  </div>
  <app-mobile-notification-snack-bar></app-mobile-notification-snack-bar>
</div>
<app-application-loader></app-application-loader>
