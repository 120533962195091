import { PlanDetails } from '@core/interfaces/plan/plan.interface';

export type TRegisterFppClaimNavigationStepDesktop =
  'gettingStarted' |
  'consumer' |
  'products' |
  'product' |
  'files';
export type TRegisterFppClaimNavigationStepMobile =
  'selectPlan' |
  'guidance' |
  'contactInfo' |
  'homeAddress' |
  'selectProducts' |
  'product' |
  'uploadReceipt' |
  'entireProductFile' |
  'damageFile' |
  'closeupFile' |
  'productTag' |
  'incidentReview' |
  'review' |
  'finish';
export type TRegisterFppClaimNavigationStep = TRegisterFppClaimNavigationStepDesktop | TRegisterFppClaimNavigationStepMobile;

export namespace StoreState {
  export interface IStoreState {
    isMobile: boolean;
    planDetails: PlanDetails;
    activeProductIndex: number;
    isLoading: boolean;
    isPaperPlan: boolean;
    submitInProgress: boolean;
    claimSubmitted: boolean;
    isLoadingReceiptFiles: boolean;
    isReceiptUploaded: boolean;
  }

  export const initialState: IStoreState = {
    isMobile: false,
    planDetails: null,
    activeProductIndex: 0,
    isLoading: false,
    isPaperPlan: false,
    submitInProgress: false,
    claimSubmitted: false,
    isLoadingReceiptFiles: false,
    isReceiptUploaded: false,
  };
}
