<form
  [formGroup]="consumerForm"
  class="claim-form__form"
  spellcheck="false">
  <h2 class="section__title">Consumer</h2>
  <div>
    <app-consumer-template-form [formGroupRef]="consumerForm.get('info')"></app-consumer-template-form>
    <div class="section">
      <h3 class="section__title">Home Address</h3>
      <app-address-template-form
        [formGroup]="consumerForm.get('consumerAddress')"></app-address-template-form>
      <app-address-suggest
        [addressForm]="consumerForm.get('consumerAddress')"
        (isInProgress)="setInProgressState($event)"></app-address-suggest>
    </div>
    <div class="section">
      <div class="fpp-claim-consumer__same-address">
        <h3 class="section__title">Location of Furniture Requiring Service</h3>
        <app-switch
          class="claim-form__same-switch"
          type="checkbox"
          title="Same as Home address"
          [(ngModel)]="isServiceAddressSame"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="isServiceAddressSameChanged()"></app-switch>
      </div>
      <app-address-template-form
        [formGroup]="consumerForm.get('serviceAddress')"
        [blocked]="isServiceAddressSame"></app-address-template-form>
      <app-address-suggest
        *ngIf="!isServiceAddressSame"
        [addressForm]="consumerForm.get('serviceAddress')"
        (isInProgress)="setInProgressState($event)"
        [isServiceAddressSame]="isServiceAddressSame"></app-address-suggest>
    </div>
    <div class="form-step-buttons">
      <button
        type="button"
        id="button-back"
        (click)="goToGettingStarted()"
        mat-flat-button
        color="primary">
        Back
      </button>
      <button
        type="button"
        id="button-next"
        [disabled]="consumerForm.invalid || (addressValidationInProgress$ | push)"
        (click)="goToProducts()"
        mat-flat-button
        color="primary">
        Next
      </button>
    </div>
  </div>
</form>
