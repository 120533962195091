import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';

import { Files } from '@core/interfaces/claims/files.interface';
import { ImageErrorStandaloneDirective } from '@shared/directives/image-error-standalone.directive';

interface IPhotoDialogMobileState {
  activeFile: Files;
  activeFileOrderIndex: number;
  prevDisabled: boolean;
  nextDisabled: boolean;
}

@Component({
  selector: 'app-photo-dialog-mobile',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    ImageErrorStandaloneDirective,
  ],
  templateUrl: './photo-dialog-mobile.component.html',
  styleUrls: ['./photo-dialog-mobile.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoDialogMobileComponent {
  dialogData = inject(MAT_DIALOG_DATA) as { data: { files: Files[]; file: Files } };
  vm$ = this.state.select();

  constructor(
    private readonly state: RxState<IPhotoDialogMobileState>,
  ) {
    const activeFile = this.dialogData.data.file;
    const files = this.dialogData.data.files;
    const activeFileOrderIndex = files.findIndex(file => file.id ? file.id === activeFile.id : file.url === activeFile.url);
    this.state.set({
      activeFile,
      activeFileOrderIndex,
      prevDisabled: activeFileOrderIndex === 0,
      nextDisabled: activeFileOrderIndex === files.length - 1,
    });
  }

  nextPhoto(): void {
    const files = this.dialogData.data.files;
    const activeFileOrderIndex = this.state.get('activeFileOrderIndex');
    const nextIndex = activeFileOrderIndex + 1;
    if (files.length - 1 >= nextIndex) {
      this.state.set({
        activeFile: files[nextIndex],
        activeFileOrderIndex: nextIndex,
        nextDisabled: nextIndex === files.length - 1,
        prevDisabled: false,
      });
    }
  }

  prevPhoto(): void {
    const files = this.dialogData.data.files;
    const activeFileOrderIndex = this.state.get('activeFileOrderIndex');
    if (activeFileOrderIndex > 0) {
      const prevIndex = activeFileOrderIndex - 1;

      this.state.set({
        activeFile: files[prevIndex],
        activeFileOrderIndex: prevIndex,
        prevDisabled: prevIndex === 0,
        nextDisabled: false,
      });
    }
  }
}
