<div class="plan-confirm" *rxLet="vm$; let vm">
  <ng-container [ngSwitch]="vm.registrationType">
    <ng-container *ngSwitchCase="TConsumerPlanRegistrationState.Error">
      <p class="plan-confirm__section-title">Plan Unavailable</p>
      <p>{{vm.planLookup.errorMessage}}</p>
      <ng-container *cdkPortal>
        <button type="button" mat-stroked-button (click)="goToPlanLookup()">Try Another Plan</button>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="TConsumerPlanRegistrationState.DynamicPlanConflictEmail">
      <ng-container *rxIf="!vm.emailCodeSent; else emailCodeSentTemplate">
        <p class="plan-confirm__header-title">Plan Associated With Another Email</p>
        <p>The plan is already associated with email {{vm.planLookup.conflictEmail}}.<p>
        <br>
        <p>To verify that this is your email address, complete the hidden part and click "Send verification code" to receive your code.</p>
        <app-form-input-new [formControl]="emailForm.get('confirmationEmail')"
                            label="Email Address"
                            type="email"
                            [required]="true"></app-form-input-new>
      </ng-container>
      <ng-template #emailCodeSentTemplate>
        <p class="plan-confirm__header-title">Plan Associated With Another Email</p>
        <p>If <b>{{emailForm.value.confirmationEmail}}</b> matches the email address associated with the plan, we'll send you a verification code.</p>
        <p class="section__title">If you don't receive it within a few minutes, check your junk email folder.</p>
        <app-form-input-new [formControl]="emailForm.get('validationCode')"
                            [label]="'Verification Code'"
                            [required]="true"></app-form-input-new>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="TConsumerPlanRegistrationState.DynamicPlanConflictPhone">
      <p class="plan-confirm__header-title">Plan Associated With Another Phone Number</p>
      <p>This plan is already associated with phone number {{vm.planLookup.conflictPhoneNumber}}.<p>
      <p>To verify that this is your plan, please enter your phone number below.</p>
      <p class="help-text help-text--required">At least one Mobile, Work, or Home phone number is required.</p>
      <app-form-input-new [formControl]="phoneControl"
                          label="Phone Number"
                          [required]="true"></app-form-input-new>
    </ng-container>
    <ng-container *ngSwitchCase="TConsumerPlanRegistrationState.DynamicPlanConflictAddress">
      <p class="plan-confirm__header-title">Plan Associated With Another Address</p>
      <p>Looks like none of those numbers matched your account.</p>
      <p>To verify that this is your plan, enter your address below and click “Verify Address.”</p>
      <app-address-form-mobile [form]="addressForm"></app-address-form-mobile>
    </ng-container>
  </ng-container>
  <ng-container *cdkPortal>
    <ng-container [ngSwitch]="vm.registrationType">
      <ng-container *ngSwitchCase="TConsumerPlanRegistrationState.DynamicPlanConflictEmail">
        <div class="plan-confirm__row-actions" *rxIf="!vm.emailCodeSent; else emailCodeSentButtonsTemplate">
          <ng-container [ngTemplateOutlet]="verificationOptionsTemplate"></ng-container>
          <button mat-flat-button
                  appSubmitBtn
                  appLoadingButton
                  [isLoading]="vm.verificationInProgress"
                  [class._common-button_submit-disabled]="emailForm.get('confirmationEmail').invalid"
                  (click)="sendEmailVerificationCode(emailForm.get('confirmationEmail').invalid)">Send verification code</button>
        </div>
        <ng-template #emailCodeSentButtonsTemplate>
          <div class="plan-confirm__row-actions">
            <button mat-stroked-button type="button" (click)="changeVerificationType('email')">Try Another Email</button>
            <button mat-flat-button
                    appSubmitBtn
                    appLoadingButton
                    [isLoading]="vm.verificationInProgress"
                    (click)="verifyPlan('email', emailForm.get('validationCode').invalid)"
                    [class._common-button_submit-disabled]="emailForm.get('validationCode').invalid">
              Submit
            </button>
          </div>
        </ng-template>
      </ng-container>
      <div class="plan-confirm__row-actions" *ngSwitchCase="TConsumerPlanRegistrationState.DynamicPlanConflictPhone">
        <ng-container [ngTemplateOutlet]="verificationOptionsTemplate"></ng-container>
        <button mat-flat-button
                appSubmitBtn
                appLoadingButton
                [isLoading]="vm.verificationInProgress"
                [class._common-button_submit-disabled]="phoneControl.invalid"
                (click)="verifyPlan('phone', phoneControl.invalid)">Verify Phone Number</button>
      </div>
      <div class="plan-confirm__row-actions" *ngSwitchCase="TConsumerPlanRegistrationState.DynamicPlanConflictAddress">
        <ng-container [ngTemplateOutlet]="verificationOptionsTemplate"></ng-container>
        <button mat-flat-button
                appSubmitBtn
                appLoadingButton
                [isLoading]="vm.verificationInProgress"
                [class._common-button_submit-disabled]="addressForm.invalid"
                (click)="verifyPlan('address', addressForm.invalid)">Verify Address</button>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #verificationOptionsTemplate>
    <button *rxFor="let verificationOption of vm.verificationOptions; let last = last"
            mat-stroked-button
            type="button"
            (click)="changeVerificationType(verificationOption)">Verify {{verificationOption}} instead</button>
  </ng-template>
</div>
