import { ComponentPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsumerMobilePortalService {
  footerPortal$$ = new Subject<Portal<any>>();
  footerPortal$ = this.footerPortal$$.asObservable();

  constructor() { }

  setFooterPortal(portal: ComponentPortal<any> | TemplatePortal<any>) {
    this.footerPortal$$.next(portal);
  }
}
