import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { VerifyPlanPurchaseService } from '@consumer-core/services/verify-plan-purchase.service';
import { WizardPortalService } from '@consumer-core/services/wizard-portal.service';
import { RegisterPlanStore } from '@consumer-core/stores/register-plan/register-plan.store';
import { RxState } from '@rx-angular/state';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { LetDirective } from '@rx-angular/template/let';
import { combineLatestWith, map } from 'rxjs/operators';

import { TConsumerPlanRegistrationState } from '@core/enums/plan/consumer-plan-registration-state.enum';
import { PlanLookup } from '@core/interfaces/plan/plan.interface';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';

import {
  AddressFormMobileComponent,
} from '../../../../../shared/components/address-form-mobile/address-form-mobile.component';
import { FormInputNewComponent } from '../../../../../shared/components/form-controls/form-input-new/form-input-new.component';
import { SubmitBtnDirective } from '../../../../../shared/directives/submit-btn.directive';

interface IPlanConfirmMobileState {
  planLookup: PlanLookup;
  registrationType: TConsumerPlanRegistrationState;
  verificationInProgress: boolean;
  emailCodeSent: boolean;
  verificationOptions: Array<'email' | 'phone' | 'address'>;
}

const REGISTRATION_TYPE_TO_VERIFICATION_TYPE = {
  [TConsumerPlanRegistrationState.DynamicPlanConflictEmail]: 'email',
  [TConsumerPlanRegistrationState.DynamicPlanConflictPhone]: 'phone',
  [TConsumerPlanRegistrationState.DynamicPlanConflictAddress]: 'address',
};

@Component({
  selector: 'app-plan-confirm-mobile',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    RxIf,
    MatButtonModule,
    PortalModule,
    FormInputNewComponent,
    ReactiveFormsModule,
    LoadingButtonDirective,
    AddressFormMobileComponent,
    RxFor,
    SubmitBtnDirective,
  ],
  templateUrl: './plan-confirm-mobile.component.html',
  styleUrls: ['./plan-confirm-mobile.component.scss'],
  providers: [
    RxState,
    VerifyPlanPurchaseService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanConfirmMobileComponent {
  vm$ = this.state.select();
  TConsumerPlanRegistrationState = TConsumerPlanRegistrationState;
  emailForm = this.verifyPlanPurchaseService.emailForm;
  phoneControl = this.verifyPlanPurchaseService.phoneControl;
  addressForm = this.verifyPlanPurchaseService.addressForm;

  @ViewChild(CdkPortal) set actionsPortal(actionsPortal: CdkPortal) {
    this.wizardPortalService.setActionsPortal(actionsPortal);
  }

  constructor(
    private readonly state: RxState<IPlanConfirmMobileState>,
    private readonly store: RegisterPlanStore,
    private readonly wizardPortalService: WizardPortalService,
    private readonly verifyPlanPurchaseService: VerifyPlanPurchaseService,
  ) {
    this.state.connect(this.store.select$('planLookup').pipe(
      combineLatestWith(this.store.select$('registrationType')),
      map(([planLookup, registrationType]) => ({
        planLookup,
        registrationType,
      }),
      )));
    this.state.connect('verificationInProgress', this.verifyPlanPurchaseService.verificationInProgress$);
    this.state.connect('emailCodeSent', this.verifyPlanPurchaseService.emailCodeSent$);
    this.state.connect('verificationOptions', this.store.select$('verificationOptions').pipe(
      combineLatestWith(this.store.select$('registrationType')),
      map(
        ([verificationOptions, registrationType]) =>
          verificationOptions.filter(option => option !== REGISTRATION_TYPE_TO_VERIFICATION_TYPE[registrationType]),
      )));
  }

  verifyPlan(verificationType: 'email' | 'phone' | 'address', isInvalid = false): void {
    if (isInvalid) {
      if (verificationType === 'address') {
        this.addressForm.markAllAsTouched();
      }
      return;
    }
    const planNumber = this.store.get('planNumber');
    const planCrmRefId = this.store.get('planLookup', 'consumerPlan', 'crmRefId');
    this.verifyPlanPurchaseService.verifyPlan(verificationType, {
      planNumber,
      planCrmRefId,
    }).subscribe({
      next: (planLookup: PlanLookup) => {
        this.store.completeVerification(planLookup);
      },
    });
  }

  goToPlanLookup(): void {
    this.store.wizardNavigationStore.setStep('lookupPlan');
  }

  changeVerificationType(verificationType: 'email' | 'phone' | 'address'): void {
    switch (verificationType) {
      case 'email':
        this.verifyPlanPurchaseService.emailCodeSent$$.next(false);
        this.store.goToEmailVerification();
        break;
      case 'phone':
        this.store.goToPhoneVerification();
        break;
      case 'address':
        this.store.goToAddressVerification();
        break;
    }
  }

  sendEmailVerificationCode(isInvalid: boolean): void {
    if (isInvalid) {
      return;
    }

    const planNumber = this.store.get('planNumber');
    this.verifyPlanPurchaseService.sendEmailVerificationCode(planNumber);
  }
}
