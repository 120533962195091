<div class="plan-files-upload" *rxLet="vm$; let vm">
  <div class="plan-files-upload__intro-wrapper" *rxIf="vm.filesLength === 0; else filesExistTemplate">
    <p class="plan-files-upload__header">We’ll need a receipt on file!</p>
    <img class="plan-files-upload__image" src="assets/consumer-mobile/receipt-upload.svg" alt="Receipt Upload">
    <div>
      <p class="plan-files-upload__message">
        We recommend providing a copy of your original purchase receipt now as it will help speed up any future claims processing.
      </p>
    </div>
  </div>
  <ng-template #filesExistTemplate>
    <app-files-list-mobile [files]="vm.files"
                           [showDelete]="true"
                           (removeFile)="removeFile($event)"></app-files-list-mobile>
  </ng-template>
  <input class="plan-files-upload__file-input" #filesInputReceipt type="file"
         [multiple]="true"
         [accept]="accept"
         (change)="handleInputFiles($event)">
  <ng-container *cdkPortal>
    <div class="plan-files-upload__actions"
         *ngIf="vm.filesLength === 0; else filesExistButtonsTemplate">
      <button type="button"
              (click)="goToReview()"
              mat-stroked-button>Skip</button>
      <button type="button"
              (click)="filesInputReceipt.click()"
              mat-flat-button>Add Now</button>
    </div>
    <ng-template #filesExistButtonsTemplate>
      <div class="plan-files-upload__footer">
        <app-files-total-size-error [filesForm]="filesForm"></app-files-total-size-error>
        <div class="plan-files-upload__actions">
          <button type="button"
                  (click)="filesInputReceipt.click()"
                  mat-stroked-button>Add More</button>
          <button type="button"
                  (click)="goToReview()"
                  [disabled]="filesForm.invalid"
                  mat-flat-button>Continue</button>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
