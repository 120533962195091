import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, ResolveFn } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { map } from 'rxjs/operators';

import { ISession } from '@core/interfaces/session/session-data.interface';
import { ThemingService } from '@core/services/theming.service';

@Injectable({ providedIn: 'root' })
export class ConsumerThemeResolver implements Resolve<any> {
  constructor(
    private themingService: ThemingService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const parseToken = jwtDecode<ISession>(route.queryParams.token);
    return this.themingService.for(parseToken['tenantid']).pipe(
      map(isLoaded => isLoaded),
    );
  }
}

export const consumerThemeResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot) => {
  const themingService = inject(ThemingService);
  const parseToken = jwtDecode<ISession>(route.queryParams.token);
  return themingService.for(parseToken['tenantid']).pipe(
    map(isLoaded => isLoaded),
  );
};
