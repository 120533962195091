import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ProductApiService } from '@core/api/product-api.service';
import { Feature } from '@core/enums/feature.enum';
import { ProductInterface } from '@core/interfaces/claims/product.interface';
import { Product } from '@core/interfaces/quick-search/search-results.interface';
import { SelectProductPopupData } from '@core/interfaces/select-product-popup-data';

export const CONST_fromScratchDefaultConfig = true;
export const CONST_existedDefaultConfig = true;
export const CONST_displayButtonAddNewProductsToClaimDefaultConfig = true;

export interface ISelectProductConfig {
  fromScratch: boolean;
  existed: boolean;
  displayButtonAddNewProductsToClaim: boolean;
}

interface ISelectProductState {
  selectInProgress: boolean;
  products: ProductInterface[];
  selectedProductIds: string[];
}

@Component({
  selector: 'app-select-product',
  templateUrl: './select-product.component.html',
  styleUrls: ['./select-product.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectProductComponent {
  @Input() set data({ products, selectedProductIds }: SelectProductPopupData) {
    selectedProductIds = selectedProductIds || [];
    this.state.set({
      products: (products || []).map(product => ({
        ...product,
        disabled: selectedProductIds.includes(product.id) || product.disabled,
      })),
      selectedProductIds,
    });
  }
  @Input() config: ISelectProductConfig = { fromScratch: CONST_fromScratchDefaultConfig, existed: CONST_existedDefaultConfig, displayButtonAddNewProductsToClaim: CONST_displayButtonAddNewProductsToClaimDefaultConfig };
  @Output() newProduct: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectProducts: EventEmitter<Product[]> = new EventEmitter<Product[]>();

  products$ = this.state.select('products');
  selectInProgress$ = this.state.select('selectInProgress');

  selectedProducts: string[] = [];
  features = Feature;

  constructor(private productApiService: ProductApiService, private readonly state: RxState<ISelectProductState>) {
    this.state.set({
      selectInProgress: false,
      selectedProductIds: [],
    });
  }

  emitSelectProducts() {
    if (this.state.get('selectInProgress')) {
      return;
    }

    this.state.set({
      selectInProgress: true,
    });
    forkJoin(this.selectedProducts.map(productId => this.productApiService.getByGuid(productId)))
      .pipe(
        finalize(() => {
          this.state.set({
            selectInProgress: false,
          });
        }),
      )
      .subscribe(products => {
        this.selectProducts.emit(products);
      });
  }
}
