import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { DocumentApi } from '@core/api/document.api';
import { ClaimServiceInterface } from '@core/interfaces/claims/claim-service.interface';
import { IClaimNote } from '@core/interfaces/claims/claimDetails.interface';
import { Files } from '@core/interfaces/claims/files.interface';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { MyClaimApiService } from '../api/my-claim-api.service';

@Injectable({
  providedIn: 'root',
})
export class ConsumerClaimService implements ClaimServiceInterface {
  constructor(
    private documentsApiService: DocumentApi,
    private myClaimApiService: MyClaimApiService,
    private notification: NotificationService,
  ) {}

  getMyClaims(page: number = 1) {
    const searchRequest = {
      page,
      pageSize: 24,
      orderBy: [],
    };
    return this.myClaimApiService.all(searchRequest);
  }

  getClaimDetails(claimId: number) {
    return this.myClaimApiService.getClaimDetails(claimId).pipe(
      catchError(error => {
        if (error.status === 404) {
          this.notification.next({
            message: 'You do not have access to this claim.',
            type: NotificationType.Error,
          });
        }
        return throwError(error);
      }),
    );
  }

  getFiles(claimId: number) {
    return this.myClaimApiService.getFiles(claimId);
  }

  uploadMultipleFiles(filesToUpload: Files[], claimId: string): Observable<void> {
    return this.documentsApiService.uploadMyClaimMultipleFiles(filesToUpload, claimId).pipe(
      tap(() => {
        this.notification.next({
          message: 'Your file(s) have been uploaded and will show up on the claim shortly.',
          type: NotificationType.Success,
        });
      }),
    );
  }

  uploadPlanFiles(filesToUpload: Files[], claimId: number) {
    return this.documentsApiService.uploadMyPlanFiles(filesToUpload, claimId).pipe(
      tap(() => {
        this.notification.next({
          message: 'Your file(s) have been uploaded and will show up on the plan shortly.',
          type: NotificationType.Success,
        });
      }),
    );
  }

  saveNote(claimId: number, note: string): Observable<IClaimNote> {
    return this.myClaimApiService.saveNote(claimId, note).pipe(
      map(noteResponse => {
        this.notification.next({
          message: 'You note has been submitted for processing.',
          type: NotificationType.Success,
          duration: 10000,
        });
        return noteResponse;
      }),
      catchError(error => {
        if (error.status === 400) {
          this.notification.next({
            message: error.error.errors.Note[0],
            type: NotificationType.Error,
            duration: 6000,
          });
        } else {
          this.notification.next({
            message: 'API error, try again later.',
            type: NotificationType.Error,
          });
        }
        return throwError(error);
      }),
    );
  }
}
