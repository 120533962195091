import { IEnhancement } from '@core/interfaces/enhancement/enhancement.interface';

export namespace StoreState {
  export interface IStoreState {
    enhancements: IEnhancement[];
    loading: boolean;
  }

  export const initialState: IStoreState = {
    enhancements: [],
    loading: false,
  };
}
