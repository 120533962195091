<div class="claim-form__block">
  <div *ngIf="form.get('coveredProducts').value.length === 0">
    <app-select-product [data]="selectProductsData$ | push"
                        [config]="{existed: (isPaperPlan$ | push) === false, fromScratch: true, displayButtonAddNewProductsToClaim: true}"
                        (selectProducts)="addProducts($event)"
                        (newProduct)="addProducts()"></app-select-product>
  </div>
  <div class="form-step-buttons">
    <button type="button"
            id="button-back"
            (click)="goToConsumer()"
            mat-flat-button color="primary">Back</button>
  </div>
</div>
