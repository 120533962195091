import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { PushPipe } from '@rx-angular/template/push';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SwitchComponent } from '@shared/components/switch/switch.component';
import { ClaimDialogsCoreModule } from '@shared/modules/claim-dialogs/claim-dialogs-core.module';
import {
  AddressSuggestComponent,
} from '@shared/modules/claim-dialogs/components/address-suggest/address-suggest.component';
import { AddressTemplateFormComponent } from '@shared/standalone/claim-forms/address-template-form/address-template-form.component';
import { ConsumerTemplateFormComponent } from '@shared/standalone/claim-forms/consumer-template-form/consumer-template-form.component';

import { RegisterFppClaimStore } from '../../store/register-fpp-claim.store';

interface IFppClaimConsumerState {
  addressValidationInProgress: boolean;
}

@Component({
  selector: 'app-fpp-claim-consumer',
  templateUrl: './fpp-claim-consumer.component.html',
  styleUrls: ['./fpp-claim-consumer.component.scss'],
  standalone: true,
  imports: [
    ConsumerTemplateFormComponent,
    AddressTemplateFormComponent,
    ClaimDialogsCoreModule,
    ReactiveFormsModule,

    SwitchComponent,
    MatButtonModule,
    AddressSuggestComponent,
    PushPipe,
    NgIf,
    FormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FppClaimConsumerComponent implements OnInit, OnDestroy {
  consumerForm: FormGroup;
  isServiceAddressSame = false;
  addressValidationInProgress$ = this.state.select('addressValidationInProgress');
  stopAddressSync$ = new Subject<void>();

  constructor(private readonly store: RegisterFppClaimStore, private readonly state: RxState<IFppClaimConsumerState>) {
    this.state.set({
      addressValidationInProgress: false,
    });
  }

  ngOnInit(): void {
    this.consumerForm = this.store.getForm('consumer') as FormGroup;
  }

  ngOnDestroy(): void {
    this.stopAddressSync$.next();
    this.stopAddressSync$.complete();
  }

  goToGettingStarted(): void {
    this.store.wizardNavigationStore.setStep('gettingStarted');
  }

  goToProducts(): void {
    const coveredProductsLength = this.store.getForm('coveredProducts').value.length;
    if (coveredProductsLength > 0) {
      this.store.goToProduct(0);
    } else {
      this.store.wizardNavigationStore.setStep('products');
    }
  }

  setInProgressState(addressValidationInProgress: boolean): void {
    this.state.set({
      addressValidationInProgress,
    });
  }

  isServiceAddressSameChanged(): void {
    if (this.isServiceAddressSame) {
      this.consumerForm.get('serviceAddress').patchValue(this.consumerForm.get('consumerAddress').value);
      this.consumerForm
        .get('consumerAddress')
        .valueChanges.pipe(takeUntil(this.stopAddressSync$))
        .subscribe(address => {
          this.consumerForm.get('serviceAddress').patchValue(address);
        });
    } else {
      this.stopAddressSync$.next();
    }
  }
}
