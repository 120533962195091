<app-side-dialog-with-route
  class="_common-side-dialog_clear-view"
  [isVisible]="isVisible$ | push"
  [loading]="isLoading$ | push"
  (closeDialog)="onClose()">
  <div header>
    <ng-container *rxLet="plan$; let plan">
      <h2 class="register-fpp-claim__header">Claim for plan {{ plan.planInfo.consumerPlanName }}</h2>
    </ng-container>
  </div>
  <div
    content
    class="register-fpp-claim">
    <div class="dialog__nav">
      <div *ngFor="let nav of navigationList$ | push">
        <div
          class="register-fpp-claim__nav"
          [class.register-fpp-claim__nav--active]="nav.id === (activeStep$ | push)">
          <div class="register-fpp-claim__nav-content">
            <p class="register-fpp-claim__nav-title">{{ nav.title }}</p>
            <div
              *ngIf="nav.valid$ | push"
              class="register-fpp-claim__icon">
              <mat-icon class="_common-icon--white">done</mat-icon>
            </div>
          </div>
        </div>
        <ng-container *ngIf="nav.children && nav.children.length > 0">
          <div
            class="register-fpp-claim__nav-child"
            *ngFor="let childNav of nav.children; let childIndex = index"
            [class.register-fpp-claim__nav-child--active]="
              (activeStep$ | push) === 'product' && childIndex === (activeProductIndex$ | push)
            ">
            <div class="register-fpp-claim__nav-content">
              <p class="register-fpp-claim__nav-title">{{ childNav.title }}</p>
              <div
                *ngIf="childNav.valid$ | push"
                class="register-fpp-claim__icon">
                <mat-icon class="_common-icon--white">done</mat-icon>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="dialog__scroll-view">
      <ng-container *ngComponentOutlet="activeComponent$ | push"></ng-container>
    </div>
  </div>
</app-side-dialog-with-route>
