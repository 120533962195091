import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { ClaimApiBaseService } from '@core/api/claim-api.service';
import { CrudApiBaseService } from '@core/api/crud-api.base';
import { Claim } from '@core/interfaces/claims/claim.interface';
import { ClaimDetails, FileInterface, IClaimNote } from '@core/interfaces/claims/claimDetails.interface';
import { Files } from '@core/interfaces/claims/files.interface';
import { Theme } from '@core/interfaces/ui/theme.interface';
import { ApplicationInsightsService } from '@core/services/application-insights/application-insights.service';
import { FileService } from '@core/services/file.service';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class MyClaimApiService extends CrudApiBaseService<Claim> implements ClaimApiBaseService {
  endpoint = 'myclaim';

  constructor(
    private notification: NotificationService,
    private readonly fileService: FileService,
    private readonly applicationInsightsService: ApplicationInsightsService,
  ) {
    super();
  }

  getClaimDetails(id: number): Observable<ClaimDetails> {
    return this.httpClient.get<ClaimDetails>(this.getUrl(`${id}`));
  }

  getFiles(id: number) {
    return this.fileService.getFileViewerWidth().pipe(
      switchMap(width =>
        this.httpClient
          .get<Files[]>(this.getUrl(`${id}/files`), {
          params: {
            width,
          },
        })
          .pipe(map(files => this.fileService.sortFiles(files))),
      ),
    );
  }

  getCommunicationAttachments(claimId: number, activityId: string) {
    return this.httpClient.get<FileInterface[]>(this.getUrl(`${claimId}/attachments/${activityId}`));
  }

  createClaim(data: any, files: Files[]) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('formfiles', files[i].originalFile);
    }

    formData.append('model', JSON.stringify(data));

    this.applicationInsightsService.logEvent('ConsumerRegisterClaim', {
      body: data,
    });

    return this.httpClient.post<boolean>(this.getUrl(''), formData).pipe(
      tap(() => {
        this.notification.next({
          message:
            'You claim has been submitted for processing. '
            + 'You will receive the Claim Number shortly, as soon as it has been assigned.',
          type: NotificationType.Success,
          duration: 6000,
        });
      }),
    );
  }

  saveNote(id: number, note: string): Observable<IClaimNote> {
    return this.httpClient.post<IClaimNote>(this.getUrl(`${id}/note`), {
      note,
    });
  }

  tenantInfo(encryptedClaimPortalToken: string) {
    return this.httpClient.get<ClaimDetails & {tenantInfo: Theme}>(this.getUrl('claimTenantInfo'), {
      params: {
        encryptedClaimPortalToken,
      },
    });
  }
}
