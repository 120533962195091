import { Directive, inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import { PromptDialogService } from '@core/services/dialog/prompt-dialog.service';
import { ClaimFormSteps } from '@shared/modules/claim-dialogs/enums/claim-form-steps.enum';

import { UserRoles } from '../enums/user-roles.enum';
import { ProductInterface } from '../interfaces/claims/product.interface';
import { Product } from '../interfaces/quick-search/search-results.interface';
import { ClaimFormService } from '../services/claim-form.service';
import { UserStore } from '../store/user/user.store';
import { BaseWizard, WizardNavigation, WizardNavigationChild } from './base-wizard';

@Directive()
export abstract class ClaimWizard<T extends WizardNavigation> extends BaseWizard<T> implements OnInit {
  protected promptDialogService = inject(PromptDialogService);
  private updateAddressOnAgreement: boolean;
  private serviceAddressWasChecked = false;

  abstract initWizard();

  protected constructor(
    protected claimFormService: ClaimFormService,
    protected matDialog: MatDialog,
    protected readonly userStore: UserStore,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initWizard();
    this.isServiceAddressChanged();
  }

  productNameChanged(productName: string, product: UntypedFormControl, index: number) {
    product.patchValue({
      productName,
    });
    this.navigationItems.coveredProducts.child[index].name = product.value.productName || `Product ${index + 1}`;
  }

  addProduct(goToStep: boolean = true) {
    (this.form.get('coveredProducts') as UntypedFormArray).push(this.getProductFields({}));
    const productStepIndex = this.navigationItems.coveredProducts.child.push(this.returnCoveredProductChild()) - 1;
    if (goToStep) {
      this.goToStep('coveredProducts');
      this.currentSubStep = productStepIndex;
    }
  }

  removeProduct(index: number) {
    (this.form.get('coveredProducts') as UntypedFormArray).removeAt(index);
    this.navigationItems.coveredProducts.child.splice(index, 1);
    this.navigationItems.coveredProducts.child.forEach((child, childIndex) => {
      child.subStep = childIndex;
    });
    if (index !== 0) {
      this.prevStep();
    }
  }

  removeIncident(incidentIndex: number, product: UntypedFormControl) {
    const incidentsControl = product.get('productIncidents') as UntypedFormArray;
    incidentsControl.removeAt(incidentIndex);
    product.updateValueAndValidity();
  }

  copyProduct(product: UntypedFormGroup, index: number) {
    if (!product.valid) {
      return;
    }
    const newControl = this.getProductFields({
      ...product.getRawValue(),
      id: null,
      crmRefId: null,
    });

    (this.form.get('coveredProducts') as UntypedFormArray).insert(index + 1, newControl);
    this.currentSubStep =
      this.navigationItems.coveredProducts.child.push(this.returnCoveredProductChild(newControl.value)) - 1;
  }

  addIncident(product: UntypedFormControl) {
    (product.get('productIncidents') as UntypedFormArray).push(this.getProductIncidentFields({}));
    product.updateValueAndValidity();
  }

  addProductsToForm(products: Product[], goToStep: boolean = true) {
    products.forEach((product, index) => {
      (this.form.get('coveredProducts') as UntypedFormArray).push(this.getProductFields(product, true));
      this.navigationItems.coveredProducts.child.push(this.returnCoveredProductChild(product));
      if (index === 0 && goToStep) {
        this.currentSubStep = this.navigationItems.coveredProducts.child.length - 1;
      }
    });
  }

  nextStep() {
    const session = this.userStore.get('session');
    if (
      session.roles[0] !== UserRoles.Consumer
      && this.form.value.consumer.info.id
      && this.currentStep.step === ClaimFormSteps.Consumer
      && !this.serviceAddressWasChecked
      && this.form.get('consumer.serviceAddress').dirty
    ) {
      this.promptDialogService.openPrompt({
        message: '<span>Should we update your service address on record with this address, or do you wish to just use it for this claim?</span>',
        buttonNo: 'CURRENT CLAIM',
        buttonYes: 'ALL CLAIMS',
      }).afterClosed().subscribe(result => {
        if (result !== null) {
          this.updateAddressOnAgreement = result;
          this.serviceAddressWasChecked = true;
          this.form.get('updateAddressOnAgreement').patchValue(this.updateAddressOnAgreement);
          super.nextStep();
        }
      });
    } else {
      super.nextStep();
    }
  }

  protected getProductIncidentFields(values: any) {
    return this.claimFormService.getProductIncidentFields(values);
  }

  protected getProductFields(product?: ProductInterface, readonly: boolean = false) {
    return this.claimFormService.getProductFields(product, readonly);
  }

  protected returnCoveredProductChild(product?: Product, editable: boolean = false): WizardNavigationChild {
    let readonlyValue = product && (product.id || product.crmRefId) ? !!product.id || !!product.crmRefId : false;
    if (editable) {
      readonlyValue = false;
    }
    return {
      name:
        product && (product.name || product.productName)
          ? product.name || product.productName
          : `Product ${this.navigationItems.coveredProducts.child.length + 1}`,
      subStep: this.navigationItems.coveredProducts.child.length,
      readonly: readonlyValue,
    };
  }

  private isServiceAddressChanged() {
    this.form
      .get('consumer.serviceAddress')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        if (this.form.value.consumer.info.id) {
          this.updateAddressOnAgreement = false;
          this.serviceAddressWasChecked = false;
        }
      });
  }
}
