<div>
  <p class="plan-lookup__title">Plan Lookup</p>
  <app-form-input-new [autocomplete]="'off'"
                      [(ngModel)]="planNumber"
                      [label]="'Plan Agreement Number'"
                      [required]="true"></app-form-input-new>
  <ng-container *cdkPortal>
    <div class="plan-lookup__footer">
      <div>
        <button type="button"
                mat-stroked-button
                (click)="openHowToFindPlan()">
          Where do I find my plan number?
        </button>
      </div>
      <button mat-flat-button
              appLoadingButton
              appSubmitBtn
              [class._common-button_submit-disabled]="!planNumber"
              [isLoading]="lookupInProgress$ | push"
              (click)="lookUpPlan($event, !planNumber)">Continue</button>
<!--      <p class="plan-lookup__link">Can’t find your plan number? <button mat-button class="link-button"-->
<!--                                              type="button"-->
<!--                                              (click)="openHowToFindPlan()">Click here</button>.</p>-->
    </div>
  </ng-container>
</div>
