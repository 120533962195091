<ng-container *rxLet="vm$; let vm">
  <app-files-prompt [filesForm]="vm.productFilesForm"
                    [showFilesList]="vm.files.length > 0"
                    [heroTitle]="heroTitle"
                    [heroImage]="heroImage"
                    [messageBody]="messageBody"
                    [warningMessage]="warningMessage"
                    [allowSkip]="allowSkip"
                    [multiple]="multiple"
                    (handleInputFiles)="handleInputFiles($event)"
                    (goToNextStep)="goToNextStep.emit()"
                    (actionsPortal)="setActionsPortal($event)">
    <app-section-header-with-action class="product-files-prompt__title" [sectionTitle]="sectionTitle"></app-section-header-with-action>
    <app-files-list-mobile [files]="vm.files"
                           [showDelete]="true"
                           (removeFile)="removeFile($event)"></app-files-list-mobile>
  </app-files-prompt>
</ng-container>
