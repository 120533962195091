import { Directive, ElementRef, Input } from '@angular/core';

import { Files } from '@core/interfaces/claims/files.interface';

@Directive({
  selector: '[appImageErrorStandalone]',
  standalone: true,
})
export class ImageErrorStandaloneDirective {
  @Input() file: Files;

  constructor(el: ElementRef) {
    el.nativeElement.onerror = (event): void => {
      if (window.navigator.onLine) {
        event.target.src = 'assets/icons/icon-brokenimage.svg';
        if (this.file) {
          this.file.loadFailed = true;
        }
      }
    };
  }
}
