import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { startWith } from 'rxjs/operators';

import { Files } from '@core/interfaces/claims/files.interface';

import { RegisterFppClaimStore } from '../../../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import {
  SectionHeaderWithActionComponent,
} from '../../../../../shared/components/section-header-with-action/section-header-with-action.component';
import {
  FilesListMobileComponent,
} from '../../../../shell-mobile/pages/claim-details/containers/files-list-mobile/files-list-mobile.component';
import {
  BaseWizardPageComponent,
} from '../../../register-plan-consumer-mobile/components/base-wizard-page/base-wizard-page.component';
import { FilesPromptComponent } from '../../components/files-prompt/files-prompt.component';

interface IFppClaimUploadReceiptState {
  isReceiptUploaded: boolean;
  foundFiles: Files[];
  addedFiles: Files[];
}

@Component({
  selector: 'app-fpp-claim-upload-receipt',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    MatButtonModule,
    FilesPromptComponent,
    SectionHeaderWithActionComponent,
    FilesListMobileComponent,
  ],
  templateUrl: './fpp-claim-upload-receipt.component.html',
  styleUrls: ['./fpp-claim-upload-receipt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RxState,
  ],
})
export class FppClaimUploadReceiptComponent extends BaseWizardPageComponent {
  vm$ = this.state.select();
  receiptFilesForm = this.store.getFilesForm().get('receiptFiles') as FormArray;

  constructor(
    private readonly state: RxState<IFppClaimUploadReceiptState>,
    private readonly store: RegisterFppClaimStore,
  ) {
    super();
    this.state.connect('isReceiptUploaded', this.store.select$('isReceiptUploaded'));
    this.state.connect(this.receiptFilesForm.valueChanges.pipe(
      startWith(this.receiptFilesForm.value),
    ), (oldState, files) => {
      const foundFiles = files.filter((file: Files) => file.isReadOnly);
      const addedFiles = files.filter((file: Files) => !file.isReadOnly);
      return {
        ...oldState,
        foundFiles,
        addedFiles,
      };
    });
  }

  handleInputFiles($event: Event): void {
    this.store.handleReceiptFiles($event);
  }

  removeFile(fileIndex: number): void {
    this.store.removeReceiptFile(fileIndex);
  }

  goToEntireProductFileUpload(): void {
    this.store.wizardNavigationStore.setStep('review');
  }
}
