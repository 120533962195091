<ng-container *ngIf="files.length > 0; else noFilesMessageTemplate">
  <div class="files-list">
    <div class="files-list__file-wrapper" *rxFor="let file of files">
      <img class="files-list__file"
           [src]="file.thumbnailUrl"
           [alt]="file.fileName"
           appImageErrorStandalone
           [file]="file"
           (click)="fileClick(file)">
      <mat-icon *ngIf="showDelete && !file.isReadOnly"
                (click)="removeFileFn(file.index)"
                svgIcon="delete-button"
                class="files-list__remove-icon"></mat-icon>
    </div>
  </div>
</ng-container>
<ng-template #noFilesMessageTemplate>
  {{ noFilesMessage }}
</ng-template>
