<div
  class="fpp-claim-review"
  *rxLet="vm$; let vm">
  <p class="fpp-claim-review__title">Claim Review</p>
  <app-expandable-card-group sectionTitle="Claim Details">
    <app-expandable-card cardTitle="Protection Plan">
      <app-plan-details-card [planDetails]="vm.planDetails.planInfo"></app-plan-details-card>
    </app-expandable-card>
    <app-expandable-card cardTitle="Consumer Details">
      <app-consumer-info-card
        [consumerInfo]="vm.claim.consumer.info"
        [consumerAddress]="vm.claim.consumer.consumerAddress"
        [serviceAddress]="vm.claim.consumer.serviceAddress"></app-consumer-info-card>
    </app-expandable-card>
    <app-expandable-card
      *rxLet="vm$; let vm"
      cardTitle="Claim Incidents">
      <app-expandable-card-with-pagination
        [items]="vm.claim.coveredProducts"
        noResultsText="No incidents found"
        [cardTemplate]="incidentCard"></app-expandable-card-with-pagination>
    </app-expandable-card>
  </app-expandable-card-group>

  <app-expandable-card-group sectionTitle="Claim Images">
    <app-expandable-card cardTitle="Purchase Receipt">
      <app-files-list-mobile [files]="vm.receiptFiles"></app-files-list-mobile>
    </app-expandable-card>
    <app-expandable-card cardTitle="Product Files">
      <app-files-list-mobile [files]="vm.productFiles"></app-files-list-mobile>
    </app-expandable-card>
  </app-expandable-card-group>
  <ng-container *cdkPortal>
    <button
      mat-flat-button
      appLoadingButton
      [isLoading]="vm.submitInProgress"
      [disabled]="form.invalid"
      (click)="submit()">
      Submit
    </button>
  </ng-container>
</div>
<ng-template
  #incidentCard
  let-activeItem>
  <app-claim-wizard-incident-card [product]="activeItem"></app-claim-wizard-incident-card>
</ng-template>
