import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';

import { Files } from '@core/interfaces/claims/files.interface';
import { IConsumerForm } from '@core/interfaces/consumer/consumer.interface';
import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';

import { RegisterFppClaimStore } from '../../../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import { IClaimProductForm } from '../../../../../core/interfaces/claim-incident-form.interface';
import {
  ExpandableCardGroupComponent,
} from '../../../../../shared/components/expandable-card-group/expandable-card-group.component';
import {
  ConsumerInfoCardComponent,
} from '../../../../../shared/components/expandable-cards/consumer-info-card/consumer-info-card.component';
import {
  ExpandableCardComponent,
} from '../../../../../shared/components/expandable-cards/expandable-card/expandable-card.component';
import {
  PlanDetailsCardComponent,
} from '../../../../../shared/components/expandable-cards/plan-details-card/plan-details-card.component';
import {
  ClaimWizardIncidentCardComponent,
} from '../../../../shell-mobile/pages/claim-details/components/claim-wizard-incident-card/claim-wizard-incident-card.component';
import {
  ExpandableCardWithPaginationComponent,
} from '../../../../shell-mobile/pages/claim-details/containers/expandable-card-with-pagination/expandable-card-with-pagination.component';
import {
  FilesListMobileComponent,
} from '../../../../shell-mobile/pages/claim-details/containers/files-list-mobile/files-list-mobile.component';
import {
  BaseWizardPageComponent,
} from '../../../register-plan-consumer-mobile/components/base-wizard-page/base-wizard-page.component';

interface IFppClaimReviewState {
  claim: IClaimForm;
  receiptFiles: Files[];
  productFiles: Files[];
  planDetails: PlanDetails;
  submitInProgress: boolean;
}

interface IClaimForm {
  consumer: IConsumerForm;
  coveredProducts: IClaimProductForm[];
}

@Component({
  selector: 'app-fpp-claim-review',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    ConsumerInfoCardComponent,
    ExpandableCardComponent,
    PlanDetailsCardComponent,
    ExpandableCardWithPaginationComponent,
    ClaimWizardIncidentCardComponent,
    FilesListMobileComponent,
    MatButtonModule,
    PortalModule,
    LoadingButtonDirective,
    ExpandableCardGroupComponent,
  ],
  templateUrl: './fpp-claim-review.component.html',
  styleUrls: ['./fpp-claim-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class FppClaimReviewComponent extends BaseWizardPageComponent {
  vm$ = this.state.select();
  form = this.store.form;

  constructor(
    private readonly state: RxState<IFppClaimReviewState>,
    private readonly store: RegisterFppClaimStore,
  ) {
    super();
    this.state.set({
      claim: this.store.getForm().getRawValue(),
      planDetails: this.store.get('planDetails'),
      receiptFiles: this.store.getReceiptFiles(),
      productFiles: this.store.getProductFilesAll(),
    });
    this.state.connect('submitInProgress', this.store.select$('submitInProgress'));
  }

  submit(): void {
    this.store.submitForm().subscribe(() => {
      this.store.wizardNavigationStore.setStep('finish');
    });
  }
}
