import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CrudApiBaseService } from './crud-api.base';

@Injectable({
  providedIn: 'root',
})
export class ProductApiService extends CrudApiBaseService<any> {
  endpoint = 'product';

  getByGuid(guidId: string): Observable<any> {
    return this.httpClient.get<any>(this.getUrl(`${guidId}`));
  }
}
