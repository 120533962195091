<div class="add-products dialog">
  <div *ngIf="config.existed" class="add-products__exist-products">
    <div>
      <h3>Select another product from the same purchase</h3>
      <app-form-select [data]="products$ | async"
                       [(ngModel)]="selectedProducts"
                       [ngModelOptions]="{standalone: true}"
                       [multiple]="true"
                       [label]="'Products'"
                       [disabled]="selectInProgress$ | async"></app-form-select>
    </div>
    <div>
      <button mat-flat-button color="primary" appLoadingButton [isLoading]="selectInProgress$ | async" (click)="emitSelectProducts()" [disabled]="selectedProducts.length === 0">
        ADD SELECTED PRODUCTS
      </button>
    </div>
  </div>
  <div *ngIf="(config.fromScratch && config.existed) && config.displayButtonAddNewProductsToClaim" class="break-line">
    <div class="break-line__text-wrapper">
      <span class="break-line__text">OR</span>
    </div>
  </div>
  <div *ngIf="config.fromScratch && config.displayButtonAddNewProductsToClaim">
    <label class="add-button-label"
           (click)="newProduct.emit()">
      <button type="button"
              class="_common-button--circle add-button"
              mat-flat-button
              color="primary">
        <mat-icon class="_common-icon--white" svgIcon="add-icon"></mat-icon>
      </button>
      <span>ADD A NEW PRODUCT</span>
    </label>
  </div>
</div>
