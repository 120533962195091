import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RegisterPlanForm, RegisterPlanStore } from '@consumer-core/stores/register-plan/register-plan.store';
import { RxState } from '@rx-angular/state';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { PushPipe } from '@rx-angular/template/push';

import { Address } from '@core/interfaces/address.interface';
import { Files } from '@core/interfaces/claims/files.interface';
import { ImageErrorStandaloneDirective } from '@shared/directives/image-error-standalone.directive';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { PipesModule } from '@shared/pipes/pipes.module';

import {
  ExpandableCardGroupComponent,
} from '../../../../../shared/components/expandable-card-group/expandable-card-group.component';
import {
  ExpandableCardComponent,
} from '../../../../../shared/components/expandable-cards/expandable-card/expandable-card.component';
import { LabelValueComponent } from '../../../../../shared/components/label-value/label-value.component';
import {
  SectionHeaderWithActionComponent,
} from '../../../../../shared/components/section-header-with-action/section-header-with-action.component';
import { BaseWizardPageComponent } from '../../components/base-wizard-page/base-wizard-page.component';

interface IPlanReviewMobileState {}

@Component({
  selector: 'app-plan-review-mobile',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    PipesModule,
    ExpandableCardComponent,
    LabelValueComponent,
    MatButtonModule,
    PortalModule,
    ImageErrorStandaloneDirective,
    RxFor,
    SectionHeaderWithActionComponent,
    PushPipe,
    RxIf,
    LoadingButtonDirective,
    ExpandableCardGroupComponent,
  ],
  templateUrl: './plan-review-mobile.component.html',
  styleUrls: ['./plan-review-mobile.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanReviewMobileComponent extends BaseWizardPageComponent {
  formValue = this.store.form.getRawValue() as RegisterPlanForm;
  files = this.store.filesForm.value as Files[];
  addressString = this._parseAddress(this.formValue.consumer.consumerAddress);
  serviceAddressString = this._parseAddress(this.store.getServiceAddress());

  submitInProgress$ = this.store.select$('submitInProgress');

  constructor(
    private readonly store: RegisterPlanStore,
    private readonly state: RxState<IPlanReviewMobileState>,
  ) {
    super();
  }

  submit($event: MouseEvent): void {
    $event.preventDefault();
    this.store.submit();
  }

  private _parseAddress(address: Address): string {
    return [
      [
        address.streetAddress1,
        address.streetAddress2,
      ].filter(value => !!value).join(', '),
      [
        address.city,
        [
          address.stateProvince,
          address.postalCode,
        ].filter(value => !!value).join(' '),
      ].filter(value => !!value).join(', '),
    ].join('<br>');
  }
}
