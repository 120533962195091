import { Files } from '@core/interfaces/claims/files.interface';
import { PlanDetails } from '@core/interfaces/plan/plan.interface';

export namespace StoreState {
  export interface IStoreState {
    plan: PlanDetails;
    loading: boolean;
    filesLoading: boolean;
    isReceiptUploaded: boolean;
    receiptFilesLoading: boolean;
    currentId: number;
    files?: Files[];
    receiptFiles?: Files[];
  }

  export const initialState: IStoreState = {
    plan: null,
    currentId: null,
    loading: false,
    filesLoading: false,
    isReceiptUploaded: true,
    receiptFilesLoading: false,
    files: [],
    receiptFiles: [],
  };
}
