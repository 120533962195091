import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
  color: 'primary',
  appearance: 'fill',
  floatLabel: 'auto',
};

const matFormFieldDefaultOptionsTechPortal: MatFormFieldDefaultOptions = {
  color: 'primary',
  appearance: 'outline',
};

export const MAT3_FORM_FIELD_DEFAULT_OPTIONS = {
  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
  useValue: matFormFieldDefaultOptions,
};

export const TECH_PORTAL_FORM_FIELD_DEFAULT_OPTIONS = {
  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
  useValue: matFormFieldDefaultOptionsTechPortal,
};
