import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { WizardPortalService } from '@consumer-core/services/wizard-portal.service';
import { RegisterPlanStore } from '@consumer-core/stores/register-plan/register-plan.store';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { map } from 'rxjs/operators';

import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';


interface IPlanFoundMobileState {
  planName: string;
  retailerName: string;
  consumerLoading: boolean;
}

@Component({
  selector: 'app-plan-found-mobile',
  standalone: true,
  imports: [CommonModule, LetDirective, MatIconModule, MatButtonModule, PortalModule, LoadingButtonDirective],
  templateUrl: './plan-found-mobile.component.html',
  styleUrls: ['./plan-found-mobile.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanFoundMobileComponent {
  vm$ = this.state.select();

  @ViewChild(CdkPortal) set actionsPortal(actionsPortal: CdkPortal) {
    // this._footerPortal = footerPortal;
    this.wizardPortalService.setActionsPortal(actionsPortal);
  }

  constructor(
    private readonly state: RxState<IPlanFoundMobileState>,
    private readonly store: RegisterPlanStore,
    private readonly wizardPortalService: WizardPortalService,
  ) {
    this.state.connect('consumerLoading', this.store.select$('consumerLoading'));
    this.state.connect(this.store.select$('planLookup').pipe(
      map(planLookup => ({
        planName: planLookup.consumerPlan.parentPlanName,
        retailerName: planLookup.consumerPlan.retailerName,
      })),
    ));
  }

  backToLookup(): void {
    this.store.wizardNavigationStore.setStep('lookupPlan');
  }

  confirmPlanSelection(): void {
    this.store.confirmPlanSelection();
  }
}
