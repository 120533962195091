import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';

import { HeroBannerComponent } from '../../../../../shared/components/hero-banner/hero-banner.component';
import {
  BaseWizardPageComponent,
} from '../../../register-plan-consumer-mobile/components/base-wizard-page/base-wizard-page.component';

@Component({
  selector: 'app-fpp-claim-finish',
  standalone: true,
  imports: [CommonModule, MatButtonModule, HeroBannerComponent, PortalModule],
  templateUrl: './fpp-claim-finish.component.html',
  styleUrls: ['./fpp-claim-finish.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FppClaimFinishComponent extends BaseWizardPageComponent {

  constructor(
    private readonly dialogState: SideDialogStateService,
  ) {
    super();
  }

  closeDialog(): void {
    this.dialogState.close();
  }
}
