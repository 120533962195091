import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { TConsumerPlanRegistrationState } from '@core/enums/plan/consumer-plan-registration-state.enum';
import { IConsumer, IConsumerForm, IConsumerInfo } from '@core/interfaces/consumer/consumer.interface';
import { PlanLookup } from '@core/interfaces/plan/plan.interface';
import { ClaimFormService } from '@core/services/claim-form.service';
import { BaseStore } from '@core/store/_base/base.store';
import { AddressType } from '@shared/modules/claim-dialogs/enums/address-type.enum';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { MyPlanApiService } from '../../../app/core/api/my-plan-api.service';
import { StoreState, TRegisterPlanNavigationStep } from './register-plan.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;

import { WizardNavigationStore } from '@consumer-core/stores/wizard-navigation/wizard-navigation.store';
import moment from 'moment';

import { MyContactApiService } from '@core/api/my-contact-api.service';
import { Address } from '@core/interfaces/address.interface';
import { Files } from '@core/interfaces/claims/files.interface';
import { ClaimUtil } from '@core/utils/claim.util';
import { validateArrayMaxFilesSize } from '@shared/common/form-validators';

export interface IConsumerPlanForm {
  crmRefId: string;
  clientRefObject: number;
  receiptNumber: string;
  planPrice: number;
  purchaseDate: string;
  deliveryDate: string;
}

export interface RegisterPlanForm {
  consumerPlan: IConsumerPlanForm;
  consumer: IConsumerForm;
  isServiceAddressSame: boolean;
}

@Injectable()
export class RegisterPlanStore extends BaseStore<IStoreState> {
  form: FormGroup;
  filesForm = this.fb.array<Files>([], [validateArrayMaxFilesSize()]);
  initialConsumer: IConsumer;
  consumerNameAndEmailKeys: string[] = ['firstName', 'lastName', 'emailAddress'];
  consumerKeysWithoutNameAndEmail = [
    'mobilePhone',
    'homePhone',
    'workPhone',
    'preferredContactLanguage',
    'preferredContactMethod',
    'preferredContactTime',
    'streetAddress1',
    'streetAddress2',
    'city',
    'stateProvince',
    'postalCode',
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly claimFormService: ClaimFormService,
    private readonly myPlanApiService: MyPlanApiService,
    private readonly notificationService: NotificationService,
    private readonly myContactApiService: MyContactApiService,
    public readonly wizardNavigationStore: WizardNavigationStore<TRegisterPlanNavigationStep>,
  ) {
    super(initialState);
  }

  lookUpPlan(planNumber: string): void {
    const lookupInProgress = this.get('lookupInProgress');
    if (!lookupInProgress && planNumber) {
      this.updateState({
        lookupInProgress: true,
      });
      this.myPlanApiService
        .findMyPlan(planNumber)
        .pipe(
          finalize(() => {
            this.updateState({
              lookupInProgress: false,
            });
          }),
        )
        .subscribe(planLookup => {
          if (planLookup) {
            let registrationType;

            if (planLookup.errorMessage) {
              registrationType = TConsumerPlanRegistrationState.Error;
            } else if (planLookup.consumer) {
              registrationType = TConsumerPlanRegistrationState.DynamicPlanWithSameEmail;
            } else if (planLookup.conflictEmail) {
              registrationType = TConsumerPlanRegistrationState.DynamicPlanConflictEmail;
            } else if (planLookup.conflictPhoneNumber) {
              registrationType = TConsumerPlanRegistrationState.DynamicPlanConflictPhone;
            } else if (planLookup.hasAddressConflict) {
              registrationType = TConsumerPlanRegistrationState.DynamicPlanConflictAddress;
            } else {
              registrationType = TConsumerPlanRegistrationState.PaperPlan;
            }

            const verificationOptions = [];
            if (planLookup.conflictEmail) {
              verificationOptions.push('email');
            }

            if (planLookup.conflictPhoneNumber) {
              verificationOptions.push('phone');
            }

            if (planLookup.hasAddressConflict) {
              verificationOptions.push('address');
            }

            this.updateState({
              planLookup,
              registrationType,
              planNumber,
              verificationOptions,
            });
            this.wizardNavigationStore.setStep('planFound');
          } else {
            this.updateState({
              planLookup: null,
              registrationType: null,
              planNumber: '',
            });
            this.notificationService.next({
              message: `Protection plan with name ${planNumber} not found.`,
              type: NotificationType.Error,
            });
          }
        });
    }
  }

  goToPurchaseDetails(consumer: IConsumer): void {
    const planLookup = this.get('planLookup');
    const registrationType = this.get('registrationType');
    let serviceAddress;
    let isServiceAddressSame = false;

    if (this._isPlanAddressExists(planLookup)) {
      serviceAddress = planLookup.serviceAddress;
    } else if (consumer) {
      serviceAddress = {
        streetAddress1: consumer.streetAddress1,
        streetAddress2: consumer.streetAddress2,
        city: consumer.city,
        stateProvince: consumer.stateProvince,
        postalCode: consumer.postalCode,
      };
      isServiceAddressSame = true;
    }

    this.initialConsumer = { ...consumer };
    this.form = this.fb.group({
      consumerPlan: this.claimFormService.getConsumerPlanFields(
        planLookup.consumerPlan,
        registrationType !== TConsumerPlanRegistrationState.PaperPlan,
      ),
      consumer: this.fb.group({
        info: this.claimFormService.getConsumerFields(consumer || {}, false, {
          excludedFields: ['preferredContactLanguage', 'preferredContactMethod', 'preferredContactTime'],
        }),
        consumerAddress: this.claimFormService.getAddressFields(consumer || {}, AddressType.CustomerAddress),
        serviceAddress: this.claimFormService.getAddressFields(serviceAddress || {}, AddressType.ServiceAddress),
      }),
      isServiceAddressSame: [isServiceAddressSame],
    });

    if (registrationType !== TConsumerPlanRegistrationState.PaperPlan) {
      this.form.get('consumer.info.firstName').disable();
      this.form.get('consumer.info.lastName').disable();
    }

    if (consumer && !consumer.emailAddress) {
      this.form.get('consumer.info.emailAddress').clearValidators();
      // this.form.get('consumer.info.emailAddress').updateValueAndValidity();
    }
    this.wizardNavigationStore.setStep('purchaseDetails');
  }

  confirmPlanSelection(): void {
    const registrationType = this.get('registrationType');

    if (![
      TConsumerPlanRegistrationState.DynamicPlanWithSameEmail, TConsumerPlanRegistrationState.PaperPlan,
    ].includes(registrationType)) {
      this.wizardNavigationStore.setStep('confirmPlan');
      return;
    }

    if (registrationType === TConsumerPlanRegistrationState.DynamicPlanWithSameEmail) {
      this.goToPurchaseDetails(this.get('planLookup', 'consumer'));
    } else if (registrationType === TConsumerPlanRegistrationState.PaperPlan) {
      this.updateState({
        consumerLoading: true,
      });
      this.myPlanApiService
        .me()
        .pipe(
          finalize(() => {
            this.updateState({
              consumerLoading: false,
            });
          }),
        )
        .subscribe(consumer => {
          this.goToPurchaseDetails(consumer || null);
        });
    }
  }

  goToEmailVerification(): void {
    this.updateState({
      registrationType: TConsumerPlanRegistrationState.DynamicPlanConflictEmail,
    });
  }

  goToPhoneVerification(): void {
    this.updateState({
      registrationType: TConsumerPlanRegistrationState.DynamicPlanConflictPhone,
    });
  }

  goToAddressVerification(): void {
    this.updateState({
      registrationType: TConsumerPlanRegistrationState.DynamicPlanConflictAddress,
    });
  }

  completeVerification(planLookup: PlanLookup): void {
    this.updateState({
      planLookup,
    });
    this.goToPurchaseDetails(planLookup.consumer);
  }

  removeFile(fileIndex: number): void {
    const updatedFiles = this.filesForm.value.filter(file => fileIndex !== file.index);
    (this.filesForm as FormArray).clear();
    updatedFiles.forEach(file => {
      (this.filesForm as FormArray).push(this.fb.control(file));
    });
  }

  getServiceAddress(): Address {
    const isServiceAddressSame = this.form.value.isServiceAddressSame;
    if (isServiceAddressSame) {
      return this.form.value.consumer.consumerAddress;
    } else {
      return this.form.value.consumer.serviceAddress;
    }
  }

  submit(): void {
    if (this.form.valid) {
      this.updateState({
        submitInProgress: true,
      });
      const consumerForm = this.form.get('consumer.info') as FormGroup;
      const consumerAddressForm = this.form.get('consumer.consumerAddress') as FormGroup;
      const registrationType = this.get('registrationType');
      const planValue: {
        consumerPlan: IConsumerPlanForm;
        consumer: any;
        consumerAddress?: Address;
        serviceAddress?: Address;
      } = {
        consumer: consumerForm.getRawValue(),
        consumerPlan: registrationType === TConsumerPlanRegistrationState.PaperPlan ? this.form.value.consumerPlan : {
          crmRefId: this.form.value.consumerPlan.crmRefId,
        },
      };

      if (registrationType === TConsumerPlanRegistrationState.PaperPlan) {
        planValue.consumerPlan.purchaseDate = moment(planValue.consumerPlan.purchaseDate).utc(true).format();
        planValue.consumerPlan.deliveryDate = moment(planValue.consumerPlan.deliveryDate).utc(true).format();
      }

      if (!planValue.consumer.id && !planValue.consumer.crmRefId) {
        planValue.consumerAddress = consumerAddressForm.value;
        this._registerPlan(planValue, this.filesForm.value);
      } else {
        if (
          registrationType === TConsumerPlanRegistrationState.PaperPlan
          && this._consumerWasChanged(planValue.consumer, this.consumerNameAndEmailKeys)
        ) {
          planValue.consumer.id = null;
          planValue.consumer.crmRefId = null;
          planValue.consumerAddress = consumerAddressForm.value;
        } else {
          if (
            this._consumerWasChanged(
              { ...consumerForm.value, ...consumerAddressForm.value },
              this.consumerKeysWithoutNameAndEmail,
            )
          ) {
            this._updateContactInfoAndRegisterPlan(
              {
                ...planValue.consumer,
                ...consumerAddressForm.value,
              },
              planValue,
            );
            return;
          } else {
            planValue.consumer = {
              id: planValue.consumer.id,
            };
          }
        }
        this._registerPlan(planValue, this.filesForm.value);
      }
    }
  }

  private _isPlanAddressExists(plan: PlanLookup): boolean {
    return (
      !!plan.serviceAddress
      && !!plan.serviceAddress.streetAddress1
      && !!plan.serviceAddress.stateProvince
      && !!plan.serviceAddress.city
      && !!plan.serviceAddress.postalCode
    );
  }

  private _consumerWasChanged(newConsumer: IConsumerInfo, fields: string[]): boolean {
    let consumerWasChanged = false;
    if (this.initialConsumer) {
      fields.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(newConsumer, key)) {
          if (newConsumer[key] !== this.initialConsumer[key]) {
            consumerWasChanged = true;
          }
        }
      });
    } else {
      consumerWasChanged = true;
    }
    return consumerWasChanged;
  }

  private _updateContactInfoAndRegisterPlan(consumer: IConsumer, plan: {
    consumerPlan: IConsumerPlanForm;
    consumer: IConsumerInfo;
    consumerAddress?: Address;
    serviceAddress?: Address;
  }): void {
    const planNumber = this.get('planNumber');
    this.myContactApiService
      .contactInfo({
        ...consumer,
        planNumber,
      })
      .subscribe({
        next: () => {
          this._registerPlan(
            {
              ...plan,
              consumer: {
                id: plan.consumer.id,
                crmRefId: plan.consumer.crmRefId,
              },
            },
            this.filesForm.value,
          );
        },
        error: () => {
          this.updateState({
            submitInProgress: false,
          });
        },
      });
  }

  private _registerPlan(planValue: any, files: any[]): void {
    const planLookup = this.get('planLookup');
    const isServiceAddressSame = this.form.value.isServiceAddressSame;
    if (isServiceAddressSame) {
      planValue.serviceAddress = this.form.value.consumer.consumerAddress;
    } else if (
      this._isPlanAddressExists(planLookup)
      && !ClaimUtil.compareAddresses(this.form.value.consumer.serviceAddress, planLookup.serviceAddress)
    ) {
      planValue.serviceAddress = this.form.value.consumer.serviceAddress;
    } else {
      planValue.serviceAddress = this.form.value.consumer.serviceAddress;
    }
    this.myPlanApiService
      .registerMyPlan(planValue, files)
      .pipe(
        finalize(() => {
          this.updateState({
            submitInProgress: false,
          });
        }),
      )
      .subscribe(() => {
        this.wizardNavigationStore.setStep('finish');
      });
  }
}
