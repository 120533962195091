import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Claim } from '@core/interfaces/claims/claim.interface';
import { DialogService } from '@core/services/dialog.service';

import { ConsumerClaimService } from '../../../../../core/services/consumer-claim.service';

@Component({
  selector: 'app-inquire-claim',
  templateUrl: './inquire-claim.component.html',
  styleUrls: ['./inquire-claim.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InquireClaimComponent {
  claim: Claim;
  isLoading$$ = new BehaviorSubject(false);
  isLoading$ = this.isLoading$$.asObservable();
  form = this.fb.group({
    note: ['', Validators.compose([Validators.required, Validators.maxLength(4000)])],
  });

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected readonly dialogService: DialogService,
    private readonly fb: FormBuilder,
    private readonly consumerClaimService: ConsumerClaimService,
  ) {
    this.claim = this.data.data.claim;
  }

  onClose(): void {
    const isLoading = this.isLoading$$.getValue();
    if (isLoading) {
      return;
    }
    this.dialogService.canLeaveDefaultConfirmationObservable(this.form).subscribe(canLeave => {
      if (canLeave) {
        this.dialogRef.close();
      } else {
        return;
      }
    });
  }

  saveNote(): void {
    this.isLoading$$.next(true);
    this.consumerClaimService.saveNote(this.claim.id, this.form.value.note).pipe(
      finalize(() => this.isLoading$$.next(false)),
    ).subscribe(() => {
      this.form.reset({
        note: '',
      });
      this.form.markAsPristine();
      this.dialogRef.close();
    });
  }
}
