<div class="fpp-claim-guidance" *rxLet="vm$; let vm">
  <app-hero-banner [heroTitle]="vm.activeBanner.heroTitle"
                   [heroImage]="vm.activeBanner.heroImage"
                   [messageTitle]="vm.activeBanner.messageTitle"
                   [messageBody]="vm.activeBanner.messageBody"></app-hero-banner>
  <ng-container *cdkPortal>
    <div class="fpp-claim-guidance__footer">
      <button *ngIf="vm.activeIndex === 0; else backBtnTemplate"
              class="fpp-claim-guidance__button"
              mat-button
              color="primary"
              appLoadingButton
              [isLoading]="vm.skipLoading"
              [disabled]="vm.nextLoading"
              (click)="skipGuidance()">Skip</button>
      <app-pagination-indicator [size]="guidanceBanners.length"
                                [activeIndex]="vm.activeIndex"></app-pagination-indicator>
      <button class="fpp-claim-guidance__button"
              mat-button color="primary"
              appLoadingButton
              [isLoading]="vm.nextLoading"
              [disabled]="vm.skipLoading"
              (click)="nextItem(vm.activeIndex)">Next</button>
    </div>
    <ng-template #backBtnTemplate>
      <button class="fpp-claim-guidance__button"
              mat-button
              color="primary"
              [disabled]="vm.skipLoading || vm.nextLoading"
              (click)="prevItem(vm.activeIndex)">Back</button>
    </ng-template>
  </ng-container>
</div>
