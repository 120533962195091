import { importProvidersFrom } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { CoreModule } from '@core/core.module';
import { MainHub } from '@core/services/hubs/main.hub';
import { NavigationService } from '@core/services/navigation.service';
import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';
import { GridModule } from '@shared/modules/grid/grid.module';
import { NotificationModule } from '@shared/modules/notification/notification.module';

import { CONSUMER_APP_ROUTES } from './app.routes';
import { ConsumerMainHub } from './core/services/consumer-main.hub';
import { ConsumerNavigationService } from './core/services/consumer-navigation.service';
import { MyClaimsStore } from './core/store/my-claims/my-claims.store';
import { ConsumerPlanDetailsModule } from './shared/modules/consumer-plan-details/consumer-plan-details.module';

export const CONSUMER_APP = {
  providers: [
    importProvidersFrom(
      CoreModule,
      NotificationModule,
      GridModule,
      ConsumerPlanDetailsModule,
      DialogCoreModule,
      MatButtonModule,
    ),
    provideRouter(
      CONSUMER_APP_ROUTES,
      // withPreloading(PreloadAllModules),
      // withDebugTracing(),
    ),
    provideAnimations(),
    {
      provide: MainHub,
      useClass: ConsumerMainHub,
    },
    {
      provide: NavigationService,
      useClass: ConsumerNavigationService,
    },
    MyClaimsStore,
  ],
};
