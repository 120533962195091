import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

import { Plan } from '@core/interfaces/plan/plan.interface';
import { PipesModule } from '@shared/pipes/pipes.module';

@Component({
  selector: 'app-plan-item-card',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    PipesModule,
  ],
  templateUrl: './plan-item-card.component.html',
  styleUrls: ['./plan-item-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanItemCardComponent {
  @Input() plan: Plan;
  @Input() disabled = false;
}
