import { NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PushPipe } from '@rx-angular/template/push';
import { buildVariables } from '@th-common/assets/config/build-variables';
import { filter, first, switchMap, withLatestFrom } from 'rxjs/operators';

import { SystemApiService } from '@core/api/system-api.service';
import { APP_MOBILE_DETECT } from '@core/constants/app-config.constants';
import { ApplicationInsightsService } from '@core/services/application-insights/application-insights.service';
import { AuthService } from '@core/services/auth.service';
import { SystemService } from '@core/services/system.service';
import { ThemingService } from '@core/services/theming.service';
import { UserStore } from '@core/store/user/user.store';
import { ApplicationLoaderComponent } from '@shared/application-loader/application-loader.component';
import { NotificationModule } from '@shared/modules/notification/notification.module';

import { FiveNineChatComponent } from './shared/components/five-nine-chat/five-nine-chat.component';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    NotificationModule,
    RouterOutlet,
    FiveNineChatComponent,
    NgIf,
    PushPipe,
    ApplicationLoaderComponent,
  ],
})
export class AppComponent implements OnInit {
  showFive9Chat$ = this.userStore.select$('showFive9Chat');
  applicationInsightsService = inject(ApplicationInsightsService);
  appMobileDetect = inject(APP_MOBILE_DETECT);

  constructor(
    private themingService: ThemingService,
    private systemApiService: SystemApiService,
    private systemService: SystemService,
    private authService: AuthService,
    private userStore: UserStore,
  ) {
    this.applicationInsightsService.logEvent('isMobileDevice', this.appMobileDetect);
  }

  ngOnInit(): void {
    this.themingService.themeSubject$.subscribe(theme => {
      const root = document.documentElement;
      this.themingService.setThemeVariables(theme, root);
    });

    window.addEventListener('dragover', e => e && e.preventDefault(), false);
    window.addEventListener('drop', e => e && e.preventDefault(), false);

    const appRoot = document.querySelector('#app-root');
    if (appRoot) {
      appRoot.setAttribute('build-version', buildVariables.buildNumber);
    }

    this.authService.tokenResolverChecked$
      .pipe(
        withLatestFrom(this.authService.authorized$),
        filter(([tokenResolverChecked, authorized]) => (authorized ? tokenResolverChecked : true)),
        first(),
        switchMap(() => this.systemApiService.buildNumber()),
      )
      .subscribe(buildNumber => {
        this.systemService.buildNumber$.next(buildNumber);
        if (appRoot) {
          appRoot.setAttribute('server-version', buildNumber);
        }
      });
  }
}
