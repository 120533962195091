import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';

const matTabsConfig: MatTabsConfig = {
  animationDuration: '0',
};

export const MAT3_TABS_CONFIG = {
  provide: MAT_TABS_CONFIG,
  useValue: matTabsConfig,
};
