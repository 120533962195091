import { HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, ResolveFn } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ClaimDetails } from '@core/interfaces/claims/claimDetails.interface';
import { Theme } from '@core/interfaces/ui/theme.interface';
import { ThemingService } from '@core/services/theming.service';

import { MyClaimApiService } from '../api/my-claim-api.service';

@Injectable({ providedIn: 'root' })
export class ConsumerFilesPublicThemeResolver implements Resolve<any> {
  constructor(private readonly myClaimApiService: MyClaimApiService, private readonly themingService: ThemingService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.myClaimApiService.tenantInfo(route.queryParams.token).pipe(
      map(claimTenantInfo => {
        this.themingService.forClaimTenantInfo(claimTenantInfo.tenantInfo);
        return claimTenantInfo;
      }),
      catchError(err => {
        if (err.status === HttpStatusCode.BadRequest) {
          err.error.displayMessage =
            'This link has expired and is no longer available. Please contact Montage Operations via the Contact Info section of the Consumer Portal to discuss next steps.';
        }

        return throwError(err);
      }),
    );
  }
}

export const consumerFilesPublicThemeResolver: ResolveFn<ClaimDetails & {tenantInfo: Theme}> = (route: ActivatedRouteSnapshot) => {
  const themingService = inject(ThemingService);
  const myClaimApiService = inject(MyClaimApiService);
  return myClaimApiService.tenantInfo(route.queryParams.token).pipe(
    map(claimTenantInfo => {
      themingService.forClaimTenantInfo(claimTenantInfo.tenantInfo);
      return claimTenantInfo;
    }),
    catchError(err => {
      if (err.status === HttpStatusCode.BadRequest) {
        err.error.displayMessage =
          // eslint-disable-next-line max-len
          'This link has expired and is no longer available. Please contact Montage Operations via the Contact Info section of the Consumer Portal to discuss next steps.';
      }

      return throwError(err);
    }),
  );
};
