import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

import {
  AUTO_CLOSE_TIMEOUT,
} from '@shared/modules/notification/components/notification-popup/notification-popup.component';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationInterface } from '@shared/modules/notification/interfaces/notification.interface';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

const NOTIFICATION_TYPE_CLASS_MAP = {
  [NotificationType.Common]: 'snack-bar_common',
  [NotificationType.Error]: 'snack-bar_error',
  [NotificationType.Success]: 'snack-bar_success',
};

@Component({
  selector: 'app-mobile-notification-snack-bar',
  standalone: true,
  imports: [CommonModule, MatSnackBarModule],
  templateUrl: './mobile-notification-snack-bar.component.html',
  styleUrls: ['./mobile-notification-snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNotificationSnackBarComponent implements OnInit {
  constructor(
    private snackBar: MatSnackBar,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.notificationService.notification$.subscribe(notification => {
      this._showMessage(notification);
    });
  }

  private _showMessage(notification: NotificationInterface): void {
    const notificationType = notification.type || NotificationType.Common;
    const duration = notification.duration || AUTO_CLOSE_TIMEOUT;

    this.snackBar.open(notification.message, '✕', {
      duration,
      panelClass: ['snack-bar', NOTIFICATION_TYPE_CLASS_MAP[notificationType]],
    });
  }
}
