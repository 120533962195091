import { Injectable } from '@angular/core';

import { Claim } from '@core/interfaces/claims/claim.interface';
import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { HubEvent, THubMethods } from '@core/models/hub-event.model';
import { BaseHub } from '@core/services/hubs/base.hub';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

import { ClaimSuccessMobileComponent } from '../../shared/components/claim-success-mobile/claim-success-mobile.component';
import { PlanSuccessMobileComponent } from '../../shared/components/plan-success-mobile/plan-success-mobile.component';

@Injectable()
export class ConsumerMobileMainHub {
  constructor(
    private modalDialogService: ModalDialogService,
    public readonly baseHub: BaseHub,
  ) {
    baseHub.setConfig('claims', [
      THubMethods.ProductsCreated,
      THubMethods.PlanCreated,
      THubMethods.PlanRegistered,
      THubMethods.PurchaseRegistered,
      THubMethods.DocsUploaded,
      THubMethods.ClaimStatusChange,
      THubMethods.MyDocsUploaded,
    ]);
    this.subscribeToUpdates();
  }

  startSignalR(): void {
    this.baseHub.startSignalR();
  }

  subscribeToUpdates(): void {
    this.baseHub.eventBus$.subscribe((event: HubEvent) => {
      switch (event.name) {
        case THubMethods.ProductsCreated:
          if (event.data.claim) {
            this.openClaimSuccessDialog(event.data.claim);
          }
          break;
        case THubMethods.PlanRegistered:
          if (event.data.planInfo) {
            this.openPlanSuccessDialog(event.data.planInfo);
          }
          break;
        // case THubMethods.PurchaseRegistered:
        //   if (event.data.planInfo) {
        //     this.openPurchaseSuccessDialog(event.data);
        //   }
        //   break;
      }
    });
  }

  openClaimSuccessDialog(claim: Claim): void {
    this.modalDialogService.open(
      {
        title: 'Claim success',
        data: {
          claim,
        },
      },
      ClaimSuccessMobileComponent,
      {
        disableClose: true,
        width: 'auto',
      },
    );
  }

  openPlanSuccessDialog(data: PlanDetails): void {
    this.modalDialogService.open(
      {
        title: 'Plan success',
        data,
      },
      PlanSuccessMobileComponent,
      {
        disableClose: true,
        width: 'auto',
      },
    );
  }

  // openPurchaseSuccessDialog(data: any): void {
  //   this.modalDialogService.open(
  //     {
  //       title: 'Register purchase success',
  //       data: data.planInfo,
  //     },
  //     RegisterPurchaseSuccessComponent,
  //     {
  //       disableClose: true,
  //       width: 'auto',
  //     },
  //   );
  // }
}
