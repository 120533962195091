import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { WizardPortalService } from '@consumer-core/services/wizard-portal.service';
import { RegisterPlanStore } from '@consumer-core/stores/register-plan/register-plan.store';
import { PushPipe } from '@rx-angular/template/push';

import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';

import { FormInputNewComponent } from '../../../../../shared/components/form-controls/form-input-new/form-input-new.component';
import { SubmitBtnDirective } from '../../../../../shared/directives/submit-btn.directive';
import { HowToFindPlanDialogComponent } from '../../components/how-to-find-plan-dialog/how-to-find-plan-dialog.component';

@Component({
  selector: 'app-plan-lookup-mobile',
  standalone: true,
  imports: [
    CommonModule,
    FormInputNewComponent,
    FormsModule,
    MatButtonModule,
    LoadingButtonDirective,
    PushPipe,
    PortalModule,
    SubmitBtnDirective,
  ],
  templateUrl: './plan-lookup-mobile.component.html',
  styleUrls: ['./plan-lookup-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanLookupMobileComponent {
  planNumber = '';
  lookupInProgress$ = this.store.select$('lookupInProgress');

  @ViewChild(CdkPortal) set actionsPortal(actionsPortal: CdkPortal) {
    // this._footerPortal = footerPortal;
    this.wizardPortalService.setActionsPortal(actionsPortal);
  }

  constructor(
    private readonly store: RegisterPlanStore,
    private readonly wizardPortalService: WizardPortalService,
    private readonly modalDialogService: ModalDialogService,
  ) {}

  lookUpPlan($event: MouseEvent, isInvalid: boolean): void {
    if (isInvalid) {
      $event.preventDefault();
      return;
    }
    this.store.lookUpPlan(this.planNumber);
  }

  openHowToFindPlan(): void {
    this.modalDialogService.open(
      {},
      HowToFindPlanDialogComponent,
      {
        enterAnimationDuration: 0,
        exitAnimationDuration: 0,
        hasBackdrop: false,
      },
    );
  }
}
