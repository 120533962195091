import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { filter, switchMap } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { Files } from '@core/interfaces/claims/files.interface';

import { RegisterFppClaimStore } from '../../../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import {
  SectionHeaderWithActionComponent,
} from '../../../../../shared/components/section-header-with-action/section-header-with-action.component';
import {
  FilesListMobileComponent,
} from '../../../../shell-mobile/pages/claim-details/containers/files-list-mobile/files-list-mobile.component';
import {
  BaseWizardPageComponent,
} from '../../../register-plan-consumer-mobile/components/base-wizard-page/base-wizard-page.component';
import { FilesPromptComponent } from '../files-prompt/files-prompt.component';

interface IClaimProductFilesPromptState {
  activeProductIndex: number;
  productFilesForm: FormArray;
  files: Files[];
}

@Component({
  selector: 'app-product-files-prompt',
  standalone: true,
  imports: [CommonModule, FilesListMobileComponent, FilesPromptComponent, LetDirective, SectionHeaderWithActionComponent],
  templateUrl: './product-files-prompt.component.html',
  styleUrls: ['./product-files-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFilesPromptComponent extends BaseWizardPageComponent implements OnInit {
  @Input() sectionNumber: number;
  @Input() showFilesList: boolean;
  @Input() heroTitle: string;
  @Input() heroImage: string;
  @Input() messageBody: string;
  @Input() warningMessage: string;
  @Input() allowSkip = false;
  @Input() sectionTitle = '';
  @Input() multiple = false;
  @Input() mainActionLabel = 'Add Photo';

  @Output() goToNextStep = new EventEmitter<void>();

  vm$ = this.state.select();

  constructor(
    private readonly store: RegisterFppClaimStore,
    private readonly state: RxState<IClaimProductFilesPromptState>,
  ) {
    super();
    this.state.set({
      files: [],
    });
    this.state.connect('files', this.state.select('productFilesForm').pipe(
      filter((form) => !!form),
      switchMap((form) => form.valueChanges.pipe(
        startWith(form.value),
      )),
    ));
  }

  ngOnInit(): void {
    this.state.connect(this.store.select$('activeProductIndex'), (oldState, activeProductIndex) =>  {
      const productFilesForm = this.store.getProductFilesSectionForm(activeProductIndex, this.sectionNumber);
      return {
        ...oldState,
        productFilesForm,
        activeProductIndex,
      };
    });
  }

  handleInputFiles($event: Event): void {
    const activeProductIndex = this.state.get('activeProductIndex');
    this.store.handleProductFilesInput($event, activeProductIndex, this.sectionNumber);
  }

  removeFile(fileIndex: number): void {
    const activeProductIndex = this.state.get('activeProductIndex');
    this.store.removeProductFile(fileIndex, activeProductIndex, this.sectionNumber);
  }
}
