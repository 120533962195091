import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { PushPipe } from '@rx-angular/template/push';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { Identity } from '@core/interfaces/util/identity.interface';
import {
  FormSelectNewComponent,
} from '@shared/form/form-select-new/form-select-new.component';
import { CauseStore } from '@shared/modules/claim-controls/components/cause-select/store/cause.store';

@Component({
  selector: 'app-cause-select-new',
  standalone: true,
  templateUrl: './cause-select-new.component.html',
  styleUrls: ['./cause-select-new.component.scss'],
  providers: [CauseStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormSelectNewComponent,
    PushPipe,
    ReactiveFormsModule,
  ],
})
export class CauseSelectNewComponent extends BaseFormControlElement implements OnInit {
  @Input() required = false;
  @Input() label = 'Cause';

  @Output() selectedItem = new EventEmitter<Identity>();

  causes$ = this.store.select$('items');
  loading$ = this.store.select$('loading');

  constructor(@Optional() @Self() public ngControl: NgControl, private readonly store: CauseStore) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.loadData();
  }

  itemSelected(causeType: Identity): void {
    this.selectedItem.emit(causeType);
  }
}
