import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { WizardPortalService } from '@consumer-core/services/wizard-portal.service';

import { SwitchStandaloneComponent } from '@shared/standalone/switch-standalone/switch-standalone.component';

import { WizardStore } from '../../../../core/stores/wizard.store';
import { SubmitBtnDirective } from '../../../directives/submit-btn.directive';
import { AddressFormMobileComponent } from '../../address-form-mobile/address-form-mobile.component';
import { WIZARD_HOME_ADDRESS_NEXT_STEP } from './wizard-home-address.token';

@Component({
  selector: 'app-wizard-home-address-mobile',
  standalone: true,
  imports: [
    CommonModule,
    AddressFormMobileComponent,
    MatButtonModule,
    PortalModule,
    SwitchStandaloneComponent,
    ReactiveFormsModule,
    MatSlideToggleModule,
    SubmitBtnDirective,
  ],
  templateUrl: './wizard-home-address-mobile.component.html',
  styleUrls: ['./wizard-home-address-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardHomeAddressMobileComponent {
  consumerAddressForm = this.store.form.get('consumer.consumerAddress') as FormGroup;
  serviceAddressForm = this.store.form.get('consumer.serviceAddress') as FormGroup;
  isServiceAddressSameControl = this.store.form.get('isServiceAddressSame') as FormControl;
  nextStep = inject(WIZARD_HOME_ADDRESS_NEXT_STEP);

  @ViewChild(CdkPortal) set actionsPortal(actionsPortal: CdkPortal) {
    this.wizardPortalService.setActionsPortal(actionsPortal);
  }

  constructor(
    private readonly store: WizardStore,
    private readonly wizardPortalService: WizardPortalService,
  ) {}

  goToNextStep(isInvalid: boolean): void {
    if (isInvalid) {
      this.consumerAddressForm.markAllAsTouched();
      this.serviceAddressForm.markAllAsTouched();
      return;
    }

    if (this.isServiceAddressSameControl.value) {
      this.store.form.get('consumer.serviceAddress').patchValue(this.store.form.get('consumer.consumerAddress').value);
    }
    this.store.wizardNavigationStore.setStep(this.nextStep);
  }
}
