import {
  Directive,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[appSubmitBtn]',
  standalone: true,
})
export class SubmitBtnDirective {
  @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
    event.preventDefault();
    (event.target as HTMLButtonElement).focus();
  }
}
