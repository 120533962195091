import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Output, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { RxIf } from '@rx-angular/template/if';
import { Subject } from 'rxjs';

import { TPage } from '@core/enums/page.enum';

@Component({
  selector: 'app-section-header-with-action',
  standalone: true,
  imports: [CommonModule, RxIf, RouterLink, MatButtonModule],
  templateUrl: './section-header-with-action.component.html',
  styleUrls: ['./section-header-with-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionHeaderWithActionComponent {
  @Input() sectionTitle: string;
  @Input() label: string;
  @Input() routerLink: Array<string | TPage> = null;
  @Input() actionsTemplate: TemplateRef<any>;

  @Output() action$ = new Subject<void>();
}
