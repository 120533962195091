<app-form-select-new
  *ngIf="(disabled$ | async) === false"
  [formControl]="formControl"
  [data]="problemTypeSubCategories$ | push"
  [disabled]="disabled$ | async"
  [loading]="loading$ | push"
  [pagination]="pagination$ | push"
  [label]="label"
  [required]="(disabled$ | async) === false"
  [showSearch]="(disabled$ | async) === false"
  [loadOnScroll]="true"
  (selectSearch)="problemTypeCategorySearch($event)"
  (itemSelected)="problemTypeSubCategorySelected($event)"
  (selectLoadNextPage)="loadNextPage($event)"></app-form-select-new>
