import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DestroyableComponent } from '@core/classes/destroyable-component';
import { BaseProductControlsEnum } from '@core/utils/register-claim';
import { TCommonFieldSize } from '@shared/components/form-field/common-field-size.enum';

@Component({
  selector: 'app-consumer-product-form',
  templateUrl: './consumer-product-form.component.html',
  styleUrls: ['./consumer-product-form.component.scss'],
})
export class ConsumerProductFormComponent extends DestroyableComponent {
  @Input() form: UntypedFormGroup;
  @Input() readonly = false;
  @Output() productNameChanged = new EventEmitter<string>();
  protected readonly TCommonFieldSize = TCommonFieldSize;
  protected readonly FieldsNames = BaseProductControlsEnum;
  protected readonly today = new Date();

  constructor() {
    super();
  }
}
