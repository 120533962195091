import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { BaseFormControlElement } from '../../../../../core/base/base-form-control-element';
import { LookupService } from '../../../../../core/services/lookup.service';

@Component({
  selector: 'app-surface-type-select',
  templateUrl: './surface-type-select.component.html',
  styleUrls: ['./surface-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurfaceTypeSelectComponent extends BaseFormControlElement {
  @Input() readonly: boolean;
  @Input() required: boolean;

  itemsList$ = this.lookupService.getLookup('surfacetype');

  constructor(@Optional() @Self() public ngControl: NgControl, private readonly lookupService: LookupService) {
    super(ngControl);
  }
}
