import { Routes } from '@angular/router';

import { TPage } from '@core/enums/page.enum';
import { AuthGuard } from '@core/guards/auth.guard';
import { HasTokenParamGuard } from '@core/guards/has-token-param.guard';
import { LoginAsConsumerParamsGuard } from '@core/guards/login-as-consumer-params.guard';
import { LoginAsTenantParamsGuard } from '@core/guards/login-as-tenant-params.guard';
import { NonAuthGuard } from '@core/guards/nonauth.guard';
import { SignalRResolver } from '@core/resolvers/signalr.resolver';
import { tenantThemeResolver } from '@core/resolvers/tenant-theme.resolver';
import { TokenRefreshResolver } from '@core/resolvers/token-refresh.resolver';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { RedirectComponent } from '@shared/components/redirect/redirect.component';

import { ConsumerFilesPublicThemeResolver } from './core/resolvers/consumer-files-public-theme.resolver';
import { ConsumerThemeResolver } from './core/resolvers/consumer-theme.resolver';

export const CONSUMER_APP_ROUTES: Routes = [
  { path: '', pathMatch: 'full', component: RedirectComponent },
  {
    path: 'app',
    loadChildren: () => import('./pages/shell/shell.module').then(m => m.CONSUMER_SHELL_ROUTES),
    resolve: { tokenRefreshTimeout: TokenRefreshResolver, signalR: SignalRResolver },
    canMatch: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('../../th-common/app/route-modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'log-out',
    loadChildren: () => import('../../th-common/app/route-modules/log-out/log-out.module').then(m => m.LogOutModule),
  },
  {
    path: 'login-as-tenant',
    loadChildren: () =>
      import('../../th-common/app/route-modules/login-as-tenant/login-as-tenant.module').then(
        m => m.LoginAsTenantModule,
      ),
    canActivate: [LoginAsTenantParamsGuard],
  },
  {
    path: 'log-out-as-tenant',
    loadChildren: () =>
      import('../../th-common/app/route-modules/log-out-as-tenant/log-out-as-tenant.module').then(
        m => m.LogOutAsTenantModule,
      ),
  },
  {
    path: 'login-as-consumer',
    loadChildren: () => import('../../th-common/app/shared/modules/login-as-consumer/login-as-consumer.module').then(m => m.LoginAsConsumerModule),
    canMatch: [AuthGuard],
    canActivate: [LoginAsConsumerParamsGuard],
  },
  {
    path: `consumer/${TPage.ClaimFiles}`,
    canActivate: [HasTokenParamGuard],
    resolve: { theme: ConsumerThemeResolver },
    loadChildren: () =>
      import('./pages/consumer-claim-documents/consumer-claim-documents.module').then(
        m => m.ConsumerClaimDocumentsModule,
      ),
  },
  {
    path: `consumer/${TPage.ClaimFilesUpload}`,
    canActivate: [HasTokenParamGuard],
    resolve: { claimTenantInfo: ConsumerFilesPublicThemeResolver },
    loadChildren: () =>
      import('./pages/consumer-files-public/consumer-files-public.module').then(
        m => m.ConsumerFilesPublicModule,
      ),
  },
  {
    path: ':tenant',
    resolve: { theme: tenantThemeResolver },
    loadChildren: () => import('../../th-common/app/route-modules/public/public.module').then(m => m.PublicModule),
    canMatch: [NonAuthGuard],
  },
  { path: '**', component: NotFoundComponent },
];
