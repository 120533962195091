import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { CauseStore } from '@shared/modules/claim-controls/components/cause-select/store/cause.store';

import { BaseFormControlElement } from '../../../../../core/base/base-form-control-element';

@Component({
  selector: 'app-cause-select',
  templateUrl: './cause-select.component.html',
  styleUrls: ['./cause-select.component.scss'],
  providers: [CauseStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CauseSelectComponent extends BaseFormControlElement implements OnInit {
  @Input() required = false;
  @Input() label = 'Cause';

  causes$ = this.store.select$('items');
  loading$ = this.store.select$('loading');

  constructor(@Optional() @Self() public ngControl: NgControl, private readonly store: CauseStore) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();
    this.store.loadData();
  }
}
