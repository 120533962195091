<ng-container *rxLet="vm$; let vm">
  <app-files-prompt [filesForm]="receiptFilesForm"
                    [multiple]="true"
                    [showFilesList]="vm.addedFiles.length > 0 || vm.foundFiles.length > 0"
                    heroTitle="Looks like we don’t have a receipt on file..."
                    heroImage="assets/consumer-mobile/receipt-upload.svg"
                    messageTitle="Snap a Photo of Your Receipt"
                    messageBody="In order to process your claim, we will need a photo copy of your original purchase receipt. If you have a multipage receipt, please take individual photos for each page."
                    mainActionLabel="Add Receipt"
                    (handleInputFiles)="handleInputFiles($event)"
                    (goToNextStep)="goToEntireProductFileUpload()"
                    (actionsPortal)="setActionsPortal($event)">
    <div *ngIf="vm.addedFiles.length > 0">
      <app-section-header-with-action class="fpp-claim-files__section"
                                      sectionTitle="Added Receipts"></app-section-header-with-action>
      <app-files-list-mobile [files]="vm.addedFiles"
                             [showDelete]="true"
                             (removeFile)="removeFile($event)"></app-files-list-mobile>
    </div>
    <div *ngIf="vm.foundFiles.length > 0">
      <app-section-header-with-action class="fpp-claim-files__section"
                                      sectionTitle="Receipts"></app-section-header-with-action>
      <app-files-list-mobile [files]="vm.foundFiles"></app-files-list-mobile>
    </div>
  </app-files-prompt>
</ng-container>
