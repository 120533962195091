import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { LetDirective } from '@rx-angular/template/let';

import { AppConstants } from '@core/constants/app.constants';
import { FilesTotalSizeErrorComponent } from '@shared/standalone/files-total-size-error/files-total-size-error.component';

import { HeroBannerComponent } from '../../../../../shared/components/hero-banner/hero-banner.component';
import {
  FilesListMobileComponent,
} from '../../../../shell-mobile/pages/claim-details/containers/files-list-mobile/files-list-mobile.component';

@Component({
  selector: 'app-files-prompt',
  standalone: true,
  imports: [
    CommonModule,
    FilesListMobileComponent,
    FilesTotalSizeErrorComponent,
    HeroBannerComponent,
    LetDirective,
    MatButtonModule,
    PortalModule,
  ],
  templateUrl: './files-prompt.component.html',
  styleUrls: ['./files-prompt.component.scss'],
})
export class FilesPromptComponent {
  @Input() filesForm: FormArray;
  @Input() showFilesList: boolean;
  @Input() heroTitle: string;
  @Input() heroImage: string;
  @Input() messageTitle = '';
  @Input() messageBody: string;
  @Input() warningMessage: string;
  @Input() allowSkip = false;
  @Input() multiple = false;
  @Input() mainActionLabel = 'Add Photo';

  @Output() goToNextStep = new EventEmitter<void>();
  @Output() handleInputFiles = new EventEmitter<Event>();
  @Output() actionsPortal = new EventEmitter<CdkPortal>();

  @ViewChild(CdkPortal) set setActionsPortal(actionsPortal: CdkPortal) {
    this.actionsPortal.emit(actionsPortal);
  }

  accept = [AppConstants.fileTypes.images, AppConstants.fileTypes.documents].flat().join(',');
}
