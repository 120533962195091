<div [formGroup]="form">
  <div>
    <app-form-input-new [autocomplete]="'section-address consumer address-line1'"
                        [formControl]="form.get('streetAddress1')"
                        [label]="'Address'"
                        [required]="true"></app-form-input-new>
  </div>
  <div>
    <app-form-input-new [autocomplete]="'section-address consumer address-line2'"
                        [formControl]="form.get('streetAddress2')"
                        [label]="'Address 2'"
                        [required]="false"></app-form-input-new>
  </div>
  <div>
    <app-form-input-new [autocomplete]="'section-address consumer address-level2'"
                        [formControl]="form.get('city')"
                        [label]="'City'"
                        [required]="true"></app-form-input-new>
  </div>
  <div>
    <app-select-state-new [formControl]="form.get('stateProvince')"
                          [showOutOfScopeValue]="true"
                          [label]="'State'"
                          [required]="true"></app-select-state-new>
  </div>
  <div>
    <app-form-input-new [autocomplete]="'section-address consumer postal-code'"
                        [formControl]="form.get('postalCode')"
                        [label]="'Zip'"
                        [required]="true"></app-form-input-new>
  </div>
</div>
