<div class="dialog dialog--short">
  <app-base-header
    headerTitle="Purchase Located"
    (closeClick)="close()"></app-base-header>
  <div class="dialog__content">
    <p>
      Purchase
      <strong>{{ data.data.planInfo.consumerPlanName }}</strong>
      has been successfully located.
    </p>
    <p class="plan-register-success__description">
      To start a new claim related to this purchase, click the Start New Claim button below.
    </p>
    <div class="inline-buttons inline-buttons--right">
      <button
        (click)="registerClaim()"
        class="inline-buttons__button _common-button--rounded"
        mat-flat-button
        color="primary">
        Start New Claim
      </button>
    </div>
  </div>
</div>
