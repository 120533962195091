<app-bottom-popup-dialog-animation>
  <div class="how-to-find-plan">
    <div class="how-to-find-plan__header">
      <mat-icon (click)="close()">expand_more</mat-icon>
    </div>
    <ng-container *rxLet="activePlanExampleIndex$; let activePlanExampleIndex">
      <p class="how-to-find-plan__title">{{planExamples[activePlanExampleIndex].title}}</p>
      <img class="how-to-find-plan__image" [src]="planExamples[activePlanExampleIndex].imageSrc" alt="">
      <div class="how-to-find-plan__footer">
        <button class="how-to-find-plan__button"
                [disabled]="activePlanExampleIndex === 0"
                mat-button
                color="primary"
                (click)="previousPlanExample()">Back</button>
        <app-pagination-indicator [size]="planExamples.length"
                                  [activeIndex]="activePlanExampleIndex"></app-pagination-indicator>
        <button class="how-to-find-plan__button"
                [disabled]="activePlanExampleIndex === planExamples.length - 1"
                mat-button color="primary"
                (click)="nextPlanExample()">Next</button>
      </div>
    </ng-container>
  </div>
</app-bottom-popup-dialog-animation>
