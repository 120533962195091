import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HasTokenParamGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.queryParams.token) {
      return true;
    } else {
      return this.router.createUrlTree(['/']);
    }
  }
}

export const hasTokenParamGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  if (route.queryParams.token) {
    return true;
  } else {
    return router.createUrlTree(['/']);
  }
};
