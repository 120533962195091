import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RxFor } from '@rx-angular/template/for';

import { AppConstants, TIFF_FILE_PLACEHOLDER } from '@core/constants/app.constants';
import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { Files } from '@core/interfaces/claims/files.interface';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { ImageErrorStandaloneDirective } from '@shared/directives/image-error-standalone.directive';

import {
  PhotoDialogMobileComponent,
} from '../../../../../../shared/components/photo-dialog-mobile/photo-dialog-mobile.component';

@Component({
  selector: 'app-files-list-mobile',
  standalone: true,
  imports: [
    CommonModule,
    ImageErrorStandaloneDirective,
    RxFor,
    MatIconModule,
  ],
  templateUrl: './files-list-mobile.component.html',
  styleUrls: ['./files-list-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesListMobileComponent {
  @Input() files: Files[];
  @Input() noFilesMessage = 'No files';
  @Input() showDelete = false;

  @Output() removeFile = new EventEmitter<number>();

  constructor(
    private readonly modalDialogService: ModalDialogService,
  ) {}

  fileClick(file: Files): void {
    if (['/assets/icons/doc.svg', '/assets/icons/pdf.svg'].includes(file.url)) {
      return;
    }
    if (AppConstants.fileTypes.documents.includes(file.mimeType)) {
      window.open(file.url, '_blank');
    } else if (file.thumbnailUrl !== TIFF_FILE_PLACEHOLDER) {
      this._openPhotoDialog(file);
    }
  }

  private _openPhotoDialog(file: Files): void {
    if (file.loadFailed) {
      return;
    }

    const files = this.files.filter(f =>
      !AppConstants.fileTypes.documents.includes(f.mimeType)
      && !f.loadFailed && f.thumbnailUrl !== TIFF_FILE_PLACEHOLDER);
    if (files.length === 0) {
      return;
    }

    this.modalDialogService.open({
      title: 'File Dialog',
      data: {
        file,
        files,
      },
    }, PhotoDialogMobileComponent, {
      ...DIALOG_CONFIG_CUSTOM_ANIMATION,
      disableClose: true,
    });
  }

  removeFileFn(index: number): void {
    this.removeFile.emit(index);
  }
}
