import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { PlanDetails } from '@core/interfaces/plan/plan.interface';

import { ConsumerClaimMobileDialogService } from '../../../core/services/consumer-claim-mobile-dialog.service';

@Component({
  selector: 'app-plan-success-mobile',
  standalone: true,
  templateUrl: './plan-success-mobile.component.html',
  styleUrls: ['./plan-success-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
  ],
})
export class PlanSuccessMobileComponent {
  data: {data: PlanDetails} = inject(MAT_DIALOG_DATA);

  constructor(
    private readonly dialogRef: MatDialogRef<any>,
    private readonly consumerClaimMobileDialogService: ConsumerClaimMobileDialogService,
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  registerClaim(): void {
    // Adjust redirect
    this.close();
    this.consumerClaimMobileDialogService.openCreateFppClaimDialog(this.data.data);
  }
}
