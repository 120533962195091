import { Injectable } from '@angular/core';

import { Feature } from '@core/enums/feature.enum';
import { NavigationService } from '@core/services/navigation.service';
import { NavigationInterface } from '@shared/modules/navigation/components/navigation/navigation.component';

@Injectable()
export class ConsumerNavigationService extends NavigationService {
  getNavList(): NavigationInterface[] {
    return [
      {
        route: '/app/dashboard',
        name: 'Dashboard',
        features: [Feature.ViewDashboard],
        child: [],
        icon: 'dashboard',
      },
      {
        route: '/app/my-plans',
        name: 'My Plans',
        features: [Feature.ViewMyPlans],
        child: [],
        icon: 'plans',
      },
      {
        route: '/app/my-claims',
        name: 'My Claims',
        features: [Feature.ViewMyClaims],
        child: [],
        icon: 'claim-2',
        class: 'claim',
      },
      {
        route: '/app/admin/users',
        name: 'Admin',
        features: [Feature.ViewUsers],
        featureOptions: {
          some: true,
        },
        icon: 'admin',
        child: [
          {
            route: '/app/admin/users',
            name: 'Users',
            features: [Feature.ViewUsers],
          },
        ],
      },
      {
        route: '/app/contact-info',
        name: 'Contact Info',
        isAllow: true,
        child: [],
        icon: 'contact',
      },
      {
        route: '/app/help',
        name: 'Help',
        isAllow: true,
        icon: 'help',
        child: [],
      },
    ];
  }
}
