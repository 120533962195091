<input class="files-prompt__file-input" #filesInput type="file"
       [multiple]="multiple"
       [accept]="accept"
       (change)="handleInputFiles.emit($event)">
<ng-container *ngIf="showFilesList; else filesBannerTemplate">
  <ng-content></ng-content>
  <div *cdkPortal class="files-prompt__footer">
    <app-files-total-size-error [filesForm]="filesForm.root"></app-files-total-size-error>
    <div class="files-prompt__actions">
      <button type="button"
              (click)="filesInput.click()"
              mat-stroked-button>{{multiple ? 'Add More' : 'Retake photo'}}</button>
      <button type="button"
              (click)="goToNextStep.emit()"
              [disabled]="filesForm.root.hasError('sizeError')"
              mat-flat-button>Continue</button>
    </div>
  </div>
</ng-container>
<ng-template #filesBannerTemplate>
  <app-hero-banner [heroTitle]="heroTitle"
                   [heroImage]="heroImage"
                   [messageTitle]="messageTitle"
                   [messageBody]="messageBody"
                   [warningMessage]="warningMessage"></app-hero-banner>
  <ng-container *cdkPortal>
    <div class="files-prompt__actions" [class.files-prompt__actions_single]="!allowSkip">
      <button *ngIf="allowSkip"
              type="button"
              (click)="goToNextStep.emit()"
              mat-stroked-button>Skip</button>
      <button type="button"
              (click)="filesInput.click()"
              mat-flat-button>{{mainActionLabel}}</button>
    </div>
  </ng-container>
</ng-template>
