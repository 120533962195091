import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LetDirective } from '@rx-angular/template/let';

import { RegisterFppClaimStore } from '../../../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import { ProductFilesPromptComponent } from '../../components/product-files-prompt/product-files-prompt.component';

@Component({
  selector: 'app-fpp-claim-damage-files-upload',
  standalone: true,
  imports: [CommonModule, LetDirective, ProductFilesPromptComponent],
  templateUrl: './fpp-claim-damage-files-upload.component.html',
  styleUrls: ['./fpp-claim-damage-files-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FppClaimDamageFilesUploadComponent {

  constructor(
    private readonly store: RegisterFppClaimStore,
  ) {}

  goToCloseupFileUpload(): void {
    this.store.wizardNavigationStore.setStep('closeupFile');
  }
}
