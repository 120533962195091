import { Portal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { RxState } from '@rx-angular/state';
import { RxIf } from '@rx-angular/template/if';
import { LetDirective } from '@rx-angular/template/let';
import { buildVariables } from '@th-common/assets/config/build-variables';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { SystemApiService } from '@core/api/system-api.service';
import { APP_MOBILE_DETECT } from '@core/constants/app-config.constants';
import { ApplicationInsightsService } from '@core/services/application-insights/application-insights.service';
import { AuthService } from '@core/services/auth.service';
import { SystemService } from '@core/services/system.service';
import { ThemingService } from '@core/services/theming.service';
import { UserStore } from '@core/store/user/user.store';
import { ApplicationLoaderComponent } from '@shared/application-loader/application-loader.component';

import { ConsumerMobilePortalService } from './core/services/consumer-mobile-portal.service';
import { EnhancementsStore } from './core/stores/enhancements/enhancements.store';
import { Five9ChatStore } from './core/stores/five9-chat/five9-chat.store';
import { HeaderMobileComponent } from './shared/components/header-mobile/header-mobile.component';
import {
  MobileNotificationSnackBarComponent,
} from './shared/components/mobile-notification-snack-bar/mobile-notification-snack-bar.component';
import { MobileScrollableContentDirective } from './shared/directives/mobile-scrollable-content.directive';

interface IRootState {
  currentRoute: ActivatedRoute;
  backNavRoute: string[];
  showBackNavigation: boolean;
  showFooter: boolean;
  showCloseDialog: boolean;
  showProfile: boolean;
  routeTitle: string;
  userLogoText: string;
  newNotificationsNumber: number;
  footerPortal: Portal<any>;
  showFive9Chat: boolean;
}

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app-mobile.component.html',
  styleUrls: ['./app-mobile.component.scss'],
  imports: [
    CommonModule,
    RouterOutlet,
    MatIconModule,
    MatButtonModule,
    RxIf,
    MobileScrollableContentDirective,
    ApplicationLoaderComponent,
    RouterLink,
    RouterLinkActive,
    PortalModule,
    LetDirective,
    MatMenuModule,
    HeaderMobileComponent,
    MobileNotificationSnackBarComponent,
  ],
  providers: [RxState, EnhancementsStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppMobileComponent implements OnInit {
  vm$ = this.state.select();
  applicationInsightsService = inject(ApplicationInsightsService);
  appMobileDetect = inject(APP_MOBILE_DETECT);

  constructor(
    private readonly state: RxState<IRootState>,
    private themingService: ThemingService,
    private systemApiService: SystemApiService,
    private systemService: SystemService,
    private authService: AuthService,
    private router: Router,
    private consumerMobilePortalService: ConsumerMobilePortalService,
    private userStore: UserStore,
    private enhancementsStore: EnhancementsStore,
    private readonly five9ChatStore: Five9ChatStore,
  ) {
    this.applicationInsightsService.logEvent('isMobileDevice', this.appMobileDetect);
    this.state.connect('showFive9Chat', this.five9ChatStore.select$('showAboveFooter'));
    this.state.connect(
      'currentRoute',
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => {
          let route: ActivatedRoute = this.router.routerState.root;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => !route.data || !route.snapshot.data.isDialog),
      ),
    );
    this.state.connect(this.state.select('currentRoute', 'snapshot'), (oldState: IRootState, snapshot: ActivatedRouteSnapshot) => {
      const { hideBackNav, ...snapshotData } = snapshot.data;
      return {
        ...oldState,
        backNavRoute: snapshotData.backNavRoute,
        showBackNavigation: !hideBackNav,
        showFooter: snapshotData.showFooter,
        showCloseDialog: snapshotData.showCloseDialog,
        showProfile: snapshotData.showProfile,
        routeTitle: snapshot.title || '',
      };
    });
    this.state.connect('userLogoText', this.userStore.select$('session').pipe(
      filter(user => !!user),
      map(user => `${user.userFirstName.charAt(0)}${user.userLastName.charAt(0)}`),
    ));
    this.state.connect('footerPortal', this.consumerMobilePortalService.footerPortal$);
    this.state.connect('newNotificationsNumber', this.enhancementsStore.select$('enhancements').pipe(
      map(enhancements => enhancements.filter(enhancement => !enhancement.isSeen).length),
    ));
  }

  ngOnInit(): void {
    this.themingService.themeSubject$.subscribe(theme => {
      const root = document.documentElement;
      this.themingService.setThemeVariables(theme, root);
    });

    window.addEventListener('dragover', e => e && e.preventDefault(), false);
    window.addEventListener('drop', e => e && e.preventDefault(), false);

    const appRoot = document.querySelector('#app-root');
    if (appRoot) {
      appRoot.setAttribute('build-version', buildVariables.buildNumber);
    }

    this.authService.tokenResolverChecked$
      .pipe(
        withLatestFrom(this.authService.authorized$),
        filter(([tokenResolverChecked, authorized]) => (authorized ? tokenResolverChecked : true)),
        first(),
        switchMap(() => this.systemApiService.buildNumber()),
      )
      .subscribe(buildNumber => {
        this.systemService.buildNumber$.next(buildNumber);
        if (appRoot) {
          appRoot.setAttribute('server-version', buildNumber);
        }
      });
  }

  backNav(): void {
    const backNavRoute = this.state.get('backNavRoute');
    const currentRoute = this.state.get('currentRoute');
    this.router.navigate(backNavRoute || ['../'], {
      relativeTo: currentRoute,
    });
  }

  closeDialog(): void {
    const currentRoute = this.state.get('currentRoute');
    this.router.navigate(['../'], {
      relativeTo: currentRoute,
    });
  }

  logOut(): void {
    this.authService.logout();
  }

  openFive9Chat(): void {
    this.five9ChatStore.openFive9Chat();
  }
}
