import { Injectable } from '@angular/core';

import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

import { ProtectionPlanDetailsComponent } from '../../shared/modules/consumer-plan-details/containers/protection-plan-details/protection-plan-details.component';

@Injectable()
export class ConsumerPlanDialogsService {
  constructor(private readonly modalDialogService: ModalDialogService) {}

  openMyPlanDetailsDialog(planId: number, singleView: boolean = false): void {
    this.modalDialogService.open({
      title: 'My Plan Details',
      data: {
        planId,
        singleView,
      },
    }, ProtectionPlanDetailsComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);
  }
}
