import { animate, style, transition, trigger } from '@angular/animations';
import { Component, inject,Input } from '@angular/core';
import { RxIf } from '@rx-angular/template/if';

import { DIALOG_ROUTE_CONFIG } from '@core/constants/app.constants';

@Component({
  selector: 'app-bottom-dialog-animation',
  template: `
    <div *rxIf="isVisible"
          [@slideInOut]="{value: null, params: {animationTime: dialogRouteConfig.animationTime}}"
         class="bottom-dialog-animation">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./bottom-dialog-animation.component.scss'],
  standalone: true,
  imports: [
    RxIf,
  ],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('{{animationTime}}ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        animate('{{animationTime}}ms ease-in', style({ transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class BottomDialogAnimationComponent {
  @Input() isVisible = true;
  dialogRouteConfig = inject(DIALOG_ROUTE_CONFIG);
}
