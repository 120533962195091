import { Injectable } from '@angular/core';

import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

import {
  RegisterConsumerFppClaimMobileComponent,
} from '../../pages/dialogs/register-consumer-fpp-claim-mobile/register-consumer-fpp-claim-mobile.component';

@Injectable({
  providedIn: 'root',
})
export class ConsumerClaimMobileDialogService {

  constructor(
    private modalDialogService: ModalDialogService,
  ) {}

  openCreateFppClaimDialog(planDetails: PlanDetails = null): void {
    this.modalDialogService.open({
      title: 'Create FPP Claim',
      data: planDetails,
    }, RegisterConsumerFppClaimMobileComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);
  }
}
