<div class="plan-card">
  <app-label-value label="Receipt Number:">
    <p>{{planDetails.receiptNumber | appEmptyValue}}</p>
  </app-label-value>
  <app-label-value label="Plan Number:">
    <p>{{planDetails.consumerPlanName | appEmptyValue}}</p>
  </app-label-value>
  <app-label-value class="plan-card__description" label="Plan Description:">
    <p>{{planDetails.parentPlanName | appEmptyValue}}</p>
  </app-label-value>
  <app-label-value label="Purchase Date:">
    <p>{{planDetails.purchaseDate | dateCustomFormat | appEmptyValue}}</p>
  </app-label-value>
  <app-label-value label="Delivery Date:">
    <p>{{planDetails.deliveryDate | dateCustomFormat | appEmptyValue}}</p>
  </app-label-value>
</div>
