<ng-container *rxLet="vm$; let vm">
  <ng-container *rxIf="vm.totalItems === 0; else itemCard">
    {{noResultsText}}
  </ng-container>
  <ng-template #itemCard>
    <ng-container *ngTemplateOutlet="cardTemplate; context: {$implicit: vm.activeItem}"></ng-container>
    <div *rxIf="vm.showPagination" class="claim-incidents-expandable-card__pagination">
      <button mat-icon-button
              class="claim-incidents-expandable-card__nav-btn"
              [class.claim-incidents-expandable-card__nav-btn_hide]="!vm.showPrev"
              (click)="previousIncident()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <span>{{vm.activeItemNumber}} of {{vm.totalItems}}</span>
      <button mat-icon-button
              class="claim-incidents-expandable-card__nav-btn"
              [class.claim-incidents-expandable-card__nav-btn_hide]="!vm.showNext"
              (click)="nextIncident()">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </ng-template>
</ng-container>
