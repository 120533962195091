<div [formGroup]="contactForm">
  <p class="wizard-contact-info__title">Contact Info</p>
  <div class="wizard-contact-info__names"
       [class.wizard-contact-info__names_readonly]="contactForm.get('firstName').disabled">
    <app-form-input-new [autocomplete]="'section-consumer given-name'"
                        formControlName="firstName"
                        [readonly]="contactForm.get('firstName').disabled"
                        [required]="true"
                        [label]="'First Name'"></app-form-input-new>
    <app-form-input-new [autocomplete]="'section-consumer family-name'"
                        formControlName="lastName"
                        [readonly]="contactForm.get('lastName').disabled"
                        [required]="true"
                        [label]="'Last Name'"></app-form-input-new>
  </div>
  <app-email-radio-group></app-email-radio-group>
  <ng-container *ngIf="showContactPreferences">
    <ng-container *rxLet="vm$; let vm">
      <div class="wizard-contact-info__section" (click)="toggleContactPreferences()">
        <p class="wizard-contact-info__title wizard-contact-info__title_sub">Contact Preferences <span class="wizard-contact-info__optional-text">(optional)</span></p>
        <mat-icon [class.wizard-contact-info__expand-icon_expanded]="!vm.contactPreferencesCollapsed">expand_more</mat-icon>
      </div>
      <div class="wizard-contact-info__preferences"
           [class.wizard-contact-info__preferences_collapsed]="vm.contactPreferencesCollapsed">
        <app-language-select-new [formControlName]="'preferredContactLanguage'"></app-language-select-new>
        <app-contact-method-select-new [formControlName]="'preferredContactMethod'"></app-contact-method-select-new>
        <app-contact-time-select-new [formControlName]="'preferredContactTime'"></app-contact-time-select-new>
      </div>
    </ng-container>
  </ng-container>
  <div class="wizard-contact-info__section">
    <p class="wizard-contact-info__title wizard-contact-info__title_sub">Phones</p>
  </div>
  <p class="help-text help-text--required">There must be at least one phone number provided.</p>
  <app-form-input-new [autocomplete]="'section-consumer tel'"
                      [formControl]="contactForm.get('mobilePhone')"
                      [label]="'Mobile Phone #'"></app-form-input-new>
  <app-form-input-new [autocomplete]="'section-consumer tel'"
                      [formControl]="contactForm.get('homePhone')"
                      [label]="'Home Phone #'"></app-form-input-new>
  <app-form-input-new [autocomplete]="'section-consumer tel'"
                      [formControl]="contactForm.get('workPhone')"
                      [label]="'Business Phone #'"></app-form-input-new>
  <ng-container *cdkPortal>
    <button [class._common-button_submit-disabled]="contactForm.invalid"
            mat-flat-button
            appSubmitBtn
            (click)="goToAddress(contactForm.invalid)">Continue</button>
  </ng-container>
</div>
