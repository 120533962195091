<mat-form-field
  class="select"
  *rxLet="vm$; let vm"
  [class.disabled]="disabled"
  [class._common-mat-form-field_hide-errors]="!formFieldProvider.showErrors"
  [class._common-theme-field]="!!formControl.value">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select
    #matSelectInstance
    [formControl]="formControl"
    [multiple]="multiple"
    [disabled]="disabled"
    [disableOptionCentering]="true"
    (selectionChange)="itemSelect($event)"
    (openedChange)="openedHandler($event)"
    [required]="required"
    [panelClass]="{
      'select--loading': loading,
      'select--custom-search': showSearch,
      'select--search': showSearch && vm.dataLength > 5
    }"
    appMat3ScrollLoadingOverlay
    appMat3SelectInfiniteScroll
    [loading]="loading"
    [disableInfinite]="!loadOnScroll"
    (scrolled)="onScrollDown()">
    <mat-option
      *ngIf="showSearch"
      class="custom-option-search"
      [disabled]="true">
      <input
        data-lpignore="true"
        #searchInput
        autofocus
        (keydown)="$event.stopPropagation()"
        class="select__search-input"
        name="search"
        matInput
        [trim]="'blur'"
        type="text"
        autocomplete="off"
        (ngModelChange)="modelChanged($event)"
        [(ngModel)]="searchString"
        [ngModelOptions]="{ standalone: true }"
        (keydown.enter)="searchEvent($event)"
        placeholder="Search" />
      <mat-icon
        *ngIf="searchString.length > 0 && !loading"
        (click)="clearSearch()"
        class="select__search-reset">
        close
      </mat-icon>
      <div
        *ngIf="loading"
        class="select__loader">
        <app-loader></app-loader>
      </div>
    </mat-option>
    <mat-label
      *ngIf="optionsLabel"
      class="select__empty-option select__options-label">
      {{ optionsLabel }}
    </mat-label>
    <mat-option
      class="select__empty-option"
      [class.hidden]="vm.dataLength === 0"
      [value]="null"
      *ngIf="!required && !multiple && showEmptyOption">
      {{ emptyLabelPrefix }} {{ label }}
    </mat-option>
    <mat-option
      *ngIf="showOutOfScopeValue && vm.outOfScopeValue as outOfScopeValue"
      [value]="outOfScopeValue">
      {{ outOfScopeValue }} (out of scope)
    </mat-option>
    <mat-option
      *ngIf="
        loadOnScroll &&
        !showOutOfScopeValue &&
        vm.showSelectedOutOfListItem &&
        vm.selectedItem as selectedItem
      "
      [value]="selectedItem[valueField]">
      {{ selectedItem[titleField] }}
      <div
        *ngIf="selectedItem[subTitleField]"
        class="select__sub-text">
        {{ selectedItem[subTitleField] }}
      </div>
    </mat-option>
    <mat-option
      *rxFor="let item of vm.data"
      [value]="item[valueField]"
      [disabled]="item.disabled">
      {{ item[titleField] }}
      <div
        *ngIf="item[subTitleField]"
        class="select__sub-text">
        {{ item[subTitleField] }}
      </div>
    </mat-option>
    <div *ngIf="vm.dataLength === 0">
      <mat-option
        disabled
        [value]="'noResults'">
        No results found
      </mat-option>
    </div>
  </mat-select>
  <div
    matSuffix
    class="select__suffix">
    <div
      *ngIf="loading"
      class="select__input-loader">
      <app-loader></app-loader>
    </div>
    <mat-icon class="select__error-icon control-error-icon">error</mat-icon>
  </div>
  <mat-error>
    <mat-error>{{ formControl.errors | formControlError: { label: label } }}</mat-error>
  </mat-error>
</mat-form-field>
