import { TConsumerPlanRegistrationState } from '@core/enums/plan/consumer-plan-registration-state.enum';
import { PlanLookup } from '@core/interfaces/plan/plan.interface';

export type TRegisterPlanNavigationStepDesktop =
  'lookupPlan' |
  'consumer' |
  'uploadReceipt';
export type TRegisterPlanNavigationStepMobile = 'lookupPlan' |
'planFound' |
'confirmPlan' |
'purchaseDetails' |
'contactInfo' |
'homeAddress' |
'uploadReceipt' |
'review' |
'finish';
export type TRegisterPlanNavigationStep = TRegisterPlanNavigationStepDesktop | TRegisterPlanNavigationStepMobile;
export type TRegisterPlanLookupStep =
  'planEligible' |
  'validateEmail' |
  'validatePhone' |
  'validateAddress';


export namespace StoreState {
  export interface IStoreState {
    lookupInProgress: boolean;
    registrationType: TConsumerPlanRegistrationState;
    lookupStep: TRegisterPlanLookupStep;
    planLookup: PlanLookup;
    planNumber: string;
    consumerLoading: boolean;
    verificationOptions: Array<'email' | 'phone' | 'address'>;
    submitInProgress: boolean;
  }

  export const initialState: IStoreState = {
    lookupInProgress: false,
    registrationType: null,
    lookupStep: null,
    planLookup: null,
    planNumber: '',
    consumerLoading: false,
    verificationOptions: [],
    submitInProgress: false,
  };
}
