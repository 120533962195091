import { Claim } from '@core/interfaces/claims/claim.interface';
import { Pagination } from '@core/interfaces/ui/pagination.interface';
import { ClaimSearchRequest } from '@core/models/search/search-request.model';
import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';

export namespace StoreState {
  export interface IStoreState {
    claims: Claim[];
    searchRequest: Partial<ClaimSearchRequest>;
    pagination: Pagination;
    loading: boolean;
    saveInProgress: boolean;
    exportInProgress: boolean;
    allChecked?: boolean;
  }

  export const initialState: IStoreState = {
    claims: [],
    searchRequest: {
      orderBy: [new GridOrderByModel('dateOpen', false)],
      page: 1,
      pageSize: 10,
      searches: [],
      noCount: false,
      noRows: false,
    },
    pagination: {
      totalCount: 0,
      page: 1,
      pageCount: 1,
    },
    loading: false,
    saveInProgress: false,
    exportInProgress: false,
  };
}
