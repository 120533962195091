<app-bottom-dialog-animation [isVisible]="isVisible$ | push">
  <ng-container *rxLet="vm$; let vm">
    <form method="dialog" action ngForm class="register-plan-mobile">
      <app-header-mobile headerTitle="Find Your Plan">
        <ng-container leftControls>
          <button type="button"
                  *rxIf="vm.showBackButton" (click)="stepBack()"
                  mat-icon-button
                  [disabled]="vm.headerActionsDisabled">
            <mat-icon>chevron_left</mat-icon>
          </button>
        </ng-container>
        <ng-container rightControls>
          <button type="button"
                  *rxIf="vm.showCloseButton"
                  (click)="close()"
                  mat-icon-button
                  [disabled]="vm.headerActionsDisabled">
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>
      </app-header-mobile>
      <mat-progress-bar mode="determinate" [value]="vm.progress"></mat-progress-bar>
      <mat-dialog-content>
        <ng-container *ngComponentOutlet="vm.activeComponent"></ng-container>
      </mat-dialog-content>
      <mat-dialog-actions>
        <ng-template [cdkPortalOutlet]="vm.actionsPortal"></ng-template>
      </mat-dialog-actions>
    </form>
  </ng-container>
</app-bottom-dialog-animation>
