<mat-form-field
  class="form-textarea"
  [class._common-theme-field]="!!formControl.value"
  [class._common-mat-form-field_expand-hint]="expandHint"
  [color]="'primary'"
  [appearance]="appearance">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <div [class.tooltip__input-block]="tooltipText || tooltipAttentionText">
    <textarea
      matInput
      [trim]="useTrim"
      [class._common-tooltipText]="tooltipText || tooltipAttentionText"
      [cdkTextareaAutosize]="matTextareaAutosize"
      [required]="required"
      [cdkAutosizeMinRows]="minRows"
      [cdkAutosizeMaxRows]="maxRows"
      [maxlength]="maxLength"
      [placeholder]="placeholder"
      [formControl]="formControl"></textarea>
  </div>
  <ng-container matSuffix>
    <mat-icon class="control-error-icon">error</mat-icon>
  </ng-container>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error>{{ formControl.errors | formControlError: { label: label } }}</mat-error>
</mat-form-field>
