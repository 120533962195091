import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ConsumerPlan } from '@core/interfaces/claims/claimDetails.interface';
import { Plan } from '@core/interfaces/plan/plan.interface';
import { PipesModule } from '@shared/pipes/pipes.module';

import { LabelValueComponent } from '../../label-value/label-value.component';

@Component({
  selector: 'app-plan-details-card',
  standalone: true,
  imports: [CommonModule, LabelValueComponent, PipesModule],
  templateUrl: './plan-details-card.component.html',
  styleUrls: ['./plan-details-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanDetailsCardComponent {
  @Input() planDetails: ConsumerPlan | Plan;

}
