<div class="email-radio-group">
  <p class="email-radio-group__label">Email</p>
  <mat-radio-group [formControlName]="'emailAddress'"
                   (change)="emailChanged($event)"
                   [required]="true"
                   name="emailAddressRadio">
    <div class="email-radio-group__radio" *rxLet="vm$; let vm">
      <mat-radio-button [id]="'default'"
                        [value]="vm.emailDefault">
        Use your portal email address
        <p class="email-radio-group__email">{{vm.emailDefault}}</p>
      </mat-radio-button>
    </div>
    <div class="email-radio-group__radio">
      <mat-radio-button [id]="'custom'"
                        [value]="emailCustom$ | push">
        <div>
          <p>Use a different email address</p>
          <app-form-input-new #customEmailInput
                              [autocomplete]="'section-consumer email'"
                              [ngModelOptions]="{standalone: true}"
                              [ngModel]="emailCustom$ | push"
                              (ngModelChange)="customEmailChanged()"
                              appEmailValidation
                              type="email"
                              label="Email"
                              floatLabel="always"
                              [disabled]="activeInputDefault$ | push"
                              [required]="true"></app-form-input-new>
        </div>
      </mat-radio-button>
    </div>
  </mat-radio-group>
</div>
