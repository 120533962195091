import { ApplicationRef, Injectable } from '@angular/core';

import { Claim } from '@core/interfaces/claims/claim.interface';
import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { HubEvent, THubMethods } from '@core/models/hub-event.model';
import { BaseHub } from '@core/services/hubs/base.hub';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { ClaimSuccessComponent } from '@shared/modules/side-dialog/components/claim-success/claim-success.component';

import { PlanSuccessComponent } from '../../shared/components/plan-success/plan-success.component';
import { RegisterPurchaseSuccessComponent } from '../../shared/components/register-purchase-success/register-purchase-success.component';

@Injectable()
export class ConsumerMainHub {
  constructor(
    private modalDialogService: ModalDialogService,
    private readonly appRef: ApplicationRef,
    public readonly baseHub: BaseHub,
  ) {
    baseHub.setConfig('claims', [
      THubMethods.ProductsCreated,
      THubMethods.PlanCreated,
      THubMethods.PlanRegistered,
      THubMethods.PurchaseRegistered,
      THubMethods.DocsUploaded,
      THubMethods.ClaimStatusChange,
      THubMethods.MyDocsUploaded,
    ]);
    this.subscribeToUpdates();
  }

  startSignalR() {
    this.baseHub.startSignalR();
  }

  subscribeToUpdates() {
    this.baseHub.eventBus$.subscribe((event: HubEvent) => {
      switch (event.name) {
        case THubMethods.ProductsCreated:
          if (event.data.claim) {
            this.openClaimSuccessDialog(event.data.claim);
          }
          break;
        case THubMethods.PlanRegistered:
          if (event.data.planInfo) {
            this.openPlanSuccessDialog(event.data.planInfo);
          }
          break;
        case THubMethods.PurchaseRegistered:
          if (event.data.planInfo) {
            this.openPurchaseSuccessDialog(event.data);
          }
          break;
      }
    });
  }

  openClaimSuccessDialog(claim: Claim): void {
    this.modalDialogService.open(
      {
        title: 'Claim success',
        data: {
          claim,
        },
      },
      ClaimSuccessComponent,
      {
        disableClose: true,
        width: 'auto',
      },
    );
    this.appRef.tick();
  }

  openPlanSuccessDialog(data: PlanDetails): void {
    this.modalDialogService.open(
      {
        title: 'Plan success',
        data,
      },
      PlanSuccessComponent,
      {
        disableClose: true,
        width: 'auto',
      },
    );
    this.appRef.tick();
  }

  openPurchaseSuccessDialog(data: any): void {
    this.modalDialogService.open(
      {
        title: 'Register purchase success',
        data: data.planInfo,
      },
      RegisterPurchaseSuccessComponent,
      {
        disableClose: true,
        width: 'auto',
      },
    );
    this.appRef.tick();
  }
}
