<div
  *ngIf="isVisible"
  [@slideInOut]="{ value: null, params: { animationTime: dialogRouteConfig.animationTime } }"
  class="dialog">
  <div
    *ngIf="loading$ | async"
    class="dialog__loader">
    <app-loader class="base-loader"></app-loader>
  </div>
  <ng-container *ngIf="(initialLoading$ | async) === false">
    <div class="dialog__header">
      <div class="dialog__header-content">
        <ng-content select="[header]"></ng-content>
      </div>
      <button
        [disabled]="disableClose"
        *ngIf="displayCloseButton"
        class="dialog__close"
        (click)="close()">
        <mat-icon
          class="dialog__header-close-icon"
          svgIcon="close"></mat-icon>
      </button>
    </div>
    <div class="dialog__content">
      <ng-content select="[content]"></ng-content>
    </div>
  </ng-container>
</div>
