import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { MatRadioModule } from '@angular/material/radio';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { map } from 'rxjs/operators';

import { UserStore } from '@core/store/user/user.store';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { EmailValidationDirective } from '@shared/directives/validators/email-validation.directive';

import { FormInputNewComponent } from '../form-controls/form-input-new/form-input-new.component';

interface IEmailRadioGroupState {
  activeInput: 'default' | 'custom';
  emailDefault: string;
  emailCustom: string;
}

@Component({
  selector: 'app-email-radio-group',
  standalone: true,
  imports: [CommonModule, EmailValidationDirective, PushPipe, FormInputNewComponent, MatRadioModule, FormsModule, ReactiveFormsModule, LetDirective],
  templateUrl: './email-radio-group.component.html',
  styleUrls: ['./email-radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RxState,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class EmailRadioGroupComponent implements OnInit, AfterViewInit {
  vm$ = this.state.select();
  emailCustom$ = this.vm$.pipe(
    map((state) => state.emailCustom),
  );
  activeInputDefault$ = this.vm$.pipe(
    map((state) => state.activeInput === 'default'),
  );

  @ViewChild('customEmailInput') customEmailInput: FormInputComponent;

  constructor(
    private readonly state: RxState<IEmailRadioGroupState>,
    private readonly userStore: UserStore,
    private readonly controlContainer: ControlContainer,
  ) {
    this.state.set({
      activeInput: 'default',
    });
  }

  ngOnInit() {
    const defaultEmail = this.userStore.get('session').username;
    let consumerEmail = this.controlContainer.value.emailAddress;
    if (!consumerEmail) {
      consumerEmail = defaultEmail;
      this.controlContainer.control.patchValue({
        emailAddress: defaultEmail,
      });
    }
    const emailCustom = defaultEmail === consumerEmail ? '' : consumerEmail;
    const activeInput = defaultEmail === consumerEmail ? 'default' : 'custom';
    this.state.set({
      emailDefault: defaultEmail,
      emailCustom,
      activeInput,
    });
  }

  ngAfterViewInit() {
    const activeInput = this.state.get('activeInput');
    this.state.connect('emailCustom', this.customEmailInput.ngControl.valueChanges);
    if (activeInput === 'custom') {
      this.customEmailInput.ngControl.control.markAsTouched();
    }
  }

  emailChanged($event: MatRadioChange) {
    if ($event instanceof MatRadioChange) {
      this.state.set({
        activeInput: $event.source.id as 'default' | 'custom',
      });
      if ($event.source.id === 'default') {
        this.customEmailInput.ngControl.control.markAsUntouched();
      } else {
        this.customEmailInput.ngControl.control.markAsTouched();
      }
    }
  }

  customEmailChanged() {
    const activeInput = this.state.get('activeInput');
    if (!!this.customEmailInput && activeInput === 'custom') {
      this.controlContainer.control.patchValue({
        emailAddress: this.customEmailInput.ngControl.value,
      });
    }
  }
}
