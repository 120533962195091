import { ComponentPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class WizardPortalService {
  actionsPortal$$ = new Subject<Portal<any>>();
  actionsPortal$ = this.actionsPortal$$.asObservable();

  constructor() { }

  setActionsPortal(portal: ComponentPortal<any> | TemplatePortal<any>) {
    this.actionsPortal$$.next(portal);
  }
}
