<div class="plan-purchase-details">
  <p class="plan-purchase-details__title">Plan Purchase Details</p>
  <div class="plan-purchase-details__form"
       [class.plan-purchase-details__form_readonly]="consumerPlanForm.get('receiptNumber').disabled">
    <app-form-input-new [formControl]="consumerPlanForm.get('receiptNumber')"
                        [label]="'Receipt #'"
                        [readonly]="consumerPlanForm.get('receiptNumber').disabled"
                        [required]="true"></app-form-input-new>
    <app-form-date-new *appReadonlyControl="consumerPlanForm.get('purchaseDate').enabled; else labelValueItem; label: 'Purchase Date'; value: consumerPlanForm.get('purchaseDate').value; let label = label"
                       [formControl]="consumerPlanForm.get('purchaseDate')"
                       [touchUi]="true"
                       [label]="label"
                       [hint]="'Purchase date of Plan'"
                       [readonly]="true"
                       [required]="true"
                       [max]="today"
                       [min]="minDate"></app-form-date-new>
    <app-form-date-new *appReadonlyControl="consumerPlanForm.get('deliveryDate').enabled; else labelValueItem; label: 'Delivery Date'; value: consumerPlanForm.get('deliveryDate').value; let label = label"
                       [formControl]="consumerPlanForm.get('deliveryDate')"
                       [touchUi]="true"
                       [label]="label"
                       [hint]="'Delivery date of first piece of furniture'"
                       [readonly]="true"
                       [required]="true"
                       [min]="consumerPlanForm.get('purchaseDate').value"
                       [autocomplete]="'section-purchase delivery-date'"></app-form-date-new>
    <app-form-input-new [formControl]="consumerPlanForm.get('planPrice')"
                        [label]="'Plan Price'"
                        [readonly]="consumerPlanForm.get('planPrice').disabled"
                        [autocomplete]="'section-product purchase-price'"
                        [dataType]="'price'"
                        [hint]="'Purchase price of plan, without tax (not the furniture)'"
                        [required]="true"></app-form-input-new>
  </div>
  <ng-container *cdkPortal>
    <button [class._common-button_submit-disabled]="consumerPlanForm.invalid"
            appSubmitBtn
            mat-flat-button
            (click)="goToConsumerInfo(consumerPlanForm.invalid)">Continue</button>
  </ng-container>
</div>
<ng-template #labelValueItem let-label="label" let-value="value">
  <app-label-value [label]="label">
    <p>{{value | dateCustomFormat:'MM/DD/YYYY' | appEmptyValue}}</p>
  </app-label-value>
</ng-template>
