<div class="plan-found" *rxLet="vm$; let vm">
  <p class="plan-found__header">Is this the correct protection plan?</p>
  <img class="plan-found__image" src="assets/consumer-mobile/plan-found-mobile.svg" alt="Plan Found Image">
  <p class="plan-found__message">We found a <b>{{vm.planName}}</b> sold by <b>{{vm.retailerName}}</b>. Is this the correct protection plan?</p>
  <div *cdkPortal class="plan-found__actions">
    <button type="button" (click)="backToLookup()" mat-stroked-button>Try Again</button>
    <button type="button"
            (click)="confirmPlanSelection()"
            appLoadingButton
            [isLoading]="vm.consumerLoading"
            mat-flat-button>Yes</button>
  </div>
</div>
