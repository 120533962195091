import { Plan } from '@core/interfaces/plan/plan.interface';
import { Pagination } from '@core/interfaces/ui/pagination.interface';
import { ProtectionPlanRequest } from '@core/models/search/search-request.model';
import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';

export namespace StoreState {
  export interface IStoreState {
    plans: Plan[];
    searchRequest: ProtectionPlanRequest;
    pagination: Pagination;
    loading: boolean;
    saveInProgress: boolean;
  }

  export const initialState: IStoreState = {
    plans: [],
    searchRequest: {
      orderBy: [new GridOrderByModel('registrationDate', false)],
      page: 1,
      pageSize: 10,
      searches: [],
      noCount: false,
      noRows: false,
      regionId: null,
      retailerId: null,
      searchAsOneWord: false,
    },
    pagination: {
      totalCount: 0,
      page: 1,
      pageCount: 1,
    },
    loading: false,
    saveInProgress: false,
  };
}
