import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { RegisterFppClaimStore } from '../../../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import { ProductFilesPromptComponent } from '../../components/product-files-prompt/product-files-prompt.component';

@Component({
  selector: 'app-fpp-claim-closeup-files-upload',
  standalone: true,
  imports: [CommonModule, ProductFilesPromptComponent],
  templateUrl: './fpp-claim-closeup-files-upload.component.html',
  styleUrls: ['./fpp-claim-closeup-files-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FppClaimCloseupFilesUploadComponent {

  constructor(
    private readonly store: RegisterFppClaimStore,
  ) { }

  goToTagFileUpload(): void {
    this.store.wizardNavigationStore.setStep('productTag');
  }
}
