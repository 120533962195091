import { Injectable } from '@angular/core';

import { EnhancementApiService } from '@core/api/enhancement-api.service';
import { BaseStore } from '@core/store/_base/base.store';

import { StoreState } from './enhancements.state';
import initialState = StoreState.initialState;

@Injectable()
export class EnhancementsStore extends BaseStore<StoreState.IStoreState> {
  constructor(
    private readonly enhancementApiService: EnhancementApiService,
  ) {
    super(initialState);
  }

  loadData(): void{
    this.updateState({
      loading: true,
    });
    this.enhancementApiService
      .getRecent(true)
      .subscribe(enhancementsList => {
        this.updateState({
          enhancements: [...enhancementsList],
          loading: false,
        });
      });
  }

  setSeen(notificationId: string): void {
    this.updateState((oldState) => ({
      ...oldState,
      enhancements: [...oldState.enhancements.map(enhancement => {
        if (enhancement.id.toString() === notificationId) {
          return {
            ...enhancement,
            isSeen: true,
          };
        }
        return enhancement;
      })],
    }));
    this.enhancementApiService.markOneAsSeen(Number(notificationId)).subscribe(() => {
      this.loadData();
    });
  }
}
