import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, Optional, Self } from '@angular/core';
import { NgControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { PushPipe } from '@rx-angular/template/push';
import { combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import {
  FormSelectNewComponent,
} from '@shared/form/form-select-new/form-select-new.component';
import {
  ProblemTypeSubCategorySelectComponent,
} from '@shared/modules/claim-controls/components/problem-type-sub-category-select/problem-type-sub-category-select.component';
import {
  ProblemTypeSubCategoryStore,
} from '@shared/modules/claim-controls/components/problem-type-sub-category-select/store/problem-type-sub-category.store';

interface IProblemTypeSubCategorySelectState {
  disabled: boolean;
}

@Component({
  selector: 'app-problem-type-sub-category-select-new',
  standalone: true,
  templateUrl: './problem-type-sub-category-select-new.component.html',
  styleUrls: [],
  providers: [ProblemTypeSubCategoryStore, RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PushPipe,
    FormSelectNewComponent,
    ReactiveFormsModule,
    AsyncPipe,
    FormSelectComponent,
    NgIf,
  ],
})

export class ProblemTypeSubCategorySelectNewComponent extends ProblemTypeSubCategorySelectComponent implements OnInit {

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    protected readonly store: ProblemTypeSubCategoryStore,
    protected readonly state: RxState<IProblemTypeSubCategorySelectState>,
  ) {
    super(ngControl, store, state);
  }

  _initState(): void {
    this.state.set({
      disabled: true,
    });

    this.state.connect(
      'disabled',
      combineLatest([this.store.select$('loading'), this.store.select$('items')]).pipe(
        filter(() => !!this.formControl),
        map(([loading, items]) => {
          const noItems = !items || items.length === 0;
          this.formControl.disable();
          if (loading) {
            this.formControl.setValidators(Validators.required);
            this.formControl.updateValueAndValidity();
            return true;
          } else if (noItems) {
            this.formControl.setValidators([]);
            this.formControl.updateValueAndValidity();
            return true;
          } else {
            this.formControl.enable();
            this.formControl.setValidators(Validators.required);
            this.formControl.updateValueAndValidity();
            return false;
          }
        }),
      ),
    );

    this.disabled$
      .pipe(
        takeUntil(this.unsubscribeOnDestroy$),
      )
      .subscribe((disabled) => {
        if (disabled) {
          this.formControl.disable();
        } else {
          this.formControl.enable();
        }
      });
  }
}
