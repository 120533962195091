<div>
  <h2 class="section__title">Receipt</h2>
  <div class="block fpp-claim-files__tile">
    <h2 class="block__title"><b>Purchase Receipt</b></h2>
    <div class="register-consumer-claim__photos-receipt-info">
      <mat-icon svgIcon="info-outline" class="mat-icon--small _common-icon--theme register-claim__photos-receipt-icon-info"></mat-icon>
      <span>{{receiptText$ | push}}</span>
    </div>
    <ng-container *rxLet="receiptFiles$; let receiptFiles">
      <app-files-carousel *ngIf="receiptFiles.length > 0"
                          [files]="receiptFiles"
                          [showRemove]="(submitInProgress$ | push) === false"
                          [showDocumentName]="true"
                          [disableControls]="submitInProgress$ | push"
                          (removeFileByIndex)="removeFileIndex(filesForm.get('receiptFiles'), $event)"></app-files-carousel>
    </ng-container>
    <app-form-files-drop-area [formFilesArray]="filesForm.get('receiptFiles')"
                              [multiple]="true"
                              [pictureIcon]="'img-receipt-2'"
                              [sectionData]="{sectionName: 'Receipt'}"
                              [smallDropArea]="true"
                              [docType]="TDocumentType.Receipt"></app-form-files-drop-area>
  </div>

  <h2 class="block__title">Claim Images</h2>
  <p class="block__title help-text">Upload images for each of your products on the claim below.</p>
  <div>
    <div *ngFor="let productNav of productsNav$ | push; let productIndex=index;" class="block__title fpp-claim-files__tile">
      <h2 class="block__content"><p>{{productNav.title}}</p></h2>

      <ng-container *rxLet="productFiles$; let productFiles">
        <div *ngFor="let photoSection of photoSections; let productSectionIndex=index;" class="fpp-claim-files__photo-section">
          <h2 class="register-consumer-claim__files-preview-title">
            {{photoSection.title}}
            <span class="help-text register-claim__files-preview-status"
                  [class.help-text--required]="photoSection.required">{{photoSection.required ? 'Required' : '(Optional)'}}
          </span>
          </h2>
          <div [innerHTML]="photoSection.description"></div>
          <div>
            <div class="files-preview-block">
              <app-files-preview-new *ngIf="productFiles[productIndex]['section' + (productSectionIndex + 1)].length > 0"
                                     [files]="productFiles[productIndex]['section' + (productSectionIndex + 1)]"
                                     [showRemove]="(submitInProgress$ | push) === false"
                                     [showDocumentName]="true"
                                     [showCaptions]="false"
                                     [disableControls]="submitInProgress$ | push"
                                     (removeFileByIndex)="removeFileIndex(filesForm.get('productFiles')['controls'][productIndex].get('section' + (productSectionIndex + 1)), $event)"></app-files-preview-new>
            </div>
            <app-form-files-drop-area [formFilesArray]="filesForm.get('productFiles')['controls'][productIndex].get('section' + (productSectionIndex + 1))"
                                      [sectionData]="{productIndex, sectionIndex: productSectionIndex}"
                                      [pictureIcon]="'img-claimphotosdocuments'"
                                      [smallDropArea]="true"
                                      [maxFilesNumber]="1"
                                      [docType]="TDocumentType.CustPhoto"></app-form-files-drop-area>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="check-photos-section fpp-claim-files__validation-section">
    <div class="fpp-claim-files__validation-icon">
      <mat-icon class="users__action-icon users__action-icon--doublecheck" svgIcon="doublecheck"></mat-icon>
    </div>
    <div class="fpp-claim-files__validation-text">Please check all photos before sending them to us. <b>If they don’t meet the above criteria,</b> we will need to request more, which will delay the processing of your claim.</div>
  </div>
  <div class="form-step-buttons fpp-claim-files__steps">
    <button type="button"
            id="button-back"
            (click)="goBack()"
            mat-flat-button color="primary">Back</button>
    <app-files-total-size-error class="fpp-claim-files__size-error" [filesForm]="filesForm"></app-files-total-size-error>
    <button type="button"
            id="button-next"
            [disabled]="rootForm.invalid || filesForm.invalid"
            (click)="submitForm()"
            appLoadingButton
            [isLoading]="submitInProgress$ | push"
            mat-flat-button color="primary">Submit Claim</button>
  </div>
</div>
