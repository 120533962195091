import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { RxIf } from '@rx-angular/template/if';
import { LetDirective } from '@rx-angular/template/let';
import { combineLatestWith } from 'rxjs/operators';

import {
  ExpandableCardComponent,
} from '../../../../../../shared/components/expandable-cards/expandable-card/expandable-card.component';
import { ClaimIncidentCardComponent } from '../../components/claim-incident-card/claim-incident-card.component';

interface IExpandableCardWitPaginationState<T> {
  items: T[];
  activeItem: T;
  activeItemNumber: number;
  totalItems: number;
  showNext: boolean;
  showPrev: boolean;
  showPagination: boolean;
}

@Component({
  selector: 'app-expandable-card-with-pagination',
  standalone: true,
  imports: [
    CommonModule,
    ClaimIncidentCardComponent,
    ExpandableCardComponent,
    LetDirective, RxIf,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './expandable-card-with-pagination.component.html',
  styleUrls: ['./expandable-card-with-pagination.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableCardWithPaginationComponent<T> {
  @Input() set items(items: T[]) {
    const totalItems = items.length;
    this.state.set({
      items,
      totalItems,
      showPagination: totalItems > 1,
    });
  }
  @Input() noResultsText = 'No results found';
  @Input() cardTemplate: TemplateRef<any>;

  vm$ = this.state.select();

  constructor(
    private state: RxState<IExpandableCardWitPaginationState<T>>,
  ) {
    this.state.set({
      activeItemNumber: 1,
    });
    this.state.connect(
      this.state.select('activeItemNumber').pipe(
        combineLatestWith(this.state.select('items')),
      ),
      (oldState, [activeItemNumber, items]) => ({
        ...oldState,
        activeItem: items[activeItemNumber - 1],
        showNext: activeItemNumber < items.length,
        showPrev: activeItemNumber > 1,
      }));
  }

  nextIncident(): void {
    const activeItemNumber = this.state.get('activeItemNumber');
    if (activeItemNumber < this.state.get('totalItems')) {
      this.state.set({
        activeItemNumber: activeItemNumber + 1,
      });
    }
  }

  previousIncident(): void {
    const activeItemNumber = this.state.get('activeItemNumber');
    if (activeItemNumber > 1) {
      this.state.set({
        activeItemNumber: activeItemNumber - 1,
      });
    }
  }
}
