<div *ngIf="readonly === true; else regularSelect">
  <div class="field__label">Contact Method</div>
  <div class="field__text">{{selectedItemName$ | push | appEmptyValue}}</div>
</div>
<ng-template #regularSelect>
  <app-form-select-new [formControl]="formControl"
                   [data]="itemsList$ | push"
                   [label]="'Contact Method'"></app-form-select-new>
</ng-template>


