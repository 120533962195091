<app-bottom-popup-dialog-animation>
  <ng-container *rxLet="vm$; let vm">
    <div class="select-product-bottom-popup__header">
      <mat-icon (click)="close()">expand_more</mat-icon>
    </div>
    <app-form-select-new [data]="vm.products"
                         [(ngModel)]="selectedProductIds"
                         [ngModelOptions]="{standalone: true}"
                         [multiple]="true"
                         [label]="'Products'"
                         [disabled]="vm.loadProductsInProgress"></app-form-select-new>
    <div class="select-product-bottom-popup__actions">
      <button mat-stroked-button
              (click)="addNewProduct()"
              [disabled]="vm.loadProductsInProgress">Add New Product</button>
      <button mat-flat-button
              (click)="addSelectedProducts()"
              appLoadingButton
              [isLoading]="vm.loadProductsInProgress"
              [disabled]="selectedProductIds.length === 0">Add Selected Products</button>
    </div>
  </ng-container>
</app-bottom-popup-dialog-animation>
