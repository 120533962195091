import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RxFor } from '@rx-angular/template/for';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-pagination-indicator',
  standalone: true,
  imports: [CommonModule, RxFor],
  templateUrl: './pagination-indicator.component.html',
  styleUrls: ['./pagination-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationIndicatorComponent {
  @Input() set size(size: number) {
    this.indicatorsArray$$.next(Array(size).fill(null));
  }
  @Input() activeIndex: number;

  indicatorsArray$$ = new BehaviorSubject<null[]>([]);
  indicatorsArray$ = this.indicatorsArray$$.asObservable();
}
