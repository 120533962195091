import { Injectable } from '@angular/core';

import { IConsumer } from '../interfaces/consumer/consumer.interface';
import { CrudApiBaseService } from './crud-api.base';

@Injectable({
  providedIn: 'root',
})
export class MyContactApiService extends CrudApiBaseService<any> {
  endpoint = 'mycontact';

  contactInfo(consumer: IConsumer & {planNumber?: string}) {
    return this.httpClient.put(this.getUrl('contactinfo'), consumer);
  }
}
