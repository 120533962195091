import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { PushModule } from '@rx-angular/template/push';
import { map } from 'rxjs/operators';

import { Product } from '@core/interfaces/quick-search/search-results.interface';
import { ClaimDialogsCoreModule } from '@shared/modules/claim-dialogs/claim-dialogs-core.module';

import { RegisterFppClaimStore } from '../../store/register-fpp-claim.store';

@Component({
  selector: 'app-fpp-claim-products',
  templateUrl: './fpp-claim-products.component.html',
  styleUrls: ['./fpp-claim-products.component.scss'],
  standalone: true,
  imports: [ClaimDialogsCoreModule, MatButtonModule, NgIf, PushModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FppClaimProductsComponent implements OnInit {
  selectProductsData$ = this.store.select$('planDetails', 'coveredProducts').pipe(
    map(products => ({
      products,
    })),
  );
  isPaperPlan$ = this.store.select$('isPaperPlan');
  form: FormGroup;

  constructor(private readonly store: RegisterFppClaimStore) {}

  ngOnInit(): void {
    this.form = this.store.getForm() as FormGroup;
  }

  addProducts(products: Product[] = null) {
    this.store.addProducts(products);
    this.store.goToProduct(0);
  }

  goToConsumer() {
    this.store.wizardNavigationStore.setStep('consumer');
  }
}
