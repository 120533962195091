import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-five-nine-chat',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './five-nine-chat.component.html',
  styleUrls: ['./five-nine-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FiveNineChatComponent {
  constructor() {
    const options = {
      rootUrl: 'https://app.five9.com/consoles/',
      type: 'chat',
      title: 'Montage Furniture Services',
      tenant: 'Montage Furniture Services',
      profiles: 'English,Spanish',
      showProfiles: true,
      autostart: true,
      theme: `${window.origin}/assets/css/five9-chat.css`,
      surveyOptions: {
        showComment: true,
        requireComment: false,
      },
      fields: {
        name: {
          value: '',
          show: true,
          label: 'Name',
        },
        email: {
          value: '',
          show: true,
          label: 'Email',
        },
        'Chat.LastLetter': {
          value: '',
          show: true,
          label: 'Claim ID',
          required: true,
        },
        UserLocale: {
          value: 'en',
          show: false,
        },
      },
      playSoundOnMessage: true,
      allowCustomerToControlSoundPlay: false,
      showEmailButton: true,
      hideDuringAfterHours: false,
      useBusinessHours: true,
      showPrintButton: false,
      allowUsabilityMenu: false,
      enableCallback: false,
      callbackList: '',
      allowRequestLiveAgent: false,
    };
    window['Five9SocialWidget'].addWidget(options);
  }
}
