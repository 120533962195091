<div *rxLet="vm$; let vm">
  <div>
    <app-form-select-new [data]="vm.products"
                     [(ngModel)]="selectedProductIds"
                     [ngModelOptions]="{standalone: true}"
                     [multiple]="true"
                     [label]="'Products'"
                     [disabled]="vm.loadProductsInProgress"></app-form-select-new>
  </div>
  <ng-container *cdkPortal>
    <div class="fpp-claim-select-product__footer">
      <div>
        <button mat-stroked-button
                (click)="addNewProduct()"
                [disabled]="vm.loadProductsInProgress">Add New Product</button>
      </div>
      <button mat-flat-button
              (click)="addSelectedProducts()"
              appLoadingButton
              [isLoading]="vm.loadProductsInProgress"
              [disabled]="selectedProductIds.length === 0">Add Selected Products</button>
    </div>
  </ng-container>
</div>
