import { Identity } from '../../../../../../core/interfaces/util/identity.interface';

export namespace StoreState {
  export interface IStoreState {
    items: Identity[];
    loading: boolean;
    selected: Identity;
    selectedExists: boolean;
    disabled: boolean;
  }

  export const initialState: IStoreState = {
    items: [],
    loading: false,
    selected: null,
    selectedExists: false,
    disabled: false,
  };
}
