import { DatePipe, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA, MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { tap } from 'rxjs/operators';

import { Plan, Refund } from '@core/interfaces/plan/plan.interface';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { MyPlanApiService } from '../../../../../core/api/my-plan-api.service';

@Component({
  selector: 'app-cancel-plan-dialog',
  standalone: true,
  templateUrl: './cancel-plan-dialog.component.html',
  styleUrls: ['./cancel-plan-dialog.component.scss'],
  imports: [
    DialogCoreModule,
    MatLegacyInputModule,
    DatePipe,
    NgIf,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    LoadingButtonDirective,
  ],
})
export class CancelPlanDialogComponent implements OnInit {
  cancellationReason = '';
  cancellationInProgress = false;
  refund: Refund;
  plan: Plan;
  today = new Date();

  constructor(
    public dialogRef: MatDialogRef<CancelPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {refund: Refund; plan: Plan},
    public planApi: MyPlanApiService,
    public notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.refund = this.data.refund;
    this.plan = this.data.plan;
  }

  cancelPlan() {
    this.cancellationInProgress = true;
    this.planApi
      .cancelPlan(this.data.plan.crmRefId, { cancellationReason: this.cancellationReason })
      .pipe(
        tap(
          () => {
            this.notification.next({
              message:
                'Your plan cancellation has been requested. You can monitor the plan status to see if and when a refund has been approved.',
              type: NotificationType.Success,
              duration: 6000,
            });
            this.dialogRef.close();
          },
          error => {
            this.cancellationInProgress = false;
          },
        ),
      )
      .subscribe();
  }
}
