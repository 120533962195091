import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize, map, skip, take } from 'rxjs/operators';

import { BaseStore } from '@core/store/_base/base.store';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { MyPlanApiService } from '../../api/my-plan-api.service';
import { MyPlansStore } from '../my-plans/my-plans.store';
import { StoreState } from './my-plan-item.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;
import { TIFF_FILE_PLACEHOLDER } from '@core/constants/app.constants';
import { Files } from '@core/interfaces/claims/files.interface';
import { HubEvent, THubMethods } from '@core/models/hub-event.model';
import { MainHub } from '@core/services/hubs/main.hub';

import { ConsumerClaimService } from '../../services/consumer-claim.service';

@Injectable({
  providedIn: 'root',
})
export class MyPlanItemStore extends BaseStore<IStoreState> {
  detailsOpened$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected myPlanApiService: MyPlanApiService,
    public notification: NotificationService,
    public router: Router,
    protected consumerClaimService: ConsumerClaimService,
    private readonly myPlansStore: MyPlansStore,
    private readonly mainHub: MainHub,
  ) {
    super(initialState);
    this.mainHub.baseHub.eventBus$.subscribe((event: HubEvent) => {
      const currentId = this.get('currentId');
      switch (event.name) {
        case THubMethods.MyDocsUploaded:
          if (event.data.planId === +currentId) {
            this.getFiles();
          }
          break;
      }
    });
  }

  changePlanDetailsPage(page: number, claimIndex: number) {
    this.updateState({ loading: true });
    this.myPlansStore.updatePagination(page);
    this.myPlansStore
      .select$('plans')
      .pipe(skip(1), take(1))
      .subscribe(plans => {
        this.setCurrentId(plans[claimIndex].id);
        this.getItem();
      });
  }

  allFilesLoaded() {
    this.updateState({ filesLoading: false });
  }

  getItem() {
    this.updateState({ loading: true });
    this.myPlanApiService
      .detailed(this.get('currentId'))
      .pipe(
        finalize(() => {
          this.updateState({ loading: false });
        }),
      )
      .subscribe({
        next: plan => {
          this.updateState({ plan });
          this.getFiles();
          this.getReceiptFiles(plan.planInfo?.consumerPlanName || '');
        },
        error: error => {
          if (error.status === 403 || error.status === 404) {
            this.notification.next({
              message: 'You do not have access to this plan.',
              type: NotificationType.Error,
            });
          }
        },
      });
  }

  setCurrentId(id: number) {
    if (window.location.pathname === this.getRouteUrl()) {
      this.router.navigate([this.getRouteUrl()], { queryParams: { id } });
    }
    this.detailsOpened$.next(true);
    return this.updateState({ currentId: id });
  }

  destroy() {
    this.detailsOpened$.next(false);
    this.updateState({ plan: null });
    if (window.location.pathname === this.getRouteUrl()) {
      this.router.navigate([this.getRouteUrl()]);
    }
  }

  getFiles() {
    this.updateState({ files: [], filesLoading: true });
    const currentId = this.get('currentId');
    if (currentId) {
      this.myPlanApiService
        .getFiles(currentId)
        .pipe(finalize(() => this.updateState({ filesLoading: false })))
        .subscribe(files => {
          this.updateState({
            files: [...files],
          });
        });
    }
  }

  getRouteUrl(): string {
    return '/app/my-plans';
  }

  getReceiptFiles(consumerPlanName: string) {
    this.updateState({ receiptFiles: [], receiptFilesLoading: true });
    if (consumerPlanName) {
      this.myPlanApiService
        .getReceipt(consumerPlanName)
        .pipe(
          map(files => this._parseFiles(files)),
          finalize(() => this.updateState({ receiptFilesLoading: false })),
        )
        .subscribe(receiptFiles => {
          if (receiptFiles.length > 0) {
            this.updateState({
              receiptFiles: [...receiptFiles],
            });
          }
          this.updateState({
            isReceiptUploaded: receiptFiles.length > 0,
          });
        });
    }
  }

  uploadReceiptFiles(filesToUpload: Files[]) {
    this.updateState({ receiptFilesLoading: true });
    const currentId = this.get('currentId');
    return this.consumerClaimService.uploadPlanFiles(filesToUpload, currentId).pipe(
      finalize(() => {
        this.updateState({
          receiptFilesLoading: false,
          isReceiptUploaded: true,
        });
      }),
    );
  }

  private _parseFiles(files: Omit<Files, 'index'>[]): Files[] {
    return files.map((file, index) => {
      const thumbnailUrl = this._returnFilePreview(file);

      return {
        ...file,
        thumbnailUrl,
        index,
      };
    });
  }

  private _returnFilePreview(file: Omit<Files, 'index'>): string {
    const fileType = file.mimeType;
    const isImage = fileType.match(/image\/.+/);
    if (fileType === 'application/pdf') {
      return file.thumbnailUrl || '/assets/icons/pdf.svg';
    } else if (isImage) {
      const isSafari = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
      const isEdge = navigator.userAgent.includes('Edge');
      const isTif = fileType.includes('image/tif');
      if (isTif) {
        if (isSafari || isEdge) {
          return file.thumbnailUrl;
        } else {
          return TIFF_FILE_PLACEHOLDER;
        }
      } else {
        return file.thumbnailUrl;
      }
    } else if (fileType.match(/application\/vnd\..+/) || fileType === 'application/msword') {
      return file.thumbnailUrl || '/assets/icons/doc.svg';
    } else {
      return file.thumbnailUrl;
    }
  }
}
