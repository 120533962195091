import { Portal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { IWizardNavigation } from '@consumer-core/interfaces/wizard-navigation.interface';
import { WizardPortalService } from '@consumer-core/services/wizard-portal.service';
import { TRegisterPlanNavigationStepMobile } from '@consumer-core/stores/register-plan/register-plan.state';
import { RegisterPlanStore } from '@consumer-core/stores/register-plan/register-plan.store';
import { WizardNavigationStore } from '@consumer-core/stores/wizard-navigation/wizard-navigation.store';
import { RxState } from '@rx-angular/state';
import { RxIf } from '@rx-angular/template/if';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { of } from 'rxjs';

import { DialogService } from '@core/services/dialog.service';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import {
  BottomDialogAnimationComponent,
} from '@shared/standalone/bottom-dialog-animation/bottom-dialog-animation.component';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';

import { WizardStore } from '../../../core/stores/wizard.store';
import { HeaderMobileComponent } from '../../../shared/components/header-mobile/header-mobile.component';
import {
  WizardContactInfoMobileComponent,
} from '../../../shared/components/wizard/wizard-contact-info-mobile/wizard-contact-info-mobile.component';
import {
  WIZARD_HOME_ADDRESS_NEXT_STEP,
} from '../../../shared/components/wizard/wizard-home-address-mobile/wizard-home-address.token';
import {
  WizardHomeAddressMobileComponent,
} from '../../../shared/components/wizard/wizard-home-address-mobile/wizard-home-address-mobile.component';
import { PlanConfirmMobileComponent } from './containers/plan-confirm-mobile/plan-confirm-mobile.component';
import { PlanFilesUploadMobileComponent } from './containers/plan-files-upload-mobile/plan-files-upload-mobile.component';
import { PlanFinishMobileComponent } from './containers/plan-finish-mobile/plan-finish-mobile.component';
import { PlanFoundMobileComponent } from './containers/plan-found-mobile/plan-found-mobile.component';
import { PlanLookupMobileComponent } from './containers/plan-lookup-mobile/plan-lookup-mobile.component';
import {
  PlanPurchaseDetailsMobileComponent,
} from './containers/plan-purchase-details-mobile/plan-purchase-details-mobile.component';
import { PlanReviewMobileComponent } from './containers/plan-review-mobile/plan-review-mobile.component';

interface IRegisterPlanConsumerMobileState {
  actionsPortal: Portal<any>;
  showBackButton: boolean;
  showCloseButton: boolean;
  progress: number;
  activeComponent: any;
  headerActionsDisabled: boolean;
}

@Component({
  selector: 'app-register-plan-consumer-mobile',
  standalone: true,
  imports: [
    CommonModule,
    CustomOverlayScrollbarDirective,
    PushPipe,
    MatDialogModule,
    PortalModule,
    HeaderMobileComponent,
    MatButtonModule,
    MatIconModule,
    RxIf,
    MatProgressBarModule,
    LetDirective,
    BottomDialogAnimationComponent,
    FormsModule,
  ],
  templateUrl: './register-plan-consumer-mobile.component.html',
  styleUrls: ['./register-plan-consumer-mobile.component.scss'],
  providers: [
    RegisterPlanStore,
    WizardNavigationStore,
    WizardPortalService,
    RxState,
    SideDialogStateService,
    {
      provide: WizardStore,
      useExisting: RegisterPlanStore,
    },
    {
      provide: WIZARD_HOME_ADDRESS_NEXT_STEP,
      useValue: 'uploadReceipt',
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterPlanConsumerMobileComponent {
  form = this.store.form;
  vm$ = this.state.select();
  dialogState = inject(SideDialogStateService);
  isVisible$ = this.dialogState.isVisible$;

  private _navigationList: IWizardNavigation<TRegisterPlanNavigationStepMobile>[] = [
    {
      id: 'lookupPlan',
      title: 'Plan Lookup',
      valid$: of(true),
      component: PlanLookupMobileComponent,
    },
    {
      id: 'planFound',
      title: 'Plan Found',
      valid$: of(true),
      component: PlanFoundMobileComponent,
    },
    {
      id: 'confirmPlan',
      title: 'Plan Confirm',
      valid$: of(true),
      component: PlanConfirmMobileComponent,
    },
    {
      id: 'purchaseDetails',
      title: 'Plan Purchase Details',
      valid$: of(true),
      component: PlanPurchaseDetailsMobileComponent,
    },
    {
      id: 'contactInfo',
      title: 'Contact Info',
      valid$: of(true),
      component: WizardContactInfoMobileComponent,
    },
    {
      id: 'homeAddress',
      title: 'Home Address',
      valid$: of(true),
      component: WizardHomeAddressMobileComponent,
    },
    {
      id: 'uploadReceipt',
      title: 'Upload Receipt',
      valid$: of(true),
      component: PlanFilesUploadMobileComponent,
    },
    {
      id: 'review',
      title: 'Plan Review',
      valid$: of(true),
      component: PlanReviewMobileComponent,
    },
    {
      id: 'finish',
      title: 'Finish',
      valid$: of(true),
      component: PlanFinishMobileComponent,
    },
  ];

  constructor(
    private readonly state: RxState<IRegisterPlanConsumerMobileState>,
    private readonly store: RegisterPlanStore,
    private readonly wizardPortalService: WizardPortalService,
    private readonly dialogRef: MatDialogRef<RegisterPlanConsumerMobileComponent>,
    private readonly dialogService: DialogService,
  ) {
    this.store.wizardNavigationStore.setNavigationList(this._navigationList);
    this.store.wizardNavigationStore.setStep('lookupPlan');
    this.state.connect('actionsPortal', this.wizardPortalService.actionsPortal$);
    this.state.connect('activeComponent', this.store.wizardNavigationStore.selectActiveComponent$());
    this.state.connect(this.store.wizardNavigationStore.selectActiveStep$(), (oldState, activeStep: string) => ({
      ...oldState,
      showBackButton: activeStep !== this._navigationList[0].id && !['purchaseDetails', 'finish'].includes(activeStep),
      showCloseButton: activeStep !== 'finish',
      progress: this._navigationList.findIndex(
        item => item.id === activeStep) / (this._navigationList.length - 1) * 100,
    }));
    this.state.connect('headerActionsDisabled', this.store.select$('submitInProgress'));
  }

  stepBack(): void {
    this.store.wizardNavigationStore.stepBack();
  }

  close(): void {
    this.dialogService.canLeaveDefaultConfirmationObservable(this.store.form, this.store.filesForm.dirty).subscribe(canLeave => {
      if (canLeave) {
        this.dialogState.close(this.dialogRef);
      } else {
        return;
      }
    });
  }
}
