import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormControlStatus, FormGroup, Validators } from '@angular/forms';
import { WizardNavigationStore } from '@consumer-core/stores/wizard-navigation/wizard-navigation.store';
import moment from 'moment';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';

import { MyContactApiService } from '@core/api/my-contact-api.service';
import { ALLOWED_STATUSES } from '@core/constants/app.constants';
import { TDocumentType } from '@core/enums/document-type';
import { Address } from '@core/interfaces/address.interface';
import { Files, IUploadedFile } from '@core/interfaces/claims/files.interface';
import { IConsumer } from '@core/interfaces/consumer/consumer.interface';
import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { Product } from '@core/interfaces/quick-search/search-results.interface';
import { ClaimFormService } from '@core/services/claim-form.service';
import { FilesService } from '@core/services/files.service';
import { BaseStore } from '@core/store/_base/base.store';
import { ClaimUtil } from '@core/utils/claim.util';
import { convertReadOnlyToFile } from '@core/utils/file.util';
import {
  IClaimFilesForm,
  IClaimProductFiles,
  validateClaimMaxFilesSize,
  validateIdenticalFiles,
} from '@core/utils/register-claim';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { AddressType } from '@shared/modules/claim-dialogs/enums/address-type.enum';
import {
  PromptDialogNewComponent,
} from '@shared/modules/side-dialog/components/prompt-dialog-new/prompt-dialog-new.component';

import { MyClaimApiService } from '../../../core/api/my-claim-api.service';
import { MyPlanApiService } from '../../../core/api/my-plan-api.service';
import { FppClaimProductComponent } from '../containers/fpp-claim-product/fpp-claim-product.component';
import { StoreState, TRegisterFppClaimNavigationStep } from './register-fpp-claim.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;

@Injectable()
export class RegisterFppClaimStore extends BaseStore<IStoreState> {
  form$$ = new BehaviorSubject<FormGroup>(null);
  form$ = this.form$$.asObservable();

  filesForm = this.fb.group<IClaimFilesForm>(
    {
      receiptFiles: this.fb.array<FormControl<Files>>(
        [],
        Validators.compose([Validators.required, validateIdenticalFiles()]),
      ),
      productFiles: this.fb.array<FormControl<IClaimProductFiles>>([]),
    },
    {
      validators: [validateClaimMaxFilesSize()],
    },
  );

  consumerKeysWithoutNameAndEmail = [
    'mobilePhone',
    'homePhone',
    'workPhone',
    'preferredContactLanguage',
    'preferredContactMethod',
    'preferredContactTime',
    'streetAddress1',
    'streetAddress2',
    'city',
    'stateProvince',
    'postalCode',
  ];

  get form(): FormGroup {
    return this.form$$.getValue();
  }

  constructor(
    private readonly myPlanApiService: MyPlanApiService,
    private readonly fb: FormBuilder,
    private readonly claimFormService: ClaimFormService,
    private readonly myContactApiService: MyContactApiService,
    private readonly myClaimApiService: MyClaimApiService,
    public readonly wizardNavigationStore: WizardNavigationStore<TRegisterFppClaimNavigationStep, 'product'>,
    private readonly filesService: FilesService,
    private readonly modalDialogService: ModalDialogService,
  ) {
    super(initialState);
  }

  getForm(key: string = ''): FormGroup | FormArray {
    if (key) {
      if (key === 'coveredProducts') {
        return this.form.get(key) as FormArray;
      } else {
        return this.form.get(key) as FormGroup;
      }
    } else {
      return this.form;
    }
  }

  selectFormStatusChanges$(formControlKey: string): Observable<FormControlStatus> {
    return this.form$.pipe(
      switchMap(form => {
        if (form) {
          return form.get(formControlKey).statusChanges;
        } else {
          return EMPTY;
        }
      }),
    );
  }

  getFilesForm(): FormGroup<{receiptFiles: FormArray<FormControl<Files>>; productFiles: FormArray<FormControl<IClaimProductFiles>>}> {
    return this.filesForm;
  }

  getPlanDetails(plan: PlanDetails): void {
    if (plan.planInfo.id) {
      this.updateState({
        isLoading: true,
      });
      this.myPlanApiService
        .detailed(plan.planInfo.id)
        .pipe(
          map(planDetails => {
            planDetails.coveredProducts.forEach(product => {
              product.disabled = ALLOWED_STATUSES.indexOf(product.coveredProductStatus) === -1;
            });
            return planDetails;
          }),
          finalize(() => {
            this.updateState({
              isLoading: false,
            });
          }),
        )
        .subscribe(planDetails => {
          this.handlePlanDetails(planDetails);
        });
    } else {
      this.handlePlanDetails(plan);
    }
  }

  handlePlanDetails(planDetails: PlanDetails): void {
    const isPaperPlan = planDetails.coveredProducts === null || planDetails.coveredProducts.length === 0;
    this.updateState({
      planDetails,
      isPaperPlan,
    });
    this._initForm(planDetails);
    if (isPaperPlan) {
      this.addProducts(null);
    }
    this._getReceiptFiles(planDetails.planInfo.consumerPlanName);
  }

  addProducts(products: Product[]): void {
    let lastProductIndex = this.form.get('coveredProducts').value.length - 1;
    if (!products) {
      (this.form.get('coveredProducts') as FormArray).push(this.claimFormService.getConsumerProductFields({}));
      (this.filesForm.get('productFiles') as FormArray).push(
        this.fb.group({
          section1: this.fb.array([], Validators.required),
          section2: this.fb.array([], Validators.required),
          section3: this.fb.array([], Validators.required),
          section4: this.fb.array([]),
        }),
      );
    } else {
      products.forEach((product) => {
        (this.form.get('coveredProducts') as FormArray).push(
          this.claimFormService.getConsumerProductFields(product, true),
        );
        (this.filesForm.get('productFiles') as FormArray).push(
          this.fb.group({
            section1: this.fb.array([], Validators.required),
            section2: this.fb.array([], Validators.required),
            section3: this.fb.array([], Validators.required),
            section4: this.fb.array([]),
          }),
        );
      });
    }
    const navigationList = this.wizardNavigationStore.get('navigationList').map(navigationItem => {
      if (navigationItem.title === 'Products on Claim') {
        if (!products) {
          navigationItem.children.push({
            id: 'product',
            title: `Product ${lastProductIndex + 2}`,
            valid$: (this.form.get('coveredProducts') as FormArray).controls[lastProductIndex + 1].statusChanges.pipe(
              map(status => status === 'VALID'),
            ),
            component: FppClaimProductComponent,
          });
        } else {
          products.forEach(product => {
            navigationItem.children.push({
              id: 'product',
              title: product.productName,
              valid$: (this.form.get('coveredProducts') as FormArray).controls[
                lastProductIndex + 1
              ].statusChanges.pipe(map(status => status === 'VALID')),
              component: FppClaimProductComponent,
            });
            lastProductIndex++;
          });
        }
      }
      return navigationItem;
    });

    this.wizardNavigationStore.setNavigationList(navigationList);
  }

  removeProduct(productIndex: number): void {
    (this.form.get('coveredProducts') as FormArray).removeAt(productIndex);
    (this.filesForm.get('productFiles') as FormArray).removeAt(productIndex);

    const navigationList = this.wizardNavigationStore.get('navigationList').map(navigationItem => {
      if (navigationItem.title === 'Products on Claim') {
        navigationItem.children.splice(productIndex, 1);
        navigationItem.children = [...navigationItem.children];
      }
      return navigationItem;
    });
    this.wizardNavigationStore.setNavigationList(navigationList);
  }

  submitForm(): Observable<unknown> {
    const submitInProgress = this.get('submitInProgress');
    const planDetails = this.get('planDetails');
    if (submitInProgress || this.form.invalid) {
      return EMPTY;
    }
    if (this.form.valid) {
      this.updateState({
        submitInProgress: true,
      });
      // TODO: Try to move payload generation to service
      const claimValue: {consumer; consumerPlan; consumerAddress?; serviceAddress?} = {
        consumer: {
          id: null,
          crmRefId: planDetails.consumer.crmRefId,
          contactId: planDetails.consumer.contactId,
        },
        consumerPlan: {
          crmRefId: planDetails.planInfo.crmRefId,
          coveredProducts: this.form.getRawValue().coveredProducts,
        },
        serviceAddress: this.form.value.consumer.serviceAddress,
      };

      claimValue.consumerPlan.coveredProducts.forEach((product, index) => {
        product.productIncident.dateNoticed = moment(product.productIncident.dateNoticed).utc(true).format();
        product.clientRefObject = index.toString();
      });

      if (
        this._consumerWasChanged(
          {
            ...this.form.value.consumer.info,
            ...this.form.value.consumer.consumerAddress,
          },
          [...this.consumerKeysWithoutNameAndEmail, 'emailAddress'],
        )
      ) {
        return this._updateContactInfoAndCreateClaim(
          {
            ...this.form.value.consumer.info,
            ...this.form.value.consumer.consumerAddress,
          },
          claimValue,
        );
      } else {
        return this._createClaim(claimValue, this._flatFormFiles());
      }
    }
  }

  goToProduct(activeProductIndex: number): void {
    this.updateState({
      activeProductIndex,
    });
    this.wizardNavigationStore.setStep('product');
  }

  removeReceiptFile(fileIndex: number): void {
    const receiptFilesForm = this.getFilesForm().get('receiptFiles') as FormArray;
    this._removeFile(receiptFilesForm, fileIndex);
  }

  getProductFilesSectionForm(activeProductIndex: number, sectionNumber: number): FormArray {
    return (this.getFilesForm().get('productFiles') as FormArray).at(activeProductIndex).get(`section${sectionNumber}`) as FormArray;
  }

  removeProductFile(fileIndex: number, activeProductIndex: number, sectionNumber: number): void {
    const sectionForm = this.getProductFilesSectionForm(activeProductIndex, sectionNumber);
    this._removeFile(sectionForm, fileIndex);
  }

  getProductName(activeProductIndex: number): string {
    return (this.getForm('coveredProducts') as FormArray).at(activeProductIndex).getRawValue().productName
      || `Product ${activeProductIndex + 1}`;
  }

  handleReceiptFiles($event: Event): void {
    const receiptFilesForm = this.getFilesForm().get('receiptFiles') as FormArray;
    this.filesService
      .handleFilesInput($event, {
        docType: TDocumentType.Receipt,
      }).subscribe(uploadedFiles => {
        const identicalError = this.checkIdenticalPhotos(uploadedFiles, null, null);
        if (identicalError) {
          this._openIdenticalErrorDialog(identicalError);
        } else {
          this._saveFiles(receiptFilesForm, uploadedFiles, true);
        }
      });
  }

  handleProductFilesInput($event: Event, activeProductIndex: number, sectionNumber: number): void {
    const productFilesSectionForm = this.getProductFilesSectionForm(activeProductIndex, sectionNumber);
    this.filesService
      .handleFilesInput($event, {
        docType: TDocumentType.CustPhoto,
      }).subscribe(uploadedFiles => {
        const identicalError = this.checkIdenticalPhotos(uploadedFiles, activeProductIndex, sectionNumber - 1);
        if (identicalError) {
          this._openIdenticalErrorDialog(identicalError);
        } else {
          this._saveFiles(productFilesSectionForm, uploadedFiles);
        }
      });
  }

  getReceiptFiles(): Files[] {
    return this.getFilesForm().value.receiptFiles;
  }

  getProductFiles(activeProductIndex: number): Files[] {
    return Object.keys(this.getFilesForm().value.productFiles[activeProductIndex]).map(key =>
      this.getFilesForm().value.productFiles[activeProductIndex][key],
    ).flat();
  }

  getProductFilesAll(): Files[] {
    return this.getFilesForm().value.productFiles.map(product =>
      Object.keys(product).map(sectionKey => product[sectionKey]).flat(),
    ).flat();
  }

  checkIdenticalPhotos(uploadedFiles: IUploadedFile[], productIndex: number = null, sectionIndex: number = null): string | null {
    const filesValue = this.filesForm.value;
    const receiptPhotos = filesValue.receiptFiles.filter(file => !file.isReadOnly);
    const uploadedFileKeys = uploadedFiles.map(file => `${file.originalFile.name}${file.originalFile.size}`);
    const conflictSections: {productIndex: number; sectionIndex: number; fileKey: string}[] = [];

    const products = filesValue.productFiles;

    products.forEach((productFiles, productItemIndex) => {
      const sections = Object.keys(productFiles);
      if (productItemIndex === productIndex || productIndex === null) {
        sections.forEach((sectionKey, sectionKeyIndex) => {
          if (sectionKeyIndex !== sectionIndex) {
            const sectionFiles = productFiles[sectionKey];
            sectionFiles.forEach(file => {
              if (conflictSections.length === 0) {
                const fileKey = `${file.fileName}${file.size}`;
                if (uploadedFileKeys.includes(fileKey)) {
                  conflictSections.push({
                    productIndex,
                    sectionIndex,
                    fileKey,
                  });
                  conflictSections.push({
                    productIndex: productItemIndex,
                    sectionIndex: sectionKeyIndex,
                    fileKey,
                  });
                }
              }
            });
          }
        });
      }
    });


    if (productIndex !== null) {
      receiptPhotos.forEach(receiptPhoto => {
        const fileKey = `${receiptPhoto.fileName}${receiptPhoto.size}`;
        if (uploadedFileKeys.includes(fileKey)) {
          if (conflictSections.length === 0) {
            conflictSections.push({
              productIndex,
              sectionIndex,
              fileKey,
            });
            conflictSections.push({
              productIndex: null,
              sectionIndex: null,
              fileKey,
            });
          }
        }
      });
    }
    const photoErrors = conflictSections.map(conflictSection => {
      if (conflictSection.productIndex === null) {
        return '<strong>Receipt</strong>';
      } else {
        return `<strong>${this._getErrorSectionName(conflictSection.sectionIndex)}</strong> (product ${conflictSection.productIndex + 1})`;
      }
    });
    const parsedError = photoErrors.slice(0, -1).join(', ') + ' and ' + photoErrors.slice(-1);
    const errorMessage = photoErrors.length > 0 ? `photos of ${parsedError}` : null;

    if (errorMessage) {
      return (
        // eslint-disable-next-line max-len
        `We see that ${errorMessage} have an identical filename. If these are the same photo, please submit different photos per the instructions provided in the Photo Guide.`
      );
    } else {
      return null;
    }
  }

  private _getErrorSectionName(sectionIndex: number): string {
    const isMobile = this.get('isMobile');
    if (isMobile) {
      const mobileSectionNames = ['Entire Product', 'Damage', 'Damage Close Up', 'Product Tag'];
      return mobileSectionNames[sectionIndex];
    } else {
      return `#${sectionIndex + 1}`;
    }
  }

  private _saveFiles(filesForm: FormArray, uploadedFiles: IUploadedFile[], multiple = false): void {
    if (multiple) {
      const lastFile = filesForm.at(-1);
      let fileIndex = lastFile ? (lastFile.value.index + 1) : 0;
      const mappedFiles = uploadedFiles.map((file) => ({
        ...this.filesService.mapFilesToFormFiles(file, fileIndex++),
      }));
      mappedFiles.forEach(mappedFile => {
        filesForm.push(this.fb.control(mappedFile), {
          emitEvent: false,
        });
      });
    } else {
      const mappedFile = this.filesService.mapFilesToFormFiles(uploadedFiles[0], 0);
      filesForm.clear();
      filesForm.push(this.fb.control(mappedFile), {
        emitEvent: false,
      });
    }
    filesForm.updateValueAndValidity();
  }

  private _removeFile(filesForm: FormArray, fileIndex: number): void {
    const updatedFiles = filesForm.value.filter(file => fileIndex !== file.index);
    filesForm.clear();
    updatedFiles.forEach(file => {
      filesForm.push(this.fb.control(file));
    });
  }

  private _initForm(plan: PlanDetails): void {
    const planServiceAddress: Address = {
      streetAddress1: plan.planInfo.serviceStreetAddress1,
      streetAddress2: plan.planInfo.serviceStreetAddress2,
      city: plan.planInfo.serviceCity,
      stateProvince: plan.planInfo.serviceState,
      postalCode: plan.planInfo.servicePostalCode,
    };
    this.form$$.next(this.fb.group({
      consumer: this.fb.group({
        info: this.claimFormService.getConsumerFields(plan.consumer, false, { defaultPreferences: false }),
        consumerAddress: this.claimFormService.getAddressFields(plan.consumer, AddressType.CustomerAddress),
        serviceAddress: this.claimFormService.getAddressFields(planServiceAddress, AddressType.ServiceAddress),
      }),
      isServiceAddressSame: this.fb.control(ClaimUtil.compareAddresses(plan.consumer, planServiceAddress)),
      coveredProducts: this.claimFormService.getCoveredProductsArray([]),
    }));
    this.form.get('consumer.info.firstName').disable();
    this.form.get('consumer.info.lastName').disable();
  }

  private _getReceiptFiles(consumerPlanName: string): void {
    this.updateState({
      isLoadingReceiptFiles: true,
    });
    this.myPlanApiService
      .getReceipt(consumerPlanName)
      .pipe(
        finalize(() => {
          this.updateState({
            isLoadingReceiptFiles: false,
          });
        }),
      )
      .subscribe(uploadedReceiptFiles => {
        this.updateState({
          isReceiptUploaded: uploadedReceiptFiles.length > 0,
        });
        uploadedReceiptFiles.map(convertReadOnlyToFile).forEach(fileData => {
          (this.filesForm.get('receiptFiles') as FormArray).push(this.fb.control(fileData));
        });
      });
  }

  private _consumerWasChanged(newConsumer: Record<string, unknown>, fields: string[]): boolean {
    const planDetails = this.get('planDetails');
    let consumerWasChanged = false;
    if (planDetails.consumer) {
      fields.forEach(key => {
        // eslint-disable-next-line no-prototype-builtins
        if (newConsumer.hasOwnProperty(key) && planDetails.consumer.hasOwnProperty(key)) {
          if (newConsumer[key] !== planDetails.consumer[key]) {
            consumerWasChanged = true;
          }
        }
      });
    } else {
      consumerWasChanged = true;
    }
    return consumerWasChanged;
  }

  private _updateContactInfoAndCreateClaim(consumer: IConsumer, claim: Record<string, unknown>): Observable<boolean> {
    const planDetails = this.get('planDetails');
    return this.myContactApiService.contactInfo(consumer).pipe(
      switchMap(() => this._createClaim(
        {
          ...claim,
          consumer: {
            id: null,
            crmRefId: planDetails.consumer.crmRefId,
            contactId: planDetails.consumer.contactId,
          },
        },
        this._flatFormFiles(),
      )),
    );
  }

  private _createClaim(claimValue: Record<string, unknown>, files: Files[]): Observable<boolean> {
    claimValue.filesMetaData = files.map((file, index) => ({
      fileName: file.fileName || null,
      documentType: file.docType,
      clientReference: index.toString(),
    }));
    return this.myClaimApiService.createClaim(claimValue, files).pipe(
      tap(() => {
        this.updateState({
          claimSubmitted: true,
        });
      }),
      finalize(() => {
        this.updateState({
          submitInProgress: false,
        });
      }),
    );
  }

  private _flatFormFiles(): Files[] {
    let flatFiles: Files[] = [];
    flatFiles = flatFiles.concat(this.filesForm.get('receiptFiles').value.filter(file => !file.isReadOnly));
    this.filesForm.get('productFiles').value.forEach((product) => {
      flatFiles = flatFiles.concat(product.section1, product.section2, product.section3, product.section4);
    });

    return flatFiles;
  }

  private _openIdenticalErrorDialog(identicalError: string): void {
    this.modalDialogService.open({
      data: {
        message: identicalError,
        buttonNo: 'OK',
        showClose: false,
      },
    }, PromptDialogNewComponent, {
      disableClose: true,
    });
  }
}
