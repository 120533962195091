<app-side-dialog-animation class="_common-side-dialog_clear-view">
  <app-side-dialog-loader *ngIf="loadDetails$ | async"></app-side-dialog-loader>
  <div
    *ngIf="plan"
    class="side-dialog__header">
    <h2 class="register-fpp-claim__header">Claim for purchase {{ plan.planInfo.consumerPlanName }}</h2>
    <mat-icon
      class="side-dialog__close"
      svgIcon="close"
      (click)="close()"></mat-icon>
  </div>
  <div
    *ngIf="form"
    class="side-dialog__content">
    <div class="side-dialog__nav">
      <app-wizard-navigation
        [additionalValidations]="{ photos: filesForm.valid }"
        [navigationList]="navigationList"
        [currentStep]="currentStep"
        [navigationItems]="navigationItems"
        [form]="form"></app-wizard-navigation>
    </div>
    <div class="dialog__scroll-view side-dialog__content-scroll">
      <div
        class="section"
        [hidden]="currentStep.step !== steps.GettingStarted">
        <h2 class="section__title">Getting started</h2>
        <mat-icon
          class="claim-form__getting-started-icon"
          svgIcon="couch"></mat-icon>
        <div class="claim-form__tile">
          <p>
            To enable us to process your claim, we need you to provide information including a description of
            the issue, how and when it happened, and photos of the problem. Please be ready to provide this
            information prior to beginning the claim process.
          </p>
          <br />
          <p>
            <strong>If you are making a claim on more than one piece of furniture,</strong>
            enter the details for each piece separately.
          </p>
          <br />
          <p>
            <strong>If the furniture requiring service is at multiple different locations,</strong>
            please create a separate claim for each location.
          </p>
        </div>
      </div>
      <form
        class="claim-form__form"
        spellcheck="false">
        <div
          [hidden]="currentStep.step !== steps.Consumer"
          class="section">
          <h2
            class="section__title"
            *ngIf="!navigationItems.consumer.readonly">
            Consumer
          </h2>
          <div>
            <app-consumer-template-form
              [formGroupRef]="form.get('consumer.info')"></app-consumer-template-form>
            <div class="section">
              <h3 class="section__title">Home Address</h3>
              <app-address-readonly
                *ngIf="navigationItems.consumer.readonly; else consumerAddressForm"
                [address]="form.getRawValue().consumer.consumerAddress"></app-address-readonly>
              <ng-template #consumerAddressForm>
                <app-address-form [form]="form.get('consumer.consumerAddress')"></app-address-form>
                <app-address-suggest
                  [addressForm]="form.get('consumer.consumerAddress')"
                  (isInProgress)="setInProgressState($event)"></app-address-suggest>
              </ng-template>
            </div>
            <div class="section">
              <div class="claim-form__switch-header section__title">
                <h3 class="section__title">Location of Furniture Requiring Service</h3>
                <app-switch
                  class="claim-form__same-switch"
                  type="checkbox"
                  title="Same as Home address"
                  [(ngModel)]="isServiceAddressSame"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="isServiceAddressSameChanged()"></app-switch>
              </div>
              <app-address-template-form
                [formGroup]="form.get('consumer.serviceAddress')"
                [blocked]="isServiceAddressSame"></app-address-template-form>
              <app-address-suggest
                *ngIf="!isServiceAddressSame"
                [addressForm]="form.get('consumer.serviceAddress')"
                (isInProgress)="setInProgressState($event)"
                [isServiceAddressSame]="isServiceAddressSame"></app-address-suggest>
            </div>
          </div>
        </div>
        <div
          [hidden]="currentStep.step !== steps.Products"
          [formGroup]="form.get('coveredProducts')"
          class="claim-form__block">
          <div *ngIf="form.get('coveredProducts').value.length === 0">
            <app-select-product
              [data]="planProducts"
              [config]="{
                existed: !isPaperPlan,
                fromScratch: true,
                displayButtonAddNewProductsToClaim: hasFeatureAddNewProductsToClaim$ | async
              }"
              (selectProducts)="addProductsToForm($event)"
              (newProduct)="addProduct()"></app-select-product>
          </div>
          <div *ngFor="let product of form.get('coveredProducts')['controls']; let i = index">
            <div [hidden]="i !== currentSubStep">
              <div>
                <h2 class="section__title">{{ navigationItems.coveredProducts.child[i]?.name }}</h2>
                <div class="claim-form__header-buttons-block">
                  <div
                    (click)="removeProduct(i)"
                    class="claim-form__header-button">
                    <mat-icon
                      class="sub-nav__icon"
                      svgIcon="close"></mat-icon>
                    <span class="claim-form__header-button-text">Remove this product from the claim</span>
                  </div>
                </div>
              </div>
              <div>
                <app-consumer-product-form
                  [form]="product"
                  [readonly]="navigationItems.coveredProducts.child[i]?.readonly"
                  (productNameChanged)="productNameChanged($event, product, i)"></app-consumer-product-form>
                <div>
                  <h3 class="section__title">Describe the Problem</h3>
                  <app-consumer-product-incident-form
                    [incidentFormControl]="
                      product.get('productIncident')
                    "></app-consumer-product-incident-form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          [hidden]="currentStep.step !== steps.PhotosAndDocuments"
          class="section">
          <h2 class="block__title">Claim Images</h2>
          <p class="block__title help-text">Upload images for each of your products on the claim below.</p>
          <div>
            <div
              *ngFor="let product of navigationItems.coveredProducts.child; let productIndex = index"
              class="block__title claim-form__tile">
              <h2 class="block__content">
                <p>{{ product.name }}</p>
              </h2>

              <div
                *ngFor="let photoSection of photoSections; let productSectionIndex = index"
                class="block__title claim-form__tile claim-form__tile--photo-section">
                <h2 class="register-claim__files-preview-title block__item">
                  {{ photoSection.title }}
                  <span
                    class="help-text register-claim__files-preview-status"
                    [class.help-text--required]="photoSection.required">
                    {{ photoSection.required ? "Required" : "(Optional)" }}
                  </span>
                </h2>
                <div [innerHTML]="photoSection.description"></div>
                <div>
                  <div class="files-preview-block">
                    <ng-container *rxLet="productFiles$; let productFiles">
                      <app-files-preview-new
                        *ngIf="productFiles[productIndex]['section' + (productSectionIndex + 1)].length > 0"
                        [files]="productFiles[productIndex]['section' + (productSectionIndex + 1)]"
                        [showRemove]="submitInProgress === false"
                        [showDocumentName]="true"
                        [showCaptions]="false"
                        [disableControls]="submitInProgress"
                        (removeFileByIndex)="
                          removeFileIndex(
                            filesForm
                              .get('productFiles')
                              ['controls'][productIndex].get('section' + (productSectionIndex + 1)),
                            $event
                          )
                        "></app-files-preview-new>
                    </ng-container>
                  </div>

                  <app-form-files-drop-area
                    [formFilesArray]="
                      filesForm
                        .get('productFiles')
                        ['controls'][productIndex].get('section' + (productSectionIndex + 1))
                    "
                    [sectionData]="{productIndex, sectionIndex: productSectionIndex}"
                    [pictureIcon]="'img-claimphotosdocuments'"
                    [smallDropArea]="true"
                    [maxFilesNumber]="1"
                    [docType]="TDocumentType.CustPhoto"></app-form-files-drop-area>
                </div>
              </div>
            </div>
          </div>
          <div class="check-photos-section claim-form__validation-section">
            <div class="claim-form__validation-icon">
              <mat-icon
                class="users__action-icon users__action-icon--doublecheck"
                svgIcon="doublecheck"></mat-icon>
            </div>
            <div class="claim-form__validation-text">
              Please check all photos before sending them to us.
              <b>If they don’t meet the above criteria,</b>
              we will need to request more, which will delay the processing of your claim.
            </div>
          </div>
        </div>
        <app-wizard-step-buttons
          [form]="form"
          [currentStep]="currentStep"
          [nextButtonShow]="
            currentStep.step !== steps.Products || form.get('coveredProducts')['controls'].length > 0
          "
          [nextButtonDisabled]="
            currentStep.step === steps.Products
              ? coveredProductsAreInvalid()
              : (currentStep.formGroupName && !form.get(currentStep.formGroupName).valid) ||
                nextButtonDisabled
          "
          [submitInProgress]="submitInProgress"
          [submitButtonText]="'SUBMIT CLAIM'"
          [submitButtonPosition]="'right'"
          [disableSubmitButton]="form.invalid || filesForm.invalid"
          (prevStep)="prevStep()"
          (nextStep)="nextStep()"
          (submitForm)="submitForm()">
          <ng-container
            *ngIf="
              currentStep.step === steps.Products &&
              form.get('coveredProducts').value.length > 0 &&
              (showAddNewProduct$ | async)
            ">
            <button
              type="button"
              mat-flat-button
              color="primary"
              (click)="openAddProductDialog()"
              [disabled]="!form.get('coveredProducts')['controls'][currentSubStep].valid">
              Add another product
            </button>
          </ng-container>
          <ng-container
            sizeError
            *ngIf="currentStep.step === steps.PhotosAndDocuments">
            <app-files-total-size-error
              class="register-claim__size-error"
              [filesForm]="filesForm"></app-files-total-size-error>
          </ng-container>
        </app-wizard-step-buttons>
      </form>
    </div>
  </div>
  <div
    class="claim-loader__wrapper"
    *ngIf="loading$ | async">
    <app-loader class="claim-loader"></app-loader>
  </div>
</app-side-dialog-animation>
