import { Injectable } from '@angular/core';
import { finalize, switchMap, take } from 'rxjs/operators';

import { ClaimSearchRequest } from '@core/models/search/search-request.model';
import { BaseStore } from '@core/store/_base/base.store';
import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';

import { StoreState } from './my-claims.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;
import { AppConstants } from '@core/constants/app.constants';

import { MyClaimApiService } from '../../api/my-claim-api.service';

@Injectable()
export class MyClaimsStore extends BaseStore<IStoreState> {
  constructor(private readonly myClaimApiService: MyClaimApiService) {
    super(initialState);
  }

  loadData(lazy = false): void {
    this.updateState({ loading: true });
    this.select$('searchRequest')
      .pipe(
        take(1),
        switchMap(searchRequest => this.myClaimApiService.all(searchRequest)),
        finalize(() => this.updateState({ loading: false })),
      )
      .subscribe(({ items, totalCount, page, pageCount }) => {
        const claims = this.get('claims');
        items = items.map(item => ({
          ...item,
          claimStageTitle: AppConstants.claimStages[item.claimStage],
        }));
        items = lazy ? [...claims, ...items] : items;
        this.updateState({
          claims: items || [],
          pagination: {
            totalCount,
            page,
            pageCount,
          },
        });
      });
  }

  updatePagination(page: number, pageSize?: number, lazy = false): void {
    const searchRequest = this.get('searchRequest');
    const pagination = this.get('pagination');
    if (page > 0 && page <= pagination.pageCount) {
      this.updateState({
        searchRequest: {
          ...searchRequest,
          page,
          pageSize: pageSize || searchRequest.pageSize,
        },
      });
      this.loadData(lazy);
    }
  }

  updatePageSize(pageSize: number): void {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        pageSize,
      },
    });
    this.loadData();
  }

  updateSearches(searchString: string): void {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        page: 1,
        searches: [searchString],
      },
    });
    this.loadData();
  }

  updateOrderBy(orderBy: GridOrderByModel[]): void {
    const storeSearchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...storeSearchRequest,
        orderBy,
      },
    });
    this.loadData();
  }

  updateSearchRequest(searchRequest: Partial<ClaimSearchRequest>): void {
    const storeSearchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...storeSearchRequest,
        ...searchRequest,
        page: 1,
      },
    });
  }

  checkAll(isChecked: boolean): void {
    const claims = this.get('claims');
    claims.forEach(item => (item.isChecked = isChecked));
    this.updateState({
      claims: [...claims],
    });
  }

  checkItem(checkedItem: any): void {
    const claims = this.get('claims');
    claims.forEach(item => {
      if (checkedItem.id === item.id) {
        item.isChecked = checkedItem.isChecked;
      }
    });
    this.updateState({
      claims: [...claims],
    });
  }

  clearSearchRequest(): void {
    this.updateSearchRequest(initialState.searchRequest);
  }
}
