import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { map, startWith } from 'rxjs/operators';

import { TDocumentType } from '@core/enums/document-type';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { FilesCarouselComponent } from '@shared/modules/files/components/files-carousel/files-carousel.component';
import { FilesPreviewNewComponent } from '@shared/modules/files/components/files-preview-new/files-preview-new.component';
import { FormFilesDropAreaComponent } from '@shared/standalone/file-controls/form-files-drop-area/form-files-drop-area.component';
import { FilesTotalSizeErrorComponent } from '@shared/standalone/files-total-size-error/files-total-size-error.component';

import { RegisterFppClaimStore } from '../../store/register-fpp-claim.store';

interface IFppClaimFilesStore {
  receiptText: string;
}

@Component({
  selector: 'app-fpp-claim-files',
  templateUrl: './fpp-claim-files.component.html',
  styleUrls: ['./fpp-claim-files.component.scss'],
  standalone: true,
  providers: [RxState],
  imports: [
    FormFilesDropAreaComponent,
    MatButtonModule,
    PushPipe,
    MatIconModule,
    NgIf,
    NgForOf,

    LetDirective,
    FilesTotalSizeErrorComponent,
    FilesPreviewNewComponent,
    FilesCarouselComponent,
    LoadingButtonDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FppClaimFilesComponent {
  rootForm = this.store.getForm();
  filesForm = this.store.getFilesForm();
  TDocumentType = TDocumentType;
  receiptText$ = this.state.select('receiptText');
  isLoadingReceiptFiles$ = this.store.select$('isLoadingReceiptFiles');
  submitInProgress$ = this.store.select$('submitInProgress');
  fileSizeError$ = this.filesForm.statusChanges.pipe(
    map(formStatus => {
      if (formStatus === 'INVALID' && !!this.filesForm.getError('sizeError')) {
        return `Total size of all files should be less than ${
          this.filesForm.getError('sizeError').maxFilesSize / (1000 * 1000)
        } MB.`;
      } else {
        return '';
      }
    }),
  );

  receiptFiles$ = this.filesForm.get('receiptFiles').valueChanges.pipe(
    startWith(this.filesForm.get('receiptFiles').value),
  );
  productFiles$ = this.filesForm
    .get('productFiles')
    .valueChanges.pipe(startWith(this.filesForm.get('productFiles').value));

  productsForm = this.store.getForm('coveredProducts');
  productsNav$ = this.store.wizardNavigationStore
    .select$('navigationList')
    .pipe(map(navigationList => navigationList.find(navItem => navItem.id === 'products').children));

  photoSections: {title: string; required: boolean; description: string}[] = [
    {
      title: 'Photo 1',
      required: true,
      description:
        '<div class="register-consumer-claim__files-preview-requirement section">Show the entire damaged piece of furniture – '
        + 'stand far enough away to get the whole piece in the photo but close enough for it to fill the screen. '
        + 'Ideally, this photo should show the location of the damage.</div>',
    },
    {
      title: 'Photo 2',
      required: true,
      description:
        '<div class="register-consumer-claim__files-preview-requirement section">Focus on the area of furniture where the '
        + 'damage is located. For example, if it is a stain on a seat cushion, the photo should show the entire seat '
        + 'cushion. If it is a scratch on one leaf of a dining room table, it should show the entire leaf with the '
        + 'scratch on it.</div>',
    },
    {
      title: 'Photo 3',
      required: true,
      description:
        '<div class="register-consumer-claim__files-preview-requirement section">Take a close up of the damage itself. '
        + 'It should be close enough to show the detail of the damage but not so close that the image is out of focus. '
        + 'Please consider using a ruler or other object to provide scale.</div>',
    },
    {
      title: 'Photo 4',
      required: false,
      description:
        '<div class="register-consumer-claim__files-preview-requirement section">Provide a photo of the tags on '
        + 'the furniture showing the SKU number and the serial number.</div>\n'
        + '     <div class="register-consumer-claim__files-preview-requirement section"><strong><u>PLEASE READ ALL OF THIS BEFORE '
        + 'DECIDING IF IT IS SOMETHING YOU CAN COMFORTABLY DO.</u></strong></div>\n'
        + '     <div class="register-consumer-claim__files-preview-requirement section">The tags are often found on the underside or '
        + 'back of furniture. On upholstered furniture it is usually under the seat cushion(s) or under '
        + 'the footrest.</div>\n'
        + '     <div class="register-consumer-claim__files-preview-requirement section"><strong>WE DO NOT EXPECT YOU TO '
        + 'TRY TO MOVE OR TIP FURNITURE UNLESS YOU FEEL IT IS WITHIN YOUR PHYSICAL CAPABILITIES OR YOU HAVE '
        + 'SUITABLE HELP.</strong></div>\n'
        + '     <div class="register-consumer-claim__files-preview-requirement section">If you have concerns about supplying '
        + 'this, you can skip this photo and we will reach out at a later date to get the information we need.</div>\n',
    },
  ];

  constructor(
    private readonly store: RegisterFppClaimStore,
    private readonly state: RxState<IFppClaimFilesStore>,
    private readonly fb: FormBuilder,
  ) {
    this.state.connect(
      'receiptText',
      this.filesForm.valueChanges.pipe(
        startWith(this.filesForm.value),
        map(filesForm => filesForm.receiptFiles.filter(file => file.isReadOnly)),
        map(uploadedReceiptFiles => {
          if (uploadedReceiptFiles.length === 0) {
            return 'We do not have a receipt for your plan on file, please upload a copy of your receipt.';
          } else {
            return 'The current receipt is what is on file for your protection plan. If the receipt is incorrect for any reason, please upload a new copy of the receipt.';
          }
        }),
      ),
    );
  }

  submitForm(): void {
    this.store.submitForm().subscribe();
  }

  removeFileIndex(filesFormArray: AbstractControl, fileIndex: number): void {
    const updatedFiles = filesFormArray.value.filter(file => fileIndex !== file.index);
    (filesFormArray as FormArray).clear();
    updatedFiles.forEach(file => {
      (filesFormArray as FormArray).push(this.fb.control(file));
    });
  }

  goBack(): void {
    this.store.goToProduct(this.productsForm.value.length - 1);
  }
}
