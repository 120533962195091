import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { StoreState } from '@shared/modules/claim-controls/components/cause-select/store/cause.state';

import { LookupApiService } from '../../../../../../core/api/lookup-api.service';
import { BaseStore } from '../../../../../../core/store/_base/base.store';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;

@Injectable()
export class CauseStore extends BaseStore<IStoreState> {
  constructor(private readonly lookupApiService: LookupApiService) {
    super(initialState);
  }

  loadData() {
    this.updateState({ loading: true });
    this.lookupApiService
      .lookupProblemCause()
      .pipe(finalize(() => this.updateState({ loading: false })))
      .subscribe(items => {
        this.updateState({
          items,
        });
      });
  }
}
