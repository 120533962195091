<app-side-dialog-animation>
  <div class="dialog__header">
    <app-side-dialog-header
      [dialogTitle]="'Plan Details' + (planName ? ' for ' + planName : '')"
      [disableClose]="cancellationInProgress$ | async"
      (closeDialog)="onClose()">
      <ng-container
        controls
        *ngIf="plan$ | async as plan">
        <div [matTooltip]="(planExpired$ | async) ? 'This plan has expired' : null">
          <button
            mat-flat-button
            color="primary"
            class="plan-details__create-new-claim"
            (click)="registerClaim(plan)"
            [disabled]="(canStartClaim$ | async) === false">
            <ng-container>Start New Claim</ng-container>
          </button>
        </div>
        <button
          *ngIf="isAllowedCancelPlan"
          mat-flat-button
          color="primary"
          class="plan-details__create-new-claim"
          appLoadingButton
          [isLoading]="cancellationInProgress$ | async"
          [disabled]="
            plan.planInfo.protectionPlanStatus !== planStatuses.Registered ||
            (cancellationInProgress$ | async)
          "
          (click)="cancelPlan(plan)">
          Cancel Plan
        </button>
        <app-item-details-pagination
          *ngIf="(singleView$ | async) === false"
          [showCount]="false"
          [itemsTitle]="'Plans'"
          [pagination]="plansPagination$ | async"
          [currentItemIndex]="currentPlanIndex$ | async"
          (setPage)="setPage($event)"
          (changeItemIndex)="changeItemIndex($event)"></app-item-details-pagination>
        <div class="plan-details__pdf">
          <a
            *ngIf="plan.planInfo.downloadablePlan"
            [href]="pdfLink$ | async"
            target="_blank">
            <mat-icon
              class="_common-icon--theme"
              svgIcon="pdf"
              title="View Plan"></mat-icon>
          </a>
        </div>
      </ng-container>
    </app-side-dialog-header>
  </div>
  <div
    class="dialog__container"
    *ngIf="plan$ | async as plan">
    <div class="dialog__nav">
      <app-anchor-nav
        [navigationList]="anchorNavigation.list"
        [currentAnchor]="anchorNavigation.currentAnchor"
        (anchorClick)="anchorNavigation.scrollToAnchor($event)"></app-anchor-nav>
    </div>
    <div
      appCustomOverlayScrollbar
      class="dialog__content dialog__content--black">
      <app-cancellation-status
        *ngIf="
          plan.planInfo.cancellationStatus &&
          (plan.planInfo.protectionPlanStatus !== planStatuses.Cancelling ||
            plan.planInfo.protectionPlanStatus === planStatuses.Cancelled)
        "
        [cancellationStatus]="plan.planInfo.cancellationStatus"
        [refundAmount]="plan.planInfo.refundAmount"></app-cancellation-status>
      <div
        id="Consumer"
        class="section">
        <app-plan-dialog-information [plan]="plan"></app-plan-dialog-information>
      </div>
      <div
        id="PlanInformation"
        class="section">
        <app-collapsible-data-block
          headingTitle="Protection Plan"
          [grid]="{ source: [plan.planInfo], columns: planColumns }"></app-collapsible-data-block>
      </div>
      <div
        id="CoveredProducts"
        class="section">
        <app-collapsible-data-block
          headingTitle="Covered Products"
          [grid]="{
            source: plan.coveredProducts,
            columns: coveredProductColumns
          }"></app-collapsible-data-block>
      </div>

      <div
        id="PlanDocuments"
        class="section">
        <div class="files-slider">
          <app-collapsible-data-block headingTitle="Photos/Documents">
            <ng-container *ngIf="(filesLoading$ | async) === false">
              <ng-container *ngIf="(filesLength$ | async) === 0">
                <span>No files</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="filesLoading$ | async">
              <mat-spinner
                color="primary"
                [diameter]="50"></mat-spinner>
            </ng-container>
            <ng-container *ngIf="(filesLength$ | async) > 0">
              <app-documents-filter
                [files]="files$ | async"
                (docTypeFilterChanged)="docTypeFilterChanged($event)"></app-documents-filter>

              <app-files-preview-new
                *ngIf="(filesLength$ | async) > 0"
                [files]="files$ | async | filterDocumentsByTypes: docTypeFilter"
                [sectionTitle]="null"
                [showCaptions]="false"
                [showRemove]="false"
                [showDocumentName]="true"
                (allFilesLoaded)="allFilesLoaded()"></app-files-preview-new>
            </ng-container>
          </app-collapsible-data-block>
        </div>
        <app-files-for-print [files]="files$ | async"></app-files-for-print>
      </div>

      <div
        id="PurchaseReceipt"
        class="section"
        *ngIf="(isReceiptUploaded$ | async) === false">
        <div class="files-slider">
          <app-collapsible-data-block headingTitle="Purchase Receipt">
            <p class="help-text">{{ receiptText }}</p>
            <div class="files-drop-area__drop-area-list">
              <div
                class="files-drop-area__drop-area"
                *rxFor="let dropArea of dropAreasList">
                <p class="files-drop-area__drop-area-header">{{ dropArea.header }}</p>
                <app-form-files-drop-area
                  [formFilesArray]="filesForm"
                  [labelDescription]="dropArea.labelDescription"
                  [labelDescriptionForMobile]="dropArea.labelDescriptionForMobile"
                  [multiple]="dropArea.maxFilesNumber === null"
                  [pictureIcon]="dropArea.svgIcon"
                  [maxFilesNumber]="dropArea.maxFilesNumber"
                  [docType]="dropArea.docType"
                  [oneFileErrorMessage]="dropArea.errorMessage"></app-form-files-drop-area>
              </div>
            </div>
            <app-files-preview-new
              [files]="filesForm.value"
              [showRemove]="true"
              [showDocumentName]="true"
              [disableControls]="filesLoading$ | async"
              (removeFileByIndex)="removeFiles($event)"></app-files-preview-new>

            <app-files-total-size-error [filesForm]="filesForm"></app-files-total-size-error>
            <button
              mat-flat-button
              color="primary"
              (click)="uploadReceiptFiles()"
              [disabled]="filesForm.invalid">
              Upload Files
            </button>
          </app-collapsible-data-block>
        </div>
      </div>
    </div>
  </div>
  <app-side-dialog-loader
    *ngIf="(loading$ | async) || (receiptFilesLoading$ | async)"></app-side-dialog-loader>
</app-side-dialog-animation>
