import { ChangeDetectionStrategy, Component, EventEmitter, inject,Input, Output } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LookupApiService } from '@core/api/lookup-api.service';
import { IState } from '@core/interfaces/state.interface';
import { FormSelectNewComponent } from '@shared/form/form-select-new/form-select-new.component';

interface ISelectStateState {
  items: IState[];
  loading: boolean;
}

@Component({
  selector: 'app-select-state-new',
  standalone: true,
  templateUrl: './select-state-new.component.html',
  styleUrls: ['./select-state-new.component.scss'],
  providers: [
    RxState,
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultValueAccessor,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormSelectNewComponent,
    LetDirective,
    ReactiveFormsModule,
  ],
})
export class SelectStateNewComponent {
  @Input() label: string;
  @Input() required = false;
  @Input() showOutOfScopeValue = false;
  // eslint-disable-next-line rxjs/finnish
  @Input() set formUpdated(formUpdated$: Observable<void>) {
    this.state.connect('items', formUpdated$, ({ items }) => [...items]);
  }

  @Output()
    itemSelected = new EventEmitter<IState>();

  ngControl = inject(NgControl, {
    optional: true,
    self: true,
  });

  vm$ = this.state.select();

  constructor(
    private readonly lookupApiService: LookupApiService,
    private readonly state: RxState<ISelectStateState>,
  ) {
    this.state.set({
      loading: true,
      items: [],
    });
    this.lookupApiService
      .lookupStates()
      .pipe(
        finalize(() => {
          this.state.set({
            loading: false,
          });
        }),
      )
      .subscribe(states => {
        this.state.set({
          items: states,
        });
      });
  }
}
