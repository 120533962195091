import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { WizardPortalService } from '@consumer-core/services/wizard-portal.service';
import { RegisterPlanStore } from '@consumer-core/stores/register-plan/register-plan.store';

import { AppConstants } from '@core/constants/app.constants';
import {
  LabelValueItemComponent,
} from '@shared/components/label-value-item/label-value-item.component';
import { ReadonlyControlDirective } from '@shared/directives/readonly-control.directive';
import { PipesModule } from '@shared/pipes/pipes.module';

import { FormDateNewComponent } from '../../../../../shared/components/form-controls/form-date-new/form-date-new.component';
import { FormInputNewComponent } from '../../../../../shared/components/form-controls/form-input-new/form-input-new.component';
import { LabelValueComponent } from '../../../../../shared/components/label-value/label-value.component';
import { SubmitBtnDirective } from '../../../../../shared/directives/submit-btn.directive';

@Component({
  selector: 'app-plan-purchase-details-mobile',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormInputNewComponent,
    FormDateNewComponent,
    PortalModule,
    MatButtonModule,
    SubmitBtnDirective,
    ReadonlyControlDirective,
    LabelValueItemComponent,
    LabelValueComponent,
    PipesModule,
  ],
  templateUrl: './plan-purchase-details-mobile.component.html',
  styleUrls: ['./plan-purchase-details-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanPurchaseDetailsMobileComponent {
  minDate = AppConstants.minDateDatepicker;
  today = new Date();
  consumerPlanForm = this.store.form.get('consumerPlan');

  @ViewChild(CdkPortal) set actionsPortal(actionsPortal: CdkPortal) {
    this.wizardPortalService.setActionsPortal(actionsPortal);
  }

  constructor(
    private readonly store: RegisterPlanStore,
    private readonly wizardPortalService: WizardPortalService,
  ) {}

  goToConsumerInfo(isInvalid: boolean): void {
    if (isInvalid) {
      this.consumerPlanForm.markAllAsTouched();
      return;
    }
    this.store.wizardNavigationStore.setStep('contactInfo');
  }
}
