import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  QueryList,
} from '@angular/core';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { mergeAll } from 'rxjs';
import { combineLatestWith, map, switchMap } from 'rxjs/operators';

import { ExpandableCardComponent } from '../expandable-cards/expandable-card/expandable-card.component';
import { SectionHeaderWithActionComponent } from '../section-header-with-action/section-header-with-action.component';

interface IExpandableCardGroupState {
  panelsClosed: boolean;
  panelToggleButtonLabel: string;
  expansionPanels: ExpandableCardComponent[];
}

@Component({
  selector: 'app-expandable-card-group',
  standalone: true,
  imports: [CommonModule, SectionHeaderWithActionComponent, LetDirective],
  templateUrl: './expandable-card-group.component.html',
  styleUrls: ['./expandable-card-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RxState,
  ],
})
export class ExpandableCardGroupComponent {
  @Input() sectionTitle: string;

  vm$ = this.state.select();

  @ContentChildren(
    forwardRef(() => ExpandableCardComponent), { descendants: true },
  ) set expansionPanels(expansionPanels: QueryList<ExpandableCardComponent>) {
    this.state.set({
      expansionPanels: [...expansionPanels],
    });
  }

  constructor(
    private readonly state: RxState<IExpandableCardGroupState>,
  ) {
    this.state.set({
      panelsClosed: true,
    });
    this.state.connect('panelsClosed', this.state.select('expansionPanels').pipe(
      switchMap(expansionPanels => expansionPanels.map(panel => panel.panelToggled$)),
      mergeAll(),
    ), ({ expansionPanels }) => expansionPanels.map(panel => panel.expansionPanel.expanded).every(opened => !opened));

    this.state.connect('panelToggleButtonLabel', this.state.select('panelsClosed').pipe(
      combineLatestWith(this.state.select('expansionPanels')),
      map(([panelsClosed, expansionPanels]) => {
        if (expansionPanels.length === 0) {
          return null;
        } else {
          return panelsClosed ? 'Expand All' : 'Collapse All';
        }
      }),
    ));
  }

  toggleAll(): void {
    const { panelsClosed, expansionPanels } = this.state.get();
    expansionPanels.forEach(panel =>
      panelsClosed ? panel.expansionPanel.open() : panel.expansionPanel.close(),
    );
  }
}
