import { Injectable } from '@angular/core';

import { BaseStore } from '@core/store/_base/base.store';

import { StoreState } from './five9-chat.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;

@Injectable({
  providedIn: 'root',
})
export class Five9ChatStore extends BaseStore<IStoreState> {
  constructor() {
    super(initialState);
  }

  showAboveFooterChatBtn(): void {
    this.updateState({
      showAboveFooter: true,
    });
  }

  hideAboveFooterChatBtn(): void {
    this.updateState({
      showAboveFooter: false,
    });
  }

  openFive9Chat(): void {
    document.getElementById('five9-popout-button').click();
  }
}
