<div>
  <div class="plan-address__note-title">
    <p class="plan-address__title">Home Address</p>
    <p>* Home Address is where regular mail is delivered, such as letters or checks.</p>
  </div>
  <app-address-form-mobile [form]="consumerAddressForm"></app-address-form-mobile>
  <div class="plan-address__note-title">
    <p class="plan-address__title plan-address__title_sub">Location of Furniture Requiring Service</p>
    <p>* Location of Furniture Requiring Service is where the furniture is located, and where a technician might be sent.</p>
    <mat-slide-toggle [formControl]="isServiceAddressSameControl"
                      class="plan-address__switch">Same as home address</mat-slide-toggle>
  </div>
  <app-address-form-mobile *ngIf="!isServiceAddressSameControl.value" [form]="serviceAddressForm"></app-address-form-mobile>
  <ng-container *cdkPortal>
    <button [class._common-button_submit-disabled]="consumerAddressForm.invalid || (isServiceAddressSameControl.value ? false : serviceAddressForm.invalid)"
            mat-flat-button
            appSubmitBtn
            (click)="goToNextStep(consumerAddressForm.invalid || (isServiceAddressSameControl.value ? false : serviceAddressForm.invalid))">Continue</button>
  </ng-container>
</div>
