<mat-card class="plan-card"
          [class.plan-card_disabled]="disabled">
  <mat-card-header>
    <mat-card-title>{{plan.consumerPlanName | appEmptyValue}}</mat-card-title>
    <mat-card-subtitle>{{plan.parentPlanName | appEmptyValue}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="plan-card__description">
      <div class="plan-card__data-item">
        Effective:
        <p class="plan-card__data-value">{{plan.deliveryDate | dateCustomFormat | appEmptyValue}}</p>
      </div>
      <div class="plan-card__data-item">
        Expires:
        <p class="plan-card__data-value">{{plan.expirationDate | dateCustomFormat | appEmptyValue}}</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
