import { importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, TitleStrategy } from '@angular/router';

import { CoreModule } from '@core/core.module';
import { MainHub } from '@core/services/hubs/main.hub';
import { TenantNameResolverService } from '@core/services/tenant-name-resolver.service';
import { PipesModule } from '@shared/pipes/pipes.module';

import { ConsumerClaimService } from '../app/core/services/consumer-claim.service';
import { APP_ROUTES } from './app-mobile.routes';
import { APP_MATERIAL3_PROVIDERS } from './core/providers/material3';
import { ConsumerMobileMainHub } from './core/services/consumer-mobile-main.hub';
import { TemplatePageTitleStrategy } from './core/template-page-title-strategy';

export const APP_MOBILE_APP = {
  providers: [
    importProvidersFrom(
      CoreModule,
      PipesModule,
    ),
    provideRouter(
      APP_ROUTES,
      // withPreloading(PreloadAllModules),
      // withDebugTracing(),
    ),
    provideAnimations(),
    ...APP_MATERIAL3_PROVIDERS,
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
    {
      provide: MainHub,
      useClass: ConsumerMobileMainHub,
    },
    ConsumerClaimService,
    TenantNameResolverService,
  ],
};
