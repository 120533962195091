<ng-container *rxLet="vm$; let vm">
  <div class="fpp-claim-incident-review">
    <p class="fpp-claim-incident-review__title">{{vm.productName}}</p>
    <app-expandable-card-group sectionTitle="Incident Details">
      <app-expandable-card cardTitle="Product Incident">
        <app-claim-wizard-incident-card [product]="vm.product"></app-claim-wizard-incident-card>
      </app-expandable-card>
      <app-expandable-card cardTitle="Files">
        <app-files-list-mobile [files]="vm.files"></app-files-list-mobile>
      </app-expandable-card>
    </app-expandable-card-group>
    <ng-container *cdkPortal>
      <div class="fpp-claim-incident-review__footer">
        <div *ngIf="vm.isLastProduct">
          <button mat-stroked-button
                  (click)="addAnotherProduct()">Add Another Product</button>
        </div>
        <button mat-flat-button
                (click)="goNext()">{{vm.isLastProduct ? 'Continue' : 'Next Product'}}</button>
      </div>
    </ng-container>
  </div>

</ng-container>
