<div class="dialog dialog--short">
  <app-base-header headerTitle="Confirm Cancellation" (closeClick)="dialogRef.close()"></app-base-header>
  <div class="dialog__content">
    <div class="mat-typography" *ngIf="refund">
      <p>Given the current status of your plan, if refund today {{today | date: 'M/dd/yyyy'}}, the refund amount would be ${{refund.refundAmount}}.</p>
      <p>This is just an estimate, not an approved refund amount, and is subject to change. Changes that affect the outcome include but are not limited to claims against the plan, and the date the refund is submitted.</p>
      <p>Are you sure you would like to cancel the following plan?</p>
      <div class="cancel-plan__details">
        <p><span>Plan Number:</span><b>{{plan.consumerPlanName ? plan.consumerPlanName : '-'}}</b></p>
        <p><span>Description:</span><b>{{plan.parentPlanName ?plan.parentPlanName : '-'}}</b></p>
        <p><span>Purchase:</span><b>{{plan.purchaseDate ? (plan.purchaseDate | date: 'M/dd/yyyy') : '-'}}</b></p>
        <p><span>Delivery:</span><b>{{plan.deliveryDate ? (plan.deliveryDate | date: 'M/dd/yyyy') : '-'}}</b></p>
        <p><span>Plan Price:</span><b>{{plan.planPrice ? '$' + plan.planPrice : '-' }}</b></p>
        <p><span>Furniture Price:</span><b>{{plan.purchasePrice ? '$' + plan.purchasePrice : '-'}}</b></p>
      </div>
    </div>
    <div class="add-note">
      <label for="noteInput">Reason for cancellation</label>
      <textarea matInput trim="blur" [(ngModel)]="cancellationReason" maxlength="4000" id="noteInput"></textarea>
    </div>
    <div class="inline-buttons inline-buttons--right">
      <button [mat-dialog-close]="false" type="button" class="inline-buttons__button _common-button--rounded"
              mat-flat-button color="primary">No, don't cancel</button>
      <button (click)="cancelPlan()" type="button" class="inline-buttons__button _common-button--rounded"
              mat-flat-button color="primary"
              appLoadingButton
              [isLoading]="cancellationInProgress"
              [disabled]="cancellationReason.trim().length === 0">
        Yes, cancel Plan
      </button>
    </div>
  </div>
</div>
