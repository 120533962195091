import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

import { ProductInterface } from '@core/interfaces/claims/product.interface';
import { Product } from '@core/interfaces/quick-search/search-results.interface';
import { ISelectProductConfig } from '@shared/modules/claim-dialogs/components/select-product/select-product.component';

@Component({
  selector: 'app-select-product-dialog',
  templateUrl: './select-product-dialog.component.html',
  styleUrls: ['./select-product-dialog.component.scss'],
})
export class SelectProductDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SelectProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {products: ProductInterface[]; selectedProductIds: string[]; config: ISelectProductConfig},
  ) {}

  ngOnInit() {}

  newProduct() {
    this.dialogRef.close({
      newProduct: true,
    });
  }

  selectProducts(selectedProducts: Product[]) {
    this.dialogRef.close({
      newProduct: false,
      selectedProducts,
    });
  }
}
