<div class="photo-dialog-mobile" *rxLet="vm$; let vm">
  <button mat-dialog-close class="photo-dialog-mobile__close-btn" mat-icon-button>
    <mat-icon class="photo-dialog-mobile__close-icon">close</mat-icon>
  </button>
  <mat-dialog-content class="photo-dialog-mobile__content">
    <img class="photo-dialog-mobile__image"
         [src]="vm.activeFile.viewerUrl"
         [alt]="vm.activeFile.fileName">
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="photo-dialog-mobile__buttons">
      <button mat-button [disabled]="vm.prevDisabled" (click)="prevPhoto()">Previous</button>
      <button mat-button [disabled]="vm.nextDisabled" (click)="nextPhoto()">Next</button>
    </div>
  </mat-dialog-actions>
</div>
