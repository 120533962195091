import { Injectable, Type } from '@angular/core';
import { IWizardNavigation } from '@consumer-core/interfaces/wizard-navigation.interface';

import { StoreState } from './wizard-navigation.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;

import { Observable } from 'rxjs';

import { BaseStore } from '@core/store/_base/base.store';

@Injectable()
export class WizardNavigationStore<TNavigationStep, TNavigationChildrenStep = unknown> extends
  BaseStore<IStoreState<TNavigationStep, TNavigationChildrenStep>> {

  constructor() {
    super(initialState as IStoreState<TNavigationStep, TNavigationChildrenStep>);
  }

  setNavigationList(navigationList: IWizardNavigation<TNavigationStep, TNavigationChildrenStep>[]): void {
    this.updateState({
      navigationList,
    });
  }

  setStep(activeStep: TNavigationStep | TNavigationChildrenStep): void {
    const activeComponent = this._getActiveComponent(activeStep);
    this.updateState((state) => ({
      ...state,
      activeStep,
      activeComponent,
    }));
  }

  stepBack(): void {
    const navigationList = this.get('navigationList');
    const currentStep = this.get('activeStep');
    const activeStep = navigationList[navigationList.findIndex(
      navItem => navItem.id === currentStep,
    ) - 1].id;
    this.setStep(activeStep);
  }

  selectActiveComponent$(): Observable<Type<unknown>> {
    return this.select$('activeComponent');
  }

  selectActiveStep$(): Observable<TNavigationStep | TNavigationChildrenStep> {
    return this.select$('activeStep');
  }

  getActiveStep(): TNavigationStep | TNavigationChildrenStep {
    return this.get('activeStep');
  }

  private _getActiveComponent(activeStep: TNavigationStep | TNavigationChildrenStep): any {
    const stepComponents = this.get('navigationList').map(navItem => [
      {
        id: navItem.id,
        component: navItem.component,
      }, ...(navItem.children || []).map(child => ({
        id: child.id,
        component: child.component,
      })),
    ]).flat();
    return stepComponents.find(navItem => navItem.id === activeStep).component;
  }
}
