import {
  AfterViewInit,
  Directive,
  Input, Self,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appMat3ScrollLoadingOverlay]',
  standalone: true,
})
export class Mat3ScrollLoadingOverlayDirective implements AfterViewInit {
  @Input() set loading(loading: boolean) {
    this.updateOverlayHeight();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private panel!: HTMLElement;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private destroyed$ = new Subject<boolean>();

  constructor(@Self() private matSelect: MatSelect) {}

  ngAfterViewInit(): void {
    this.matSelect.openedChange.pipe(
      takeUntil(this.destroyed$),
    ).subscribe((opened) => {
      if (opened) {
        this.panel = this.matSelect.panel.nativeElement;
        this.updateOverlayHeight();
      }
    });
  }

  updateOverlayHeight(): void {
    if (this.panel) {
      this.panel.style.setProperty('--scroll-loading-overlay-height', `${this.panel.scrollHeight}px`);
    }
  }

}
