import { CdkPortal } from '@angular/cdk/portal';
import { Directive, inject, ViewChild } from '@angular/core';
import { WizardPortalService } from '@consumer-core/services/wizard-portal.service';

@Directive({
  selector: '[appBaseWizardPage]',
  standalone: true,
})
export class BaseWizardPageComponent {
  wizardPortalService = inject(WizardPortalService);

  @ViewChild(CdkPortal) set actionsPortal(actionsPortal: CdkPortal) {
    this.setActionsPortal(actionsPortal);
  }

  setActionsPortal(actionsPortal: CdkPortal): void {
    if (actionsPortal) {
      this.wizardPortalService.setActionsPortal(actionsPortal);
    }
  }
}
