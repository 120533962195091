<div
  *ngIf="!readonly"
  [formGroup]="form">
  <th-form-field-block>
    <th-form-field [size]="TCommonFieldSize.ThreeQuarters">
      <app-form-textarea
        [formControl]="form.get(FieldsNames.ProductDescription)"
        [required]="true"
        [tooltipText]="
          'What is the piece of furniture you are making a claim on? E.g. “fabric sofa”, “dining table”, “leather recliner with power”.'
        "
        [label]="'Describe the Product'"></app-form-textarea>
    </th-form-field>
  </th-form-field-block>
  <th-form-field-block>
    <th-form-field [size]="TCommonFieldSize.Quarter">
      <app-surface-type-select
        [required]="true"
        [formControl]="form.get(FieldsNames.SurfaceTypeId)"></app-surface-type-select>
    </th-form-field>
    <th-form-field [size]="TCommonFieldSize.Quarter">
      <app-form-date
        [formControl]="form.get(FieldsNames.DeliveryDate)"
        [autocomplete]="'section-consumer-product delivery-date'"
        [label]="'Delivery Date'"
        [tooltipText]="
          'If you received your furniture in multiple deliveries, please put the earliest delivery date.'
        "
        [required]="true"
        [readonly]="true"
        [max]="today"></app-form-date>
    </th-form-field>
    <th-form-field [size]="TCommonFieldSize.Quarter">
      <app-form-input
        [autocomplete]="'section-consumer-product unit-price'"
        [label]="'Unit Price'"
        [formControl]="form.get(FieldsNames.UnitPrice)"
        [tooltipText]="
          'The price of the furniture as shown on your receipt. If the piece does not have a price as it was part of a package, please flip the Part of Package indicator to on and put the full package price in this field.'
        "
        [required]="true"
        [dataType]="'price'"></app-form-input>
    </th-form-field>
  </th-form-field-block>
  <th-form-field-block>
    <th-form-field [size]="TCommonFieldSize.ThreeQuarters">
      <div class="field-switch-wrapper">
        <app-switch
          type="checkbox"
          class="field field-switch"
          title="Part of Package"
          [formControl]="form.get(FieldsNames.IsPartOfPackage)"></app-switch>
        <div class="tooltip tooltip-button">
          <mat-icon
            class="tooltip__icon _common-icon--theme"
            svgIcon="help_outline"></mat-icon>
          <span class="tooltip__text">
            If your receipt shows multiple pieces of furniture as part of a package, for example 'living room
            package', with a single package price then please flip the toggle
          </span>
        </div>
      </div>
    </th-form-field>
  </th-form-field-block>
</div>
