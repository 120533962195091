import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { FormInputNewComponent } from '../form-controls/form-input-new/form-input-new.component';
import { SelectStateNewComponent } from '../select-state-new/select-state-new.component';

@Component({
  selector: 'app-address-form-mobile',
  standalone: true,
  templateUrl: './address-form-mobile.component.html',
  styleUrls: ['./address-form-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormInputNewComponent,
    ReactiveFormsModule,
    SelectStateNewComponent,
  ],
})
export class AddressFormMobileComponent {
  @Input() form: FormGroup;
  @Input() blocked: boolean;

  constructor() {}
}
