import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RxIf } from '@rx-angular/template/if';

import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';

import { BaseWizardPageComponent } from '../../components/base-wizard-page/base-wizard-page.component';

@Component({
  selector: 'app-plan-finish-mobile',
  standalone: true,
  imports: [CommonModule, RxIf, PortalModule, MatButtonModule],
  templateUrl: './plan-finish-mobile.component.html',
  styleUrls: ['./plan-finish-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanFinishMobileComponent extends BaseWizardPageComponent {

  constructor(
    private readonly dialogState: SideDialogStateService,
  ) {
    super();
  }

  closeDialog(): void {
    this.dialogState.close();
  }
}
