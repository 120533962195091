<div>
  <h2 class="section__title">Getting started</h2>
  <mat-icon class="getting-started__icon" svgIcon="couch"></mat-icon>
  <div class="getting-started__tile">
    <p>To enable us to process your claim, we need you to provide information including a description of the issue, how and when it happened, a copy of your purchase receipt -- if you did not provide the receipt to us when you registered your plan -- and photos of the problem. Please be ready to provide this information prior to beginning the claim process.</p>
    <br/>
    <p><strong>If you are making a claim on more than one piece of furniture,</strong> enter the details for each piece separately.</p>
    <br/>
    <p><strong>If the furniture requiring service is at multiple different locations,</strong> please create a separate claim for each location.</p>
  </div>
  <div class="form-step-buttons">
    <button type="button"
            id="button-next"
            (click)="goToConsumer()"
            mat-flat-button color="primary">Next</button>
  </div>
</div>
