<div *ngIf="readonly === true; else regularSelect">
  <div class="field__label">Time</div>
  <div class="field__text">{{selectedItemName$ | push | appEmptyValue}}</div>
</div>
<ng-template #regularSelect>
  <app-form-select-new [formControl]="formControl"
                   [data]="itemsList$ | push"
                   [label]="'Time'"></app-form-select-new>
</ng-template>




