<div class="section-header">
  <h3 class="section-header__title">{{sectionTitle}}</h3>
  <ng-container *ngIf="actionsTemplate; else defaultActions">
    <ng-container [ngTemplateOutlet]="actionsTemplate"></ng-container>
  </ng-container>
  <ng-template #defaultActions>
    <a *rxIf="routerLink; else actionButton" [routerLink]="routerLink">{{label}}</a>
    <ng-template #actionButton>
      <button *ngIf="label" mat-button (click)="action$.next(null)" type="button" class="link-button">{{label}}</button>
    </ng-template>
  </ng-template>
</div>
