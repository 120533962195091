import { Injectable } from '@angular/core';

import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { MyProtectionPlanStatus } from '@core/enums/plan/my-protection-plan-status.enum';
import { Claim } from '@core/interfaces/claims/claim.interface';
import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { IPurchaseSocket } from '@core/interfaces/purchase/purchase.interface';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

import { InquireClaimComponent } from '../../pages/my-claims/shared/modules/inquire-claim/inquire-claim.component';
import { RegisterClaimComponent } from '../../pages/register-claim/register-claim.component';
import { RegisterFppClaimComponent } from '../../pages/register-fpp-claim/register-fpp-claim.component';

@Injectable({
  providedIn: 'root',
})
export class ConsumerClaimDialogService {

  constructor(
    private modalDialogService: ModalDialogService,
  ) {}

  openCreateClaimDialog(purchase: IPurchaseSocket) {
    return this.modalDialogService.open(
      {
        title: `Claim for purchase ${purchase.planInfo.id}`,
        data: {
          purchaseId: purchase.planInfo.id,
        },
      },
      RegisterClaimComponent,
      {
        enterAnimationDuration: 0,
        exitAnimationDuration: 0,
        hasBackdrop: false,
        disableClose: true,
      },
    );
  }

  openCreateFppClaimDialog(plan: PlanDetails) {
    if (plan.planInfo.protectionPlanStatus !== MyProtectionPlanStatus.Registered) {
      return;
    }
    return this.modalDialogService.open(
      {
        title: 'Register FPP Claim',
        data: plan,
      },
      RegisterFppClaimComponent,
      DIALOG_CONFIG_CUSTOM_ANIMATION,
    );
  }

  openInquireClaimDialog(claim: Claim) {
    return this.modalDialogService.open({
      title: 'Inquire Claim',
      data: {
        claim,
      },
    }, InquireClaimComponent, {
      width: 'auto',
      disableClose: true,
    });
  }
}
