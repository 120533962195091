import { Portal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { IWizardNavigation } from '@consumer-core/interfaces/wizard-navigation.interface';
import { WizardPortalService } from '@consumer-core/services/wizard-portal.service';
import { WizardNavigationStore } from '@consumer-core/stores/wizard-navigation/wizard-navigation.store';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { mergeWith, of } from 'rxjs';
import { combineLatestWith } from 'rxjs/operators';

import { DialogService } from '@core/services/dialog.service';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import {
  BottomDialogAnimationComponent,
} from '@shared/standalone/bottom-dialog-animation/bottom-dialog-animation.component';

import { TRegisterFppClaimNavigationStep } from '../../../../app/pages/register-fpp-claim/store/register-fpp-claim.state';
import { RegisterFppClaimStore } from '../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import { WizardStore } from '../../../core/stores/wizard.store';
import { HeaderMobileComponent } from '../../../shared/components/header-mobile/header-mobile.component';
import {
  WizardContactInfoMobileComponent,
} from '../../../shared/components/wizard/wizard-contact-info-mobile/wizard-contact-info-mobile.component';
import {
  WIZARD_HOME_ADDRESS_NEXT_STEP,
} from '../../../shared/components/wizard/wizard-home-address-mobile/wizard-home-address.token';
import {
  WizardHomeAddressMobileComponent,
} from '../../../shared/components/wizard/wizard-home-address-mobile/wizard-home-address-mobile.component';
import {
  FppClaimCloseupFilesUploadComponent,
} from './containers/fpp-claim-closeup-files-upload/fpp-claim-closeup-files-upload.component';
import {
  FppClaimDamageFilesUploadComponent,
} from './containers/fpp-claim-damage-files-upload/fpp-claim-damage-files-upload.component';
import {
  FppClaimEntireProductFileUploadComponent,
} from './containers/fpp-claim-entire-product-file-upload/fpp-claim-entire-product-file-upload.component';
import { FppClaimFinishComponent } from './containers/fpp-claim-finish/fpp-claim-finish.component';
import {
  FppClaimIncidentReviewComponent,
} from './containers/fpp-claim-incident-review/fpp-claim-incident-review.component';
import { FppClaimProductMobileComponent } from './containers/fpp-claim-product-mobile/fpp-claim-product-mobile.component';
import { FppClaimReviewComponent } from './containers/fpp-claim-review/fpp-claim-review.component';
import { FppClaimSelectProductComponent } from './containers/fpp-claim-select-product/fpp-claim-select-product.component';
import {
  FppClaimTagFilesUploadComponent,
} from './containers/fpp-claim-tag-files-upload/fpp-claim-tag-files-upload.component';
import { FppClaimUploadReceiptComponent } from './containers/fpp-claim-upload-receipt/fpp-claim-upload-receipt.component';
import {
  RegisterFppClaimGuidanceComponent,
} from './containers/register-fpp-claim-guidance/register-fpp-claim-guidance.component';
import { SelectPlanForClaimComponent } from './containers/select-plan-for-claim/select-plan-for-claim.component';


interface IRegisterConsumerFppClaimMobileComponentState {
  actionsPortal: Portal<any>;
  showBackButton: boolean;
  showCloseButton: boolean;
  progress: number;
  activeComponent: any;
  headerActionsDisabled: boolean;
  backBtnDisabled: boolean;
  showModalActions: boolean;
}

@Component({
  selector: 'app-register-consumer-fpp-claim-mobile',
  standalone: true,
  imports: [
    CommonModule,
    BottomDialogAnimationComponent,
    PushPipe,
    HeaderMobileComponent,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatProgressBarModule,
    MatDialogModule,
    PortalModule,
    LetDirective,
  ],
  templateUrl: './register-consumer-fpp-claim-mobile.component.html',
  styleUrls: ['./register-consumer-fpp-claim-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SideDialogStateService,
    RxState,
    RegisterFppClaimStore,
    WizardNavigationStore,
    WizardPortalService,
    {
      provide: WizardStore,
      useExisting: RegisterFppClaimStore,
    },
    {
      provide: WIZARD_HOME_ADDRESS_NEXT_STEP,
      useValue: 'selectProducts',
    },
  ],
})
export class RegisterConsumerFppClaimMobileComponent {
  vm$ = this.state.select();
  isVisible$ = this.sideDialogStateService.isVisible$;
  dialogData = inject(MAT_DIALOG_DATA);

  private _navigationList: IWizardNavigation<TRegisterFppClaimNavigationStep>[] = [
    {
      id: 'selectPlan',
      title: '',
      valid$: of(true),
      component: SelectPlanForClaimComponent,
    },
    {
      id: 'guidance',
      title: 'Guidance',
      valid$: of(true),
      component: RegisterFppClaimGuidanceComponent,
    },
    {
      id: 'contactInfo',
      title: 'Contact Info',
      valid$: of(true),
      component: WizardContactInfoMobileComponent,
    },
    {
      id: 'homeAddress',
      title: 'Home Address',
      valid$: of(true),
      component: WizardHomeAddressMobileComponent,
    },
    {
      id: 'selectProducts',
      title: 'Contact Info',
      valid$: of(true),
      component: FppClaimSelectProductComponent,
    },
    {
      id: 'product',
      title: 'Product',
      valid$: of(true),
      component: FppClaimProductMobileComponent,
    },
    {
      id: 'entireProductFile',
      title: 'Entire Product File',
      valid$: of(true),
      component: FppClaimEntireProductFileUploadComponent,
    },
    {
      id: 'damageFile',
      title: 'Damage File',
      valid$: of(true),
      component: FppClaimDamageFilesUploadComponent,
    },
    {
      id: 'closeupFile',
      title: 'Closeup File',
      valid$: of(true),
      component: FppClaimCloseupFilesUploadComponent,
    },
    {
      id: 'productTag',
      title: 'Product Tag',
      valid$: of(true),
      component: FppClaimTagFilesUploadComponent,
    },
    {
      id: 'incidentReview',
      title: 'Incident Review',
      valid$: of(true),
      component: FppClaimIncidentReviewComponent,
    },
    {
      id: 'uploadReceipt',
      title: 'Upload Receipt',
      valid$: of(true),
      component: FppClaimUploadReceiptComponent,
    },
    {
      id: 'review',
      title: 'Claim Review',
      valid$: of(true),
      component: FppClaimReviewComponent,
    },
    {
      id: 'finish',
      title: 'Finish',
      valid$: of(true),
      component: FppClaimFinishComponent,
    },
  ];

  constructor(
    private readonly sideDialogStateService: SideDialogStateService,
    private readonly state: RxState<IRegisterConsumerFppClaimMobileComponentState>,
    private readonly matDialogRef: MatDialogRef<RegisterConsumerFppClaimMobileComponent>,
    private readonly store: RegisterFppClaimStore,
    private readonly wizardPortalService: WizardPortalService,
    private readonly dialogService: DialogService,
  ) {
    this.state.set({
      showCloseButton: true,
    });
    const navigationList = this._navigationList;
    let initialStep: TRegisterFppClaimNavigationStep = 'selectPlan';
    if (this.dialogData.data?.planInfo) {
      navigationList.splice(0, 1);
      initialStep = 'guidance';
      this.store.getPlanDetails(this.dialogData.data);
    }
    this.store.wizardNavigationStore.setNavigationList(this._navigationList);
    this.store.wizardNavigationStore.setStep(initialStep);
    this.state.connect('actionsPortal', this.wizardPortalService.actionsPortal$);
    this.state.connect('activeComponent', this.store.wizardNavigationStore.selectActiveComponent$());
    this.state.connect(this.store.wizardNavigationStore.selectActiveStep$().pipe(
      combineLatestWith(this.store.select$('activeProductIndex')),
    ), (oldState, [activeStep, activeProductIndex]) => ({
      ...oldState,
      showBackButton: activeStep !== this._navigationList[0].id && activeStep !== 'finish',
      showCloseButton: activeStep !== 'finish',
      progress: this._calcProgress(activeStep, activeProductIndex),
      showModalActions: activeStep !== 'selectPlan',
    }));
    this.state.connect('backBtnDisabled', this.store.select$('isLoading').pipe(
      mergeWith(this.store.select$('submitInProgress')),
    ));
    this.state.connect('headerActionsDisabled', this.store.select$('submitInProgress'));
    this.store.updateState({
      isMobile: true,
    });
  }

  close(): void {
    this.dialogService.canLeaveDefaultConfirmationObservable(this.store.form, this.store.filesForm.dirty).subscribe(canLeave => {
      if (canLeave) {
        this.sideDialogStateService.close(this.matDialogRef);
      } else {
        return;
      }
    });
  }

  stepBack(): void {
    const activeProductIndex = this.store.get('activeProductIndex');
    if (this.store.wizardNavigationStore.getActiveStep() !== 'product') {
      this.store.wizardNavigationStore.stepBack();
    } else if (activeProductIndex === 0) {
      this.store.wizardNavigationStore.setStep('homeAddress');
    } else {
      this.store.goToProduct(activeProductIndex - 1);
    }
  }

  private _calcProgress(activeStep: TRegisterFppClaimNavigationStep, activeProductIndex: number): number {
    const startSteps = ['selectPlan', 'guidance'];
    const beforeProductSteps = ['contactInfo', 'homeAddress', 'selectProducts'];
    const productSteps = ['product', 'entireProductFile', 'damageFile', 'closeupFile', 'productTag', 'incidentReview'];
    const productsLength = startSteps.includes(activeStep) ? 0 : this.store.getForm('coveredProducts').value.length;
    let activeStepNavIndex;
    if (startSteps.includes(activeStep) || beforeProductSteps.includes(activeStep)) {
      activeStepNavIndex = this._navigationList.findIndex(item => item.id === activeStep);
    } else if (productSteps.includes(activeStep)) {
      activeStepNavIndex = this._navigationList.findIndex(
        item => item.id === activeStep,
      ) + (productSteps.length * activeProductIndex);
    } else {
      activeStepNavIndex = this._navigationList.findIndex(item => item.id === activeStep) + ((productsLength - 1) * productSteps.length);
    }
    const navLength = this._navigationList.length + ((productsLength - 1) * 6);
    return activeStepNavIndex / navLength * 100;
  }
}
