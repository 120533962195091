import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { filter } from 'rxjs';
import { take } from 'rxjs/operators';

import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';

import { RegisterFppClaimStore } from '../../../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import { HeroBannerComponent } from '../../../../../shared/components/hero-banner/hero-banner.component';
import {
  PaginationIndicatorComponent,
} from '../../../../../shared/components/pagination-indicator/pagination-indicator.component';
import {
  BaseWizardPageComponent,
} from '../../../register-plan-consumer-mobile/components/base-wizard-page/base-wizard-page.component';

interface IRegisterFppClaimGuidanceComponentState {
  activeBanner: {
    heroTitle: string;
    heroImage: string;
    messageTitle: string;
    messageBody: string;
  };
  activeIndex: number;
  skipLoading: boolean;
  nextLoading: boolean;
}

@Component({
  selector: 'app-register-fpp-claim-guidance',
  standalone: true,
  imports: [
    CommonModule,
    HeroBannerComponent,
    MatButtonModule,
    PaginationIndicatorComponent,
    LetDirective,
    PortalModule,
    LoadingButtonDirective,
  ],
  templateUrl: './register-fpp-claim-guidance.component.html',
  styleUrls: ['./register-fpp-claim-guidance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RxState,
  ],
})
export class RegisterFppClaimGuidanceComponent extends BaseWizardPageComponent {
  vm$ = this.state.select();

  guidanceBanners = [
    {
      heroTitle: 'Here are few things you will need:',
      heroImage: 'assets/consumer-mobile/guidance-receipt.svg',
      messageTitle: 'Purchase Receipt',
      messageBody: 'If not already provided to Montage, you will need to have a copy of your purchase receipt available.',
    },
    {
      heroTitle: 'Here are few things you will need:',
      heroImage: 'assets/consumer-mobile/guidance-issue.svg',
      messageTitle: 'Description of the Issue',
      messageBody: 'You will need to provide a detailed description of the issue and the date the damage was noticed.',
    },
    {
      heroTitle: 'Here are few things you will need:',
      heroImage: 'assets/consumer-mobile/guidance-photos.svg',
      messageTitle: 'Photos of the Issue',
      // eslint-disable-next-line max-len
      messageBody: 'We will need at least 3 photos of the both the furniture and the damage. Our wizard will guide you through that process.',
    },
  ];

  constructor(
    private readonly state: RxState<IRegisterFppClaimGuidanceComponentState>,
    private readonly store: RegisterFppClaimStore,
  ) {
    super();
    this.state.set({
      activeIndex: 0,
      activeBanner: this.guidanceBanners[0],
    });
  }

  skipGuidance(): void {
    if (this.store.get('isLoading')) {
      this.state.set({
        skipLoading: true,
      });
      this.store.select$('isLoading').pipe(
        filter((isLoading) => !isLoading),
        take(1),
      ).subscribe(() => {
        this.state.set({
          skipLoading: false,
        });
        this.store.wizardNavigationStore.setStep('contactInfo');
      });
    } else {
      this.store.wizardNavigationStore.setStep('contactInfo');
    }
  }

  nextItem(activeIndex: number): void {
    if (activeIndex === this.guidanceBanners.length - 1) {
      if (this.store.get('isLoading')) {
        this.state.set({
          nextLoading: true,
        });
        this.store.select$('isLoading').pipe(
          filter((isLoading) => !isLoading),
          take(1),
        ).subscribe(() => {
          this.state.set({
            nextLoading: false,
          });
          this.store.wizardNavigationStore.setStep('contactInfo');
        });
      } else {
        this.store.wizardNavigationStore.setStep('contactInfo');
      }
    } else {
      const nextIndex = activeIndex + 1;
      this.state.set({
        activeIndex: nextIndex,
        activeBanner: this.guidanceBanners[nextIndex],
      });
    }
  }

  prevItem(activeIndex: number): void {
    const prevIndex = activeIndex - 1;
    this.state.set({
      activeIndex: prevIndex,
      activeBanner: this.guidanceBanners[prevIndex],
    });
  }
}
