<th-form-field-block>
  <th-form-field [size]="TCommonFieldSize.ThreeQuarters">
    <app-form-textarea
      [formControl]="incidentFormControl.get('damageDescription')"
      [required]="true"
      [tooltipText]="
        'Describe the problem, e.g. “coffee stain on the arm of the sofa”, “I spilled nail polish remover on my dining table”, “the power mechanism stopped working on my recliner”.'
      "
      [label]="'Describe the Problem'"></app-form-textarea>
  </th-form-field>
</th-form-field-block>
<app-alert-message
  [fullSize]="true"
  [type]="'warning'"
  [message]="
    'The following drop-down fields are extremely important for adjudicating your claim. Please make sure they are accurate to the best of your ability.'
  "></app-alert-message>
<th-form-field-block>
  <th-form-field [size]="TCommonFieldSize.Quarter">
    <app-cause-select
      [label]="'How Did it Happen'"
      [formControl]="incidentFormControl.get('causeTypeId')"
      [required]="true"></app-cause-select>
  </th-form-field>
  <th-form-field [size]="TCommonFieldSize.Quarter">
    <app-problem-type-select
      [label]="'Reported Damage'"
      [formControl]="incidentFormControl.get('problemTypeId')"
      [required]="true"></app-problem-type-select>
  </th-form-field>
  <th-form-field [size]="TCommonFieldSize.Quarter">
    <app-problem-type-sub-category-select
      [label]="'Reported Damage Subcategory'"
      [formControl]="incidentFormControl.get('problemTypeSubcategoryId')"
      [problemType]="incidentFormControl.get('problemTypeId').value"></app-problem-type-sub-category-select>
  </th-form-field>
</th-form-field-block>
<th-form-field-block>
  <th-form-field [size]="TCommonFieldSize.Quarter">
    <app-form-date
      class="field field--third"
      [formControl]="incidentFormControl.get('dateNoticed')"
      [label]="'Date Noticed'"
      [autocomplete]="'section-purchase purchase-date'"
      [tooltipText]="
        'When did you first notice the problem? (It can be helpful to establish the date by thinking about other events on the day you first noticed it, e.g. “it was the day we went to pick out new carpet”, “it was the day your friend from work came over for dinner”, “it was the night we watched that scary movie”.)'
      "
      [required]="true"
      [readonly]="true"
      [max]="today"
      [min]="dateNoticedMinDate"></app-form-date>
  </th-form-field>
</th-form-field-block>
