import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { MyProtectionPlanStatus } from '@core/enums/plan/my-protection-plan-status.enum';
import { Plan, PlanDetails, Refund } from '@core/interfaces/plan/plan.interface';
import { PromptDialogService } from '@core/services/dialog/prompt-dialog.service';

import { CancelPlanDialogComponent } from '../../shared/modules/consumer-plan-details/containers/cancel-plan-dialog/cancel-plan-dialog.component';
import { MyPlanApiService } from '../api/my-plan-api.service';

@Injectable({
  providedIn: 'root',
})
export class ConsumerPlanService {
  cancellationInProgress$ = new BehaviorSubject<boolean>(false);
  promptDialogService = inject(PromptDialogService);

  constructor(private readonly myPlanApiService: MyPlanApiService, private readonly matDialog: MatDialog) {}

  getMyPlans(page: number = 1) {
    const searchRequest = {
      page,
      pageSize: 24,
      // orderBy: [new GridOrderByModel('registrationDate', false)],
      orderBy: [],
    };
    return this.myPlanApiService.all(searchRequest);
  }

  private get customPageSize(): number {
    const viewportWidth = window.visualViewport.width;
    return viewportWidth > 769 ? 12 : 10;
  }

  filterMyPlans(searches: string[], page: number = 1) {
    const searchRequest = {
      page,
      pageSize: 24,
      orderBy: [],
      searches,
    };
    return this.myPlanApiService.all(searchRequest);
  }

  cancelPlan(planDetails: PlanDetails) {
    if (planDetails.planInfo.protectionPlanStatus !== MyProtectionPlanStatus.Registered) {
      return;
    }
    const plan = planDetails.planInfo;
    this.openMyPlanCancellationModal(plan);
  }

  private openMyPlanCancellationModal(plan: Plan) {
    this.cancellationInProgress$.next(true);
    this.myPlanApiService
      .refund(plan.crmRefId)
      .pipe(
        tap((refund: Refund) => {
          if (refund.errorMessage) {
            this.notifyCancellationError(refund);
          } else {
            this.checkIfPlanHasClaims(plan, refund);
          }
        }),
        finalize(() => {
          this.cancellationInProgress$.next(false);
        }),
      )
      .subscribe();
  }

  private checkIfPlanHasClaims(plan: Plan, refund: Refund): void {
    if (plan.hasClaims) {
      this.promptDialogService.openPrompt({
        message: '<span>This plan has one or more active claims. <br> Are you sure you want to cancel it?</span>',
        buttonNo: 'No',
        buttonYes: 'Yes',
      }).afterClosed().subscribe(result => {
        if (result) {
          this.openCancelPlanDialog(plan, refund);
        }
      });
    } else {
      this.openCancelPlanDialog(plan, refund);
    }
  }

  private openCancelPlanDialog(plan: Plan, refund: Refund) {
    this.matDialog.open(CancelPlanDialogComponent, {
      data: {
        plan,
        refund,
      },
    });
  }

  private notifyCancellationError(refund: Refund): void {
    this.promptDialogService.openNotify({
      message: refund.errorMessage,
    });
  }
}
