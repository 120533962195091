import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RegisterPlanStore } from '@consumer-core/stores/register-plan/register-plan.store';
import { RxState } from '@rx-angular/state';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { LetDirective } from '@rx-angular/template/let';

import { AppConstants } from '@core/constants/app.constants';
import { TDocumentType } from '@core/enums/document-type';
import { Files, IUploadedFile } from '@core/interfaces/claims/files.interface';
import { FilesService } from '@core/services/files.service';
import { ImageErrorStandaloneDirective } from '@shared/directives/image-error-standalone.directive';
import { FilesTotalSizeErrorComponent } from '@shared/standalone/files-total-size-error/files-total-size-error.component';

import {
  FilesListMobileComponent,
} from '../../../../shell-mobile/pages/claim-details/containers/files-list-mobile/files-list-mobile.component';
import { BaseWizardPageComponent } from '../../components/base-wizard-page/base-wizard-page.component';

interface IPlanFilesUploadMobileState {
  files: Files[];
  filesLength: number;
}

@Component({
  selector: 'app-plan-files-upload-mobile',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    LetDirective,
    PortalModule,
    RxIf,
    RxFor,
    ImageErrorStandaloneDirective,
    MatIconModule,
    FilesListMobileComponent,
    FilesTotalSizeErrorComponent,
  ],
  templateUrl: './plan-files-upload-mobile.component.html',
  styleUrls: ['./plan-files-upload-mobile.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanFilesUploadMobileComponent extends BaseWizardPageComponent {
  state = inject(RxState<IPlanFilesUploadMobileState>);
  vm$ = this.state.select();
  accept = [AppConstants.fileTypes.images, AppConstants.fileTypes.documents].flat().join(', ');
  filesForm = this.store.filesForm;

  constructor(
    private readonly store: RegisterPlanStore,
    private readonly filesService: FilesService,
    private readonly fb: FormBuilder,
  ) {
    super();
    this.state.set({
      filesLength: this.filesForm.value.length,
      files: this.filesForm.value,
    });
    this.state.connect(this.filesForm.valueChanges, (state, files) => ({
      ...state,
      files,
      filesLength: files.length,
    }));
  }

  goToReview(): void {
    this.store.wizardNavigationStore.setStep('review');
  }

  handleInputFiles($event: Event): void {
    this.filesService
      .handleFilesInput($event, {
        docType: TDocumentType.Receipt,
      }).subscribe(uploadedFiles => {
        this._saveFiles(uploadedFiles);
      });
  }

  removeFile(index: number): void {
    this.store.removeFile(index);
  }

  private _saveFiles(uploadedFiles: IUploadedFile[]): void {
    const lastFile = this.filesForm.at(-1);
    let fileIndex = lastFile ? (lastFile.value.index + 1) : 0;
    const mappedFiles = uploadedFiles.map((file) => ({
      ...this.filesService.mapFilesToFormFiles(file, fileIndex++),
    }));
    mappedFiles.forEach(mappedFile => {
      this.filesForm.push(this.fb.control(mappedFile), {
        emitEvent: false,
      });
    });
    this.filesForm.markAsDirty();
    this.filesForm.updateValueAndValidity();
  }
}
