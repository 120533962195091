<mat-form-field
  class="form-date"
  [color]="'primary'"
  [class._common-mat-form-field_hide-errors]="!formFieldProvider.showErrors"
  [class._common-mat-form-field_expand-hint]="expandHint"
  [class._common-theme-field]="!!ngControl.value">
  <mat-label *ngIf="label">
    {{ label }}
  </mat-label>
  <input
    #datepickerInput
    matInput
    [matDatepicker]="picker"
    [autocomplete]="autocomplete"
    [max]="max"
    [min]="min"
    [formControl]="ngControl.control"
    [readonly]="readonly"
    [placeholder]="placeholder"
    [required]="required"
    (click)="reOpenCalendar()"
    (focus)="reOpenCalendar()"
    [matDatepickerFilter]="datePickerFilter" />
  <!--         (dateChange)="onChange($event)">-->
  <div
    matSuffix
    class="form-date__suffix">
    <mat-icon
      *ngIf="tooltipText || tooltipAttentionText"
      [matTooltip]="tooltipText || tooltipAttentionText"
      svgIcon="help_outline"></mat-icon>
    <mat-icon
      class="control-error-icon"
      [class.form-date__error-icon-shift-left]="tooltipText">
      error
    </mat-icon>
    <ng-content select="[suffix]"></ng-content>
    <mat-datepicker-toggle
      class="form-date__icon-toggle"
      [for]="picker">
      <mat-icon
        svgIcon="calendar-icon"
        class="form-date__icon"
        matDatepickerToggleIcon></mat-icon>
    </mat-datepicker-toggle>
  </div>
  <mat-datepicker
    [touchUi]="touchUi"
    (closed)="datepickerInput.blur()"></mat-datepicker>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error>
    <app-form-error
      [formControlRef]="formControl"
      [label]="label"></app-form-error>
  </mat-error>
  <mat-error *ngIf="formControl.hasError('dateIsNotValid')">{{ label }} is invalid.</mat-error>
  <mat-error *ngIf="!formControl.hasError('dateIsNotValid') && formControl.hasError('maxDate')">
    {{ label }} should be before
    {{ formControl.errors.maxDate.maxDate | date: "M/dd/yyyy" | dateDayPlusOne: "MM/DD/YYYY" }}.
  </mat-error>
  <mat-error
    *ngIf="
      !formControl.hasError('dateIsNotValid') &&
      !customMaxDateValidation &&
      formControl.hasError('matDatepickerMax')
    ">
    {{ label }} should be before
    {{ formControl.errors.matDatepickerMax.max.toDate() | date: "M/dd/yyyy" | dateDayPlusOne: "MM/DD/YYYY" }}.
  </mat-error>
  <mat-error
    *ngIf="
      !formControl.hasError('dateIsNotValid') &&
      !customMinDateValidation &&
      formControl.hasError('matDatepickerMin')
    ">
    {{ label }} should be after
    {{
      formControl.errors.matDatepickerMin.min.toDate() | date: "M/dd/yyyy" | dateDayMinusOne: "MM/DD/YYYY"
    }}.
  </mat-error>
  <mat-error
    *ngIf="
      !formControl.hasError('dateIsNotValid') &&
      type === TDateFieldType.MinMaxDate &&
      (formControl.hasError('matDatepickerMin') || formControl.hasError('matDatepickerMax'))
    ">
    {{ label }} should be from {{ min | dateCustomFormat }} till {{ max | dateCustomFormat }}.
  </mat-error>
  <mat-error *ngIf="type === TDateFieldType.MyPlanPurchaseDate && formControl.hasError('matDatepickerMax')">
    {{ label }} cannot be later than delivery date.
  </mat-error>
  <mat-error *ngIf="type === TDateFieldType.MyPlanDeliveryDate && formControl.hasError('matDatepickerMin')">
    {{ label }} cannot be earlier than purchase date.
  </mat-error>
  <mat-error *ngIf="formControl.hasError('matDatepickerFilter')">Selected date is not allowed.</mat-error>
</mat-form-field>
