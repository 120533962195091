import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject,Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { take } from 'rxjs/operators';

import { DIALOG_ROUTE_CONFIG } from '@core/constants/app.constants';
import { ISideDialogConfig } from '@core/interfaces/side-dialog.interface';
import { DialogService } from '@core/services/dialog.service';
import { LoaderModule } from '@shared/modules/loader/loader.module';

@Component({
  selector: 'app-side-dialog-with-route',
  standalone: true,
  templateUrl: './side-dialog-with-route.component.html',
  styleUrls: ['./side-dialog-with-route.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
  imports: [
    NgIf,
    AsyncPipe,
    LoaderModule,
    MatIconModule,
  ],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('{{animationTime}}ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('{{animationTime}}ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class SideDialogWithRouteComponent {
  @Input() isVisible = true;
  @Input() set loading(loading: boolean) {
    this.state.set({
      loading,
    });
  }
  @Input() disableClose = false;
  @Input() form: FormGroup;
  @Input() useDirtyFormGuard = false;
  @Input() displayCloseButton = true;

  @Output() closeDialog = new EventEmitter<void>();

  loading$ = this.state.select('loading');
  initialLoading$ = this.state.select('loading').pipe(
    take(2),
  );

  constructor(
    @Inject(DIALOG_ROUTE_CONFIG) public dialogRouteConfig: ISideDialogConfig,
    private readonly dialogService: DialogService,
    private readonly state: RxState<{loading: boolean}>,
  ) { }

  close(): void {
    if (this.useDirtyFormGuard) {
      this.dialogService.canLeaveDefaultConfirmationObservable(this.form).subscribe(canLeave => {
        if (canLeave) {
          this.closeDialog.emit();
        } else {
          return;
        }
      });
    } else {
      this.closeDialog.emit();
    }
  }
}
