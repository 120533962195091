import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { RegisterFppClaimStore } from '../../store/register-fpp-claim.store';

@Component({
  selector: 'app-fpp-claim-getting-started',
  templateUrl: './fpp-claim-getting-started.component.html',
  styleUrls: ['./fpp-claim-getting-started.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FppClaimGettingStartedComponent {
  constructor(private readonly store: RegisterFppClaimStore) {}

  goToConsumer(): void {
    this.store.wizardNavigationStore.setStep('consumer');
  }
}
