import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { AppConstants } from '@core/constants/app.constants';
import { PlanLookup } from '@core/interfaces/plan/plan.interface';
import { ClaimFormService } from '@core/services/claim-form.service';
import { AddressType } from '@shared/modules/claim-dialogs/enums/address-type.enum';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

import { MyPlanApiService } from '../../app/core/api/my-plan-api.service';

@Injectable()
export class VerifyPlanPurchaseService {
  verificationInProgress$$ = new BehaviorSubject(false);
  verificationInProgress$ = this.verificationInProgress$$.asObservable();
  emailCodeSent$$ = new BehaviorSubject(false);
  emailCodeSent$ = this.emailCodeSent$$.asObservable();

  emailForm = this.fb.group({
    confirmationEmail: ['', Validators.compose([Validators.pattern(AppConstants.emailRegEx), Validators.required])],
    validationCode: ['', Validators.required],
  });
  phoneControl = this.fb.control('', Validators.compose([Validators.required, ClaimFormService.phoneNumber]));
  addressForm = this.claimFormService.getAddressFields({}, AddressType.CustomerAddress);

  constructor(
    private readonly fb: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly myPlanApiService: MyPlanApiService,
    private readonly claimFormService: ClaimFormService,
  ) {}

  verifyPlan(
    verificationType: 'email' | 'phone' | 'address',
    params: {planNumber?: string; planCrmRefId?: string},
  ): Observable<PlanLookup> {
    switch (verificationType) {
      case 'email':
        return this._verifyEmailCode(params.planCrmRefId);
      case 'phone':
        return this._verifyPhone(params.planNumber);
      case 'address':
        return this._verifyAddress(params.planNumber);
    }
  }

  sendEmailVerificationCode(planNumber: string): Subscription {
    this.verificationInProgress$$.next(true);
    const confirmationEmail = this.emailForm.value.confirmationEmail;
    return this.myPlanApiService
      .confirmationMail({
        planNumber,
        confirmationEmail,
      })
      .pipe(
        finalize(() => {
          this.verificationInProgress$$.next(false);
        }),
      )
      .subscribe(success => {
        if (success) {
          this.emailCodeSent$$.next(true);
        }
      });
  }

  private _verifyEmailCode(planCrmRefId: string): Observable<PlanLookup> {
    this.verificationInProgress$$.next(true);
    const validationCode = this.emailForm.value.validationCode;
    return this.myPlanApiService
      .verifycode({
        crmId: planCrmRefId,
        validationCode,
      })
      .pipe(
        map(planLookup => {
          if (planLookup.consumer) {
            return planLookup;
          } else {
            throw new Error('Verification code is invalid');
          }
        }),
        catchError(error => {
          this.notificationService.next({
            type: NotificationType.Error,
            message: error.status === HttpStatusCode.BadRequest ? 'Verification code is invalid' : error.message,
          });
          return throwError(error);
        }),
        finalize(() => {
          this.verificationInProgress$$.next(false);
        }),
      );
  }

  private _verifyPhone(planNumber: string): Observable<PlanLookup & {message?: string}> {
    this.verificationInProgress$$.next(true);
    return this.myPlanApiService
      .confirmPhoneNumber({
        phoneNumber: this.phoneControl.value,
        planNumber,
      })
      .pipe(
        map(planLookup => {
          if (planLookup.message) {
            throw new Error(planLookup.message);
          } else {
            return planLookup;
          }
        }),
        catchError(error => {
          this.notificationService.next({
            type: NotificationType.Error,
            message: error.message,
          });
          return throwError(error);
        }),
        finalize(() => {
          this.verificationInProgress$$.next(false);
        }),
      );
  }

  private _verifyAddress(planNumber: string): Observable<PlanLookup & {message?: string}> {
    this.verificationInProgress$$.next(true);
    return this.myPlanApiService
      .confirmAddress({
        planNumber,
        consumerAddress: this.addressForm.getRawValue(),
      })
      .pipe(
        map(planLookup => {
          if (planLookup.message) {
            throw new Error(planLookup.message);
          } else {
            return planLookup;
          }
        }),
        catchError(error => {
          this.notificationService.next({
            type: NotificationType.Error,
            message: error.message,
          });
          return throwError(error);
        }),
        finalize(() => {
          this.verificationInProgress$$.next(false);
        }),
      );
  }
}
