import { NavigatorUAData } from '@th-common/app/route-modules/public/components/public.component';

import { bootstrapMontageApp } from '@core/bootstrap';
import { APP_MOBILE_DETECT } from '@core/constants/app-config.constants';
import { TPortalType } from '@core/enums/portal-type.enum';

import { CONSUMER_APP } from './app/app';
import { AppComponent } from './app/app.component';
import { APP_MOBILE_APP } from './app-mobile/app-mobile.app';
import { AppMobileComponent } from './app-mobile/app-mobile.component';

const detectMobileByUserAgent = (): boolean => {
  const toMatch = [
    /Android/i,
    /iPhone/i,
    /iPad/i,
  ];

  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
};

document.addEventListener('DOMContentLoaded', () => {
  let userAgentData;
  let isMobileByUserAgent;
  try {
    userAgentData = <NavigatorUAData>navigator['userAgentData'];
    isMobileByUserAgent = detectMobileByUserAgent();
  } catch (error) {}
  const isSmallTouchDevice = window.screen.width < 1024 && ('ontouchstart' in window || navigator.maxTouchPoints > 0);
  const isMobileDevice = userAgentData && userAgentData.mobile || isMobileByUserAgent || isSmallTouchDevice;

  if (isMobileDevice) {
    bootstrapMontageApp(AppMobileComponent, {
      ...APP_MOBILE_APP,
      providers: [
        ...APP_MOBILE_APP.providers,
        {
          provide: APP_MOBILE_DETECT,
          useValue: {
            isMobileDevice: isMobileDevice,
            userAgentData: userAgentData ? userAgentData.mobile : null,
            isMobileByUserAgent: isMobileByUserAgent,
            isSmallTouchDevice: isSmallTouchDevice,
            userAgent: navigator.userAgent,
          },
        },
      ],
    }, TPortalType.ConsumerMobile);
  } else {
    bootstrapMontageApp(AppComponent, {
      ...CONSUMER_APP,
      providers: [
        ...CONSUMER_APP.providers,
        {
          provide: APP_MOBILE_DETECT,
          useValue: {
            isMobileDevice: isMobileDevice,
            userAgentData: userAgentData ? userAgentData.mobile : null,
            isMobileByUserAgent: isMobileByUserAgent,
            isSmallTouchDevice: isSmallTouchDevice,
            userAgent: navigator.userAgent,
          },
        },
      ],
    }, TPortalType.Consumer);
  }
});
