import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, Output, ViewChild } from '@angular/core';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { merge } from 'rxjs';
import { map } from 'rxjs/operators';

import { PipesModule } from '@shared/pipes/pipes.module';

interface IExpandableCardState {
  panelToggled: boolean;
}

@Component({
  selector: 'app-expandable-card',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatIconModule, PipesModule],
  templateUrl: './expandable-card.component.html',
  styleUrls: ['./expandable-card.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableCardComponent implements AfterViewInit {
  @Input() cardTitle: string;
  @Input() group: string;

  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;

  @Output() panelToggled$ = this.state.select('panelToggled');

  constructor(
    private readonly state: RxState<IExpandableCardState>,
  ) {
  }

  ngAfterViewInit(): void {
    this.state.connect('panelToggled', merge(
      this.expansionPanel.opened.asObservable().pipe(map(() => true)),
      this.expansionPanel.closed.asObservable().pipe(map(() => false)),
    ));
  }
}
