import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PipesModule } from '@shared/pipes/pipes.module';

import { IClaimProductForm } from '../../../../../../core/interfaces/claim-incident-form.interface';
import { LabelValueComponent } from '../../../../../../shared/components/label-value/label-value.component';

@Component({
  selector: 'app-claim-wizard-incident-card',
  standalone: true,
  imports: [CommonModule, LabelValueComponent, PipesModule],
  templateUrl: './claim-wizard-incident-card.component.html',
  styleUrls: ['./claim-wizard-incident-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimWizardIncidentCardComponent {
  @Input() product: IClaimProductForm;
}
