<div class="dialog dialog--short inquire-claim">
  <app-base-header [headerTitle]="'Claim ' + claim.claimNumber" (closeClick)="onClose()"></app-base-header>
  <div class="dialog__content inquire-claim__content">
    <ng-container *ngIf="claim || (isLoading$ | async); else noResult">
      <div class="inquire-claim__loader-wrapper" [ngClass]="{'in-progress': isLoading$ | async}">
          <div  class="escalation-information-form" id="AddNotes">
            <h2 class="section">Send Note</h2>
            <label for="noteTextArea">Place notes in the box provided below and click Send Note</label>
            <form class="note-form" [formGroup]="form">
              <textarea [cdkTextareaAutosize]="true" trim="blur" id="noteTextArea" formControlName="note"></textarea>
              <div class="note-form__error">
                  <span *ngIf="form.get('note').hasError('maxlength')">
                    Max note length is {{form.get('note').errors.maxlength.requiredLength}} symbols
                  </span>
              </div>
              <div>
                <button type="button" mat-flat-button color="primary"
                        appLoadingButton
                        [isLoading]="isLoading$ | push"
                        (click)="saveNote()"
                        [disabled]="!form.valid">
                  Send Note
                </button>
              </div>
            </form>
          </div>
        <div *ngIf="isLoading$ | async" class="inquire-claim__loader">
          <app-loader class="base-loader"></app-loader>
        </div>
      </div>
    </ng-container>
    <ng-template #noResult>
      <div class="inquire-claim__no-results">
        <h3>No results found</h3>
      </div>
    </ng-template>
  </div>
</div>
