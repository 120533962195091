import { NgComponentOutlet, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardNavigationStore } from '@consumer-core/stores/wizard-navigation/wizard-navigation.store';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { of } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';

import { DialogService } from '@core/services/dialog.service';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import { SideDialogWithRouteComponent } from '@shared/components/side-dialog-with-route/side-dialog-with-route.component';
import { ADDRESS_SUGGEST_SCROLL_SELECTOR } from '@shared/modules/claim-dialogs/components/address-suggest/address-suggest.token';
import { ClaimFormSteps } from '@shared/modules/claim-dialogs/enums/claim-form-steps.enum';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';

import { FppClaimConsumerComponent } from './containers/fpp-claim-consumer/fpp-claim-consumer.component';
import { FppClaimFilesComponent } from './containers/fpp-claim-files/fpp-claim-files.component';
import {
  FppClaimGettingStartedComponent,
} from './containers/fpp-claim-getting-started/fpp-claim-getting-started.component';
import { FppClaimProductsComponent } from './containers/fpp-claim-products/fpp-claim-products.component';
import { RegisterFppClaimStore } from './store/register-fpp-claim.store';

@Component({
  selector: 'app-register-fpp-claim',
  templateUrl: './register-fpp-claim.component.html',
  styleUrls: ['./register-fpp-claim.component.scss'],
  providers: [
    RxState,
    RegisterFppClaimStore,
    WizardNavigationStore,
    SideDialogStateService,
    {
      provide: ADDRESS_SUGGEST_SCROLL_SELECTOR,
      useValue: '.dialog__scroll-view',
    },
  ],
  standalone: true,
  imports: [
    CustomOverlayScrollbarDirective, MatIconModule, SideDialogWithRouteComponent, NgForOf, PushPipe, NgIf, NgComponentOutlet, LetDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterFppClaimComponent implements OnInit {
  dialogData = inject(MAT_DIALOG_DATA);
  navigationList$ = this.store.wizardNavigationStore.select$('navigationList');
  activeStep$ = this.store.wizardNavigationStore.select$('activeStep');
  activeProductIndex$ = this.store.select$('activeProductIndex');
  activeComponent$ = this.store.wizardNavigationStore.select$('activeComponent');
  plan$ = this.store.select$('planDetails').pipe(filter(Boolean));
  steps = ClaimFormSteps;
  isLoading$ = this.store.select$('isLoading');

  isVisible$ = this.sideDialogStateService.isVisible$;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: RegisterFppClaimStore,
    private readonly dialogService: DialogService,
    private readonly sideDialogStateService: SideDialogStateService,
  ) {}

  ngOnInit(): void {
    this._setNavigationList();
    this.store.wizardNavigationStore.setStep('gettingStarted');
    this.store.getPlanDetails(this.dialogData.data);
    this.store.select$('claimSubmitted').pipe(
      filter(Boolean),
      take(1),
    ).subscribe(() => {
      this.close();
    });
  }

  onClose(): void {
    const rootForm = this.store.getForm() as FormGroup;
    const filesForm = this.store.getFilesForm();
    const submitInProgress = this.store.get('submitInProgress');
    if (submitInProgress) {
      return;
    }
    this.dialogService.canLeaveDefaultConfirmationObservable(rootForm, filesForm.dirty).subscribe(canLeave => {
      if (canLeave) {
        this.close();
      } else {
        return;
      }
    });
  }

  close(): void {
    this.sideDialogStateService.close();
  }

  private _setNavigationList(): void {
    this.store.wizardNavigationStore.setNavigationList([
      {
        id: 'gettingStarted',
        title: 'Getting Started',
        valid$: of(true),
        component: FppClaimGettingStartedComponent,
      },
      {
        id: 'consumer',
        title: 'Consumer',
        valid$: this.store.selectFormStatusChanges$('consumer').pipe(
          distinctUntilChanged(),
          map(status => status === 'VALID'),
        ),
        component: FppClaimConsumerComponent,
      },
      {
        id: 'products',
        title: 'Products on Claim',
        valid$: this.store.selectFormStatusChanges$('coveredProducts').pipe(
          distinctUntilChanged(),
          map(status => status === 'VALID'),
        ),
        component: FppClaimProductsComponent,
        children: [],
      },
      {
        id: 'files',
        title: 'Receipt and Images',
        valid$: this.store.filesForm.statusChanges.pipe(
          distinctUntilChanged(),
          map(status => status === 'VALID'),
        ),
        component: FppClaimFilesComponent,
      },
    ]);
  }
}
