<div>
  <div *ngIf="data.showClose" class="prompt-close">
    <mat-icon svgIcon="close" (click)="close()"></mat-icon>
  </div>
  <mat-dialog-content>
    <p class="prompt-title" [innerHTML]="data.message ? (data.message | safe:'html') : 'No data.'"></p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button *ngIf="data.buttonNo" [mat-dialog-close]="false"
            mat-flat-button>{{data.buttonNo ? data.buttonNo : 'Cancel'}}</button>
    <button *ngIf="data.buttonYes" [mat-dialog-close]="true"
            mat-flat-button>{{data.buttonYes}}</button>
  </mat-dialog-actions>
</div>
