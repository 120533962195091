export enum ProductStatus {
  /// <summary>
  /// Active
  /// </summary>
  Active = 223940000,

  /// <summary>
  /// Fully Refunded
  /// </summary>
  FullyRefunded = 223940001,

  /// <summary>
  /// Partially Refunded
  /// </summary>
  PartiallyRefunded = 223940002,

  /// <summary>
  /// Voided
  /// </summary>
  Voided = 223940003,

  /// <summary>
  /// Expired
  /// </summary>
  Expired = 223940004,

  /// <summary>
  /// Replaced
  /// </summary>
  Replaced = 223940005,

  /// <summary>
  /// Pending Verification
  /// </summary>
  PendingVerification = 223940006,

  /// <summary>
  /// Fulfilled
  /// </summary>
  Fulfilled = 223940007,

  /// <summary>
  /// Replacement
  /// </summary>
  Replacement = 223940008,
}
