<div class="claim-wizard-incident-card">
  <app-label-value label="Product Description:">
    <p>{{product.productDescription | appEmptyValue}}</p>
  </app-label-value>
  <app-label-value label="Surface Type:">
    <p>{{product.surfaceTypeName | appEmptyValue}}</p>
  </app-label-value>
  <app-label-value label="Description of Problem:">
    <p>{{product.productIncident.damageDescription | appEmptyValue}}</p>
  </app-label-value>
  <app-label-value label="How It Happened:">
    <p>{{product.productIncident.causeTypeName | appEmptyValue}}</p>
  </app-label-value>
  <app-label-value label="Reported Damage:">
    <p>{{product.productIncident.problemTypeName | appEmptyValue}}</p>
  </app-label-value>
  <app-label-value label="Date Noticed:">
    <p>{{product.productIncident.dateNoticed | dateCustomFormat:'MM/DD/YYYY' | appEmptyValue}}</p>
  </app-label-value>
</div>
