import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';

import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { Files } from '@core/interfaces/claims/files.interface';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

import { RegisterFppClaimStore } from '../../../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import { IClaimProductForm } from '../../../../../core/interfaces/claim-incident-form.interface';
import {
  ExpandableCardGroupComponent,
} from '../../../../../shared/components/expandable-card-group/expandable-card-group.component';
import {
  ExpandableCardComponent,
} from '../../../../../shared/components/expandable-cards/expandable-card/expandable-card.component';
import {
  ClaimIncidentCardComponent,
} from '../../../../shell-mobile/pages/claim-details/components/claim-incident-card/claim-incident-card.component';
import {
  ClaimWizardIncidentCardComponent,
} from '../../../../shell-mobile/pages/claim-details/components/claim-wizard-incident-card/claim-wizard-incident-card.component';
import {
  FilesListMobileComponent,
} from '../../../../shell-mobile/pages/claim-details/containers/files-list-mobile/files-list-mobile.component';
import {
  BaseWizardPageComponent,
} from '../../../register-plan-consumer-mobile/components/base-wizard-page/base-wizard-page.component';
import {
  SelectProductBottomPopupComponent,
} from '../../dialogs/select-product-bottom-popup/select-product-bottom-popup.component';

interface IFppClaimIncidentReviewState {
  product: IClaimProductForm;
  productName: string;
  files: Files[];
  isLastProduct: boolean;
}

@Component({
  selector: 'app-fpp-claim-incident-review',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    ExpandableCardComponent,
    MatButtonModule,
    FilesListMobileComponent,
    ClaimIncidentCardComponent,
    PortalModule,
    ClaimWizardIncidentCardComponent,
    ExpandableCardGroupComponent,
  ],
  templateUrl: './fpp-claim-incident-review.component.html',
  styleUrls: ['./fpp-claim-incident-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class FppClaimIncidentReviewComponent extends BaseWizardPageComponent {
  vm$ = this.state.select();

  constructor(
    private readonly store: RegisterFppClaimStore,
    private readonly state: RxState<IFppClaimIncidentReviewState>,
    private readonly modalDialogService: ModalDialogService,
  ) {
    super();
    this.state.set({
      files: [],
    });
    this.state.connect(this.store.select$('activeProductIndex'), (oldState, activeProductIndex) => {
      const productsForm = (this.store.getForm('coveredProducts') as FormArray);
      const product = productsForm.at(activeProductIndex).getRawValue();
      const files = this.store.getProductFiles(activeProductIndex);
      return {
        ...oldState,
        product,
        productName: this.store.getProductName(activeProductIndex),
        files,
        isLastProduct: activeProductIndex === productsForm.value.length - 1,
      };
    });
  }

  goNext(): void {
    const isLastProduct = this.state.get('isLastProduct');
    if (isLastProduct) {
      this.store.wizardNavigationStore.setStep('uploadReceipt');
    } else {
      this.store.goToProduct(this.store.get('activeProductIndex') + 1);
    }
  }

  addAnotherProduct(): void {
    const isPaperPlan = this.store.get('isPaperPlan');
    const activeProductIndex = this.store.get('activeProductIndex');
    if (isPaperPlan) {
      this.store.addProducts(null);
      this.store.goToProduct(activeProductIndex + 1);
    } else {
      const selectedProductIds = (this.store.getForm('coveredProducts') as FormArray).value.map((product) => product.id);
      this.modalDialogService.open({
        title: 'Add Another Product',
        data: {
          products: this.store.get('planDetails', 'coveredProducts').map(product => ({
            ...product,
            disabled: selectedProductIds.includes(product.id) || product.disabled,
          })),
        },
      }, SelectProductBottomPopupComponent, DIALOG_CONFIG_CUSTOM_ANIMATION).afterClosed().subscribe(result => {
        if (!result) {
          return;
        }

        if (result.addNewProduct) {
          this.store.addProducts(null);
        } else if (result.selectedProducts) {
          this.store.addProducts(result.selectedProducts);
        }
        this.store.goToProduct(activeProductIndex + 1);
      });
    }
  }
}
