import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PipesModule } from '@shared/pipes/pipes.module';

@Component({
  selector: 'app-label-value',
  standalone: true,
  imports: [CommonModule, PipesModule],
  templateUrl: './label-value.component.html',
  styleUrls: ['./label-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelValueComponent {
  @Input() label: string;

}
