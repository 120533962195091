<div
  class="select-plan-for-claim"
  *rxLet="vm$; let vm">
  <app-section-header-with-action
    class="select-plan-for-claim__section"
    sectionTitle="Select a Plan"
    [actionsTemplate]="registerPlanBtnTemplate"></app-section-header-with-action>
  <div class="select-plan-for-claim__plans">
    <ng-container *ngIf="vm.plans.length === 0 && !vm.plansLoadInProgress; else plansTemplate">
      No plans found.
    </ng-container>
  </div>
  <div class="select-plan-for-claim__actions">
    <button
      *ngIf="vm.showLoadMore"
      mat-flat-button
      (click)="loadMore()">
      Load More
    </button>
    <app-loader *rxIf="vm.plansLoadInProgress"></app-loader>
  </div>
  <ng-template #plansTemplate>
    <app-plan-item-card
      class="select-plan-for-claim__card"
      *ngFor="let plan of vm.plans"
      [plan]="plan"
      [disabled]="plan.protectionPlanStatus !== MyProtectionPlanStatus.Registered || plan.isExpired"
      (click)="createClaimForPlan(plan)"></app-plan-item-card>
  </ng-template>
</div>
<ng-template #registerPlanBtnTemplate>
  <button
    mat-icon-button
    (click)="registerPlanDialog()">
    <mat-icon>add</mat-icon>
  </button>
</ng-template>
