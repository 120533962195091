import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RxState } from '@rx-angular/state';
import { RxFor } from '@rx-angular/template/for';
import moment from 'moment';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { AnchorNavigationClass } from '@core/classes/anchor-navigation';
import { RECEIPT_FILES_DROP_AREA } from '@core/constants/app.constants';
import { TDocumentType } from '@core/enums/document-type';
import { Feature } from '@core/enums/feature.enum';
import { MyProtectionPlanStatus } from '@core/enums/plan/my-protection-plan-status.enum';
import { AnchorNavigation } from '@core/interfaces/anchor-navigation/anchor-navigation.interface';
import { Files, IDropArea } from '@core/interfaces/claims/files.interface';
import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import { FeatureService } from '@core/services/feature.service';
import { PlanUtil } from '@core/utils/plan.util';
import { validateArrayMaxFilesSize } from '@shared/common/form-validators';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { BasePlanDetailsModule } from '@shared/modules/base-plan-details/base-plan-details.module';
import {
  ConsumerPlanStatusGridCellComponent,
} from '@shared/modules/base-plan-details/plan-status-grid-cell/consumer-plan-status-grid-cell.component';
import {
  CollapsibleDataBlockComponent,
} from '@shared/modules/collapsible/components/collapsible-data-block/collapsible-data-block.component';
import { DocumentsFilterComponent } from '@shared/modules/files/components/documents-filter/documents-filter.component';
import { FilesForPrintComponent } from '@shared/modules/files/components/files-for-print/files-for-print.component';
import { FilesPreviewNewComponent } from '@shared/modules/files/components/files-preview-new/files-preview-new.component';
import { GridDateCellComponent } from '@shared/modules/grid/components/grid-date-cell/grid-date-cell.component';
import { GridPriceCellComponent } from '@shared/modules/grid/components/grid-price-cell/grid-price-cell.component';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';
import { AnchorNavComponent } from '@shared/modules/navigation/components/anchor-nav/anchor-nav.component';
import {
  ItemDetailsPaginationComponent,
} from '@shared/modules/side-dialog/components/item-details-pagination/item-details-pagination.component';
import {
  SideDialogHeaderComponent,
} from '@shared/modules/side-dialog/components/side-dialog-header/side-dialog-header.component';
import {
  SideDialogLoaderComponent,
} from '@shared/modules/side-dialog/components/side-dialog-loader/side-dialog-loader.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';
import {
  FormFilesDropAreaComponent,
} from '@shared/standalone/file-controls/form-files-drop-area/form-files-drop-area.component';
import { FilesTotalSizeErrorComponent } from '@shared/standalone/files-total-size-error/files-total-size-error.component';
import { SideDialogAnimationComponent } from '@shared/standalone/side-dialog-animation/side-dialog-animation.component';

import { ConsumerClaimDialogService } from '../../../../../core/services/consumer-claim-dialog.service';
import { ConsumerPlanService } from '../../../../../core/services/consumer-plan.service';
import { MyPlanItemStore } from '../../../../../core/store/my-plan/my-plan-item.store';
import { MyPlansStore } from '../../../../../core/store/my-plans/my-plans.store';

interface IPlanDetailsState {
  currentPlanIndex: number;
  plan: PlanDetails;
  singleView: boolean;
  planExpired: boolean;
}

@Component({
  selector: 'app-protection-plan-details',
  standalone: true,
  templateUrl: './protection-plan-details.component.html',
  styleUrls: ['./protection-plan-details.component.scss'],
  providers: [RxState, SideDialogStateService],
  imports: [
    SideDialogAnimationComponent,
    SideDialogLoaderComponent,
    NgIf,
    AsyncPipe,
    FilesTotalSizeErrorComponent,
    MatButtonModule,
    SideDialogHeaderComponent,
    ItemDetailsPaginationComponent,
    MatIconModule,
    AnchorNavComponent,
    CustomOverlayScrollbarDirective,
    BasePlanDetailsModule,
    CollapsibleDataBlockComponent,
    MatProgressSpinnerModule,
    RxFor,
    FormFilesDropAreaComponent,
    PipesModule,
    DocumentsFilterComponent,
    FilesPreviewNewComponent,
    FilesForPrintComponent,
    LoadingButtonDirective,
    MatTooltipModule,
  ],
})
export class ProtectionPlanDetailsComponent implements OnInit, OnDestroy {
  plansPagination$ = this.myPlansStore.select$('pagination').pipe(
    switchMap(pagination =>
      this.myPlansStore.select$('searchRequest', 'pageSize').pipe(
        map(pageSize => ({
          ...pagination,
          pageSize,
        })),
      ),
    ),
  );
  currentPlanIndex$ = this.state.select('currentPlanIndex');
  plan$ = this.myPlanItemStore.select$('plan').pipe(
    map(plan => {
      this.planName = plan ? plan.planInfo.name || plan.planInfo.consumerPlanName : '';
      return plan;
    }),
  );
  loading$ = this.myPlanItemStore.select$('loading');
  filesLoading$ = this.myPlanItemStore.select$('filesLoading');
  files$ = this.myPlanItemStore.select$('files');
  filesLength$ = this.myPlanItemStore.select$('files', 'length');
  isReceiptUploaded = false;
  isReceiptUploaded$ = this.myPlanItemStore.select$('isReceiptUploaded').pipe(
    tap(isReceiptUploaded => {
      this.isReceiptUploaded = isReceiptUploaded;
      if (isReceiptUploaded) {
        this.anchorNavigation.list = [
          ...this.anchorNavigation.list.filter(item => item.anchor !== this.anchorPurchaseReceipt.anchor),
        ];
      } else {
        const isAddedToAnchorNavigationList = this.anchorNavigation.list
          .map(item => item.anchor)
          .includes(this.anchorPurchaseReceipt.anchor);
        if (!isAddedToAnchorNavigationList) {
          this.anchorNavigation.list = [...this.anchorNavigation.list, ...[this.anchorPurchaseReceipt]];
        }
      }
    }),
  );
  filesForm = this.fb.array<Files>([], [Validators.required, validateArrayMaxFilesSize()]);
  receiptFilesLoading$ = this.myPlanItemStore.select$('receiptFilesLoading');
  docType = TDocumentType.Receipt;
  receiptText = 'We do not have a receipt for your plan on file. You may upload a copy of your receipt below.';
  singleView$ = this.state.select('singleView');
  cancellationInProgress$ = this.consumerPlanService.cancellationInProgress$;
  docTypeFilter: TDocumentType[];
  dropAreasList: IDropArea[] = [RECEIPT_FILES_DROP_AREA];
  planUtil = inject(PlanUtil);

  pdfLink$ = this.myPlanItemStore.select$('plan').pipe(
    filter(plan => !!plan),
    filter(plan => plan.planInfo.downloadablePlan),
    map(plan => this.planUtil.getPlanPdfLink(plan.planInfo.id, 'myplan')),
  );
  canStartClaim$ = this.myPlanItemStore.select$('plan').pipe(
    filter(plan => !!plan),
    map(plan =>
      plan.planInfo.protectionPlanStatus === MyProtectionPlanStatus.Registered
      && !moment().isAfter(plan.planInfo.expirationDate, 'day'),
    ),
  );
  planExpired$ = this.myPlanItemStore.select$('plan').pipe(
    filter(plan => !!plan),
    map(plan => moment().isAfter(plan.planInfo.expirationDate, 'day')),
  );

  isAllowedCancelPlan = this.featureService.allowed(Feature.CancelProtectionPlan);

  planStatuses = MyProtectionPlanStatus;

  anchorPurchaseReceipt: AnchorNavigation = { name: 'Purchase Receipt', anchor: 'PurchaseReceipt' };
  anchorNavigation = new AnchorNavigationClass({
    currentAnchor: 'Consumer',
    list: [
      { name: 'Consumer', anchor: 'Consumer' },
      { name: 'Protection Plan', anchor: 'PlanInformation' },
      { name: 'Covered Products', anchor: 'CoveredProducts' },
      { name: 'Photos/Documents', anchor: 'PlanDocuments' },
    ],
  });

  planColumns = [
    new GridColumnModel('consumerPlanName', 'Plan Number'),
    new GridColumnModel('parentPlanName', 'Plan Description'),
    new GridColumnModel('purchaseDate', 'Purchase', GridDateCellComponent),
    new GridColumnModel('deliveryDate', 'Delivery', GridDateCellComponent),
    new GridColumnModel('planPrice', 'Plan Price', GridPriceCellComponent),
    new GridColumnModel('purchasePrice', 'Furniture Price', GridPriceCellComponent),
    new GridColumnModel('protectionPlanStatus', 'Plan Status', ConsumerPlanStatusGridCellComponent),
  ];

  coveredProductColumns = [
    new GridColumnModel('vendor', 'Vendor'),
    new GridColumnModel('name', 'Name'),
    new GridColumnModel('sku', 'SKU'),
  ];
  planName: string;

  TDocumentType = TDocumentType;
  dialogData = inject(MAT_DIALOG_DATA);

  constructor(
    private readonly sideDialogStateService: SideDialogStateService,
    private readonly myPlanItemStore: MyPlanItemStore,
    private readonly consumerClaimDialogService: ConsumerClaimDialogService,
    private readonly consumerPlanService: ConsumerPlanService,
    private readonly myPlansStore: MyPlansStore,
    private readonly featureService: FeatureService,
    private readonly state: RxState<IPlanDetailsState>,
    private readonly fb: FormBuilder,
  ) {
    this.state.set({
      singleView: this.dialogData.data.singleView,
    });
    this.state.connect(
      'currentPlanIndex',
      this.myPlanItemStore.select$('plan').pipe(
        filter(planDetails => !!planDetails),
        switchMap(planDetails =>
          this.myPlansStore.select$('plans').pipe(
            map(plans => plans.findIndex(plan => plan.id === planDetails.planInfo.id)),
          ),
        ),
      ),
    );
  }

  ngOnInit(): void {
    this.myPlanItemStore.setCurrentId(this.dialogData.data.planId);
    this.myPlanItemStore.getItem();
    // this.canStartClaim = plan.planInfo.protectionPlanStatus === MyProtectionPlanStatus.Registered;
  }

  ngOnDestroy(): void {
    this.myPlanItemStore.destroy();
  }

  onClose(): void {
    this.sideDialogStateService.close();
  }

  registerClaim(plan: PlanDetails): void {
    this.consumerClaimDialogService.openCreateFppClaimDialog(plan);
  }

  cancelPlan(planDetails: PlanDetails): void {
    if (this.isAllowedCancelPlan) {
      this.consumerPlanService.cancelPlan(planDetails);
    }
  }

  setPage({ page, itemIndex }: {page: number; itemIndex: number}): void {
    this.myPlanItemStore.changePlanDetailsPage(page, itemIndex);
  }

  changeItemIndex(itemIndex: number): void {
    const plans = this.myPlansStore.get('plans');
    this.myPlanItemStore.setCurrentId(plans[itemIndex].id);
    this.myPlanItemStore.getItem();
  }

  allFilesLoaded(): void {
    this.myPlanItemStore.allFilesLoaded();
  }

  uploadReceiptFiles(): void {
    if (this.filesForm.valid) {
      this.myPlanItemStore.uploadReceiptFiles(this.filesForm.value).subscribe(() => {
        this.filesForm.clear();
        this.anchorNavigation.list = [
          ...this.anchorNavigation.list.filter(item => item.anchor !== this.anchorPurchaseReceipt.anchor),
        ];
      });
    }
  }

  docTypeFilterChanged(filterValue: TDocumentType[]): void {
    this.docTypeFilter = filterValue;
  }

  removeFiles(fileIndex: number): void {
    const updatedFiles = this.filesForm.value.filter(file => fileIndex !== file.index);
    this.filesForm.clear();
    updatedFiles.forEach(file => {
      this.filesForm.push(this.fb.control(file));
    });
  }
}
