<div>
  <div class="plan-register-success__header">
    <p class="plan-register-success__title">Plan Located</p>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <p>Protection plan <strong>{{data.data.planInfo.consumerPlanName}}</strong> has been successfully located. It may take a few minutes to show up in the portal.</p>
    <p>To start a new claim related to this plan, click the Start New Claim button below.</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button (click)="registerClaim()" mat-stroked-button color="primary">Start New Claim</button>
    <button (click)="close()" mat-flat-button color="primary">Ok</button>
  </mat-dialog-actions>
</div>

