import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

import { IPurchaseSocket } from '@core/interfaces/purchase/purchase.interface';
import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';

import { ConsumerClaimDialogService } from '../../../core/services/consumer-claim-dialog.service';

@Component({
  selector: 'app-register-purchase-success',
  templateUrl: './register-purchase-success.component.html',
  styleUrls: ['./register-purchase-success.component.scss'],
  standalone: true,
  imports: [DialogCoreModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterPurchaseSuccessComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {data: IPurchaseSocket},
    private readonly dialogRef: MatDialogRef<any>,
    private readonly consumerClaimDialogService: ConsumerClaimDialogService,
  ) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  registerClaim() {
    this.close();
    this.consumerClaimDialogService.openCreateClaimDialog(this.data.data);
  }
}
