import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RxFor } from '@rx-angular/template/for';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { BehaviorSubject } from 'rxjs';

import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';

import {
  BottomPopupDialogAnimationComponent,
} from '../../../../../shared/components/bottom-popup-dialog-animation/bottom-popup-dialog-animation.component';
import {
  PaginationIndicatorComponent,
} from '../../../../../shared/components/pagination-indicator/pagination-indicator.component';

@Component({
  selector: 'app-how-to-find-plan-dialog',
  standalone: true,
  imports: [
    CommonModule,
    BottomPopupDialogAnimationComponent,
    PushPipe,
    MatIconModule,
    LetDirective,
    MatButtonModule,
    RxFor,
    PaginationIndicatorComponent,
  ],
  providers: [SideDialogStateService],
  templateUrl: './how-to-find-plan-dialog.component.html',
  styleUrls: ['./how-to-find-plan-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowToFindPlanDialogComponent {
  activePlanExampleIndex$$ = new BehaviorSubject(0);
  activePlanExampleIndex$ = this.activePlanExampleIndex$$.asObservable();

  planExamples = [
    {
      title: 'Plan Document Example 1',
      imageSrc: 'assets/consumer-mobile/Plan-Examples-01.png',
    },
    {
      title: 'Plan Document Example 2',
      imageSrc: 'assets/consumer-mobile/Plan-Examples-02.png',
    },
    {
      title: 'Military Pamphlet',
      imageSrc: 'assets/consumer-mobile/Plan-Examples-03.png',
    },
  ];

  constructor(
    private readonly sideDialogStateService: SideDialogStateService,
  ) {}


  nextPlanExample(): void {
    if (this.activePlanExampleIndex$$.value < this.planExamples.length - 1) {
      this.activePlanExampleIndex$$.next(this.activePlanExampleIndex$$.getValue() + 1);
      return;
    }
  }

  previousPlanExample(): void {
    if (this.activePlanExampleIndex$$.value > 0) {
      this.activePlanExampleIndex$$.next(this.activePlanExampleIndex$$.getValue() - 1);
      return;
    }
  }

  close(): void {
    this.sideDialogStateService.close();
  }
}
