import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { CrudApiBaseService } from '@core/api/crud-api.base';
import { PlanApiInterface } from '@core/interfaces/api/plan-api.interface';
import { Files } from '@core/interfaces/claims/files.interface';
import {
  IConfirmAddress,
  IConfirmEmail,
  IConfirmPhoneNumber,
  IVerifyCode,
} from '@core/interfaces/confirmation.interface';
import { PlanDetails, PlanLookup, Refund } from '@core/interfaces/plan/plan.interface';
import { FileService } from '@core/services/file.service';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class MyPlanApiService extends CrudApiBaseService<any> implements PlanApiInterface {
  endpoint = 'myplan';

  constructor(private notification: NotificationService, private readonly fileService: FileService) {
    super();
  }

  getPdf(planId: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(this.getUrl(`${planId}/myplan.pdf`), { observe: 'response', responseType: 'blob' });
  }

  getFiles(id: number) {
    return this.fileService.getFileViewerWidth().pipe(
      switchMap(width =>
        this.httpClient
          .get<Omit<Files, 'index'>[]>(this.getUrl(`${id}/files`), {
          params: {
            width,
          },
        })
          .pipe(
            map(files =>
              this.fileService.sortFiles(files).map((file, index) => ({
                ...file,
                index,
              })),
            ),
          ),
      ),
    );
  }

  findMyPlan(planNumber: string) {
    return this.httpClient.get<PlanLookup>(this.getUrl(`findMyPlan/${planNumber}`));
  }

  detailed(id: number) {
    return this.httpClient.get<PlanDetails>(this.getUrl(`${id}/detailed`));
  }

  confirmationMail(data: IConfirmEmail) {
    return this.httpClient.post<boolean>(this.getUrl('confirmationmail'), data);
  }

  confirmPhoneNumber(data: IConfirmPhoneNumber) {
    return this.httpClient.post<PlanLookup & {message?: string}>(this.getUrl('confirm-phone-number'), data);
  }

  confirmAddress(data: IConfirmAddress) {
    return this.httpClient.post<PlanLookup & {message?: string}>(this.getUrl('confirm-address'), data);
  }

  verifycode(data: IVerifyCode) {
    return this.httpClient.post<PlanLookup>(this.getUrl('verifycode'), data);
  }

  me(): Observable<any> {
    return this.httpClient.get(this.getUrl('me'));
  }

  registerMyPlan(data: any, files: Files[]) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('formfiles', files[i].originalFile);
    }

    formData.append('model', JSON.stringify(data));

    return this.httpClient.post<boolean>(this.getUrl('registermyplan'), formData).pipe(
      tap(() => {
        this.notification.next({
          message: 'Your protection plan has been submitted for processing. You will be notified as soon as it has been located. During time of high volume, this could take a few minutes. If you don\'t receive a notification, please check back later to find it under MY PLANS.', // eslint-disable-line max-len
          type: NotificationType.Success,
          duration: 20000,
        });
      }),
    );
  }

  refund(id: number | string): Observable<Refund> {
    return this.httpClient.get<Refund>(this.getUrl(`${id}/refund`));
  }

  cancelPlan(id: string, cancellationReason: any): Observable<any> {
    return this.httpClient.put(this.getUrl(`${id}/cancel`), cancellationReason);
  }

  getReceipt(consumerPlanName: string) {
    return this.fileService.getFileViewerWidth().pipe(
      switchMap(width =>
        this.httpClient.get<Files[]>(this.getUrl(`${consumerPlanName}/receipt`), {
          params: {
            width,
          },
        }),
      ),
    );
  }
}
