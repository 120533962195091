import { NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit, Optional,
  Output, Self,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { RxState } from '@rx-angular/state';
import { RxFor } from '@rx-angular/template/for';
import { LetDirective } from '@rx-angular/template/let';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { map, withLatestFrom } from 'rxjs/operators';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { Pagination } from '@core/interfaces/ui/pagination.interface';
import { APP_FORM_FIELD_PROVIDER } from '@core/providers/form-field.provider';
import { Mat3ScrollLoadingOverlayDirective } from '@shared/directives/mat3-scroll-loading-overlay.directive';
import { Mat3SelectInfiniteScrollDirective } from '@shared/directives/mat3-select-infinite-scroll.directive';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { FormControlErrorPipe } from '@shared/pipes/form-control-error.pipe';

interface ISelectState {
  data: unknown[];
  dataLength: number;
  outOfScopeValue: unknown;
  selectedItem: unknown;
  showSelectedOutOfListItem: boolean;
}

@Component({
  selector: 'app-form-select-new',
  standalone: true,
  templateUrl: './form-select-new.component.html',
  styleUrls: ['./form-select-new.component.scss'],
  imports: [
    MatSelectModule,
    NgIf,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxTrimDirectiveModule,
    LetDirective,
    RxFor,
    Mat3ScrollLoadingOverlayDirective,
    Mat3SelectInfiniteScrollDirective,
    LoaderModule,
    FormControlErrorPipe,
  ],
  providers: [RxState],
})
export class FormSelectNewComponent<T = unknown> extends BaseFormControlElement implements OnInit {
  @Input() set data(data: T[]) {
    let outOfScopeValue;
    const dataLength = data ? data.length : 0;

    if (dataLength > 0) {
      const availableValues = data.map(dataItem => dataItem[this.valueField]);
      if (availableValues.includes(this.ngControl.value)) {
        outOfScopeValue =  null;
      } else {
        outOfScopeValue = this.ngControl.value;
      }
    }

    this.state.set({
      data,
      dataLength: data ? data.length : 0,
      outOfScopeValue,
    });
  }

  @Input()
    optionColumns: GridColumnModel[];

  @Input()
    pagination: Pagination;

  @Input()
    label: string;

  @Input()
    emptyLabelPrefix = 'Choose';

  @Input()
    optionsLabel: string;

  @Input()
    showEmptyOption = true;

  @Input()
    multiple = false;

  @Input()
    placeholder: string;

  @Input()
    disabled: boolean;

  @Input()
    readonly: boolean;

  @Input()
    required: boolean;

  @Input()
    titleField = 'name';

  @Input()
    subTitleField = null;

  @Input()
    valueField = 'id';

  @Input()
    loading = false;

  @Input()
    showSearch = false;

  @Input()
    loadOnScroll = false;

  @Input() showOutOfScopeValue = false;

  @Input() set runClearSearch(value: boolean) {
    if (value) {
      this.clearSearch();
    }
  }

  @Input() enableDiscardingSearchResultsOnBlur = true;

  @Output()
    itemSelected = new EventEmitter<T>();

  @Output()
    selectSearch = new EventEmitter<string>();

  @Output()
    selectLoadNextPage = new EventEmitter<number>();

  vm$ = this.state.select();

  searchString = '';

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('matSelectInstance') matSelectInstance: MatSelect;

  constructor(
    private readonly state: RxState<ISelectState>,
    @Inject(APP_FORM_FIELD_PROVIDER) public readonly formFieldProvider: {showErrors: boolean},
    @Optional() @Self() public ngControl: NgControl,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.state.connect('selectedItem',
      this.ngControl.valueChanges,
      (oldState, value) => (oldState.data || []).find(item => item[this.valueField] === value));
    this.state.connect(
      'showSelectedOutOfListItem',
      this.state.select('data').pipe(
        withLatestFrom(this.state.select('selectedItem')),
        map(([data, selectedItem]) => !data.map(item => item[this.valueField]).includes(selectedItem[this.valueField])),
      ),
    );
  }

  /**
   * Emits a whole item object on select
   */
  itemSelect($event: MatSelectChange): void {
    const item = this._findItem($event.value);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.itemSelected.emit(item);
  }

  /**
   * Closes the dropdown on click outside
   * Note: Fixes issue with multiple dropdowns opened on mobile
   */
  onClickedOutside($event: unknown, matSelect: MatSelect): void {
    matSelect.close();
  }

  searchEvent(event: Event): void {
    event.stopPropagation();
    const searchString = this.searchString.trim();
    this.search(searchString);
    this.searchString = searchString;
  }

  clearSearch(): void {
    this.searchString = '';
    this.search(this.searchString);
  }

  search(searchString: string): void {
    if (this.matSelectInstance.panel) {
      this.matSelectInstance.panel.nativeElement.scrollTop = 0;
    }
    this.selectSearch.emit(searchString);
  }

  onScrollDown(): void {
    this.loadNextPage();
  }

  loadNextPage(): void {
    if (this.pagination.page < this.pagination.pageCount) {
      this.selectLoadNextPage.emit(++this.pagination.page);
    }
  }

  /**
   * Finds item in data list with a corresponding value
   * @param value Lookup value
   */
  private _findItem(value: string): unknown {
    const data = this.state.get('data') || [];
    return data.find(selectItem => selectItem[this.valueField] === value);
  }

  openedHandler(isOpened: boolean): void {
    if (this.searchString.length && !isOpened && this.enableDiscardingSearchResultsOnBlur) {
      this.clearSearch();
    }
  }

  modelChanged(data: Record<string, unknown>): void {
    if (!data.length && this.enableDiscardingSearchResultsOnBlur) {
      this.clearSearch();
    }
  }
}
