<div class="plan-finish">
  <p class="plan-finish__header">You’re All Done!</p>
  <img class="plan-finish__image" src="assets/consumer-mobile/happy-finish.svg" alt="Finish">
  <div class="plan-finish__message">
    <p><b>This plan was successfully added to your account.</b></p>
    <p>If you need to reference this plan in the future, you will find it under <b>My Plan</b>.</p>
  </div>
  <ng-container *cdkPortal>
    <button type="button" mat-flat-button (click)="closeDialog()">Dashboard</button>
  </ng-container>
</div>
