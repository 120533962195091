import { ChangeDetectionStrategy, Component,inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA, MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-claim-success-mobile',
  standalone: true,
  templateUrl: './claim-success-mobile.component.html',
  styleUrls: ['./claim-success-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
  ],
})
export class ClaimSuccessMobileComponent {
  data: {data: {claim: {claimNumber: string}}} = inject(MAT_DIALOG_DATA);
  constructor(
    private readonly dialogRef: MatDialogRef<any>,
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
