import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { RxIf } from '@rx-angular/template/if';
import { LetDirective } from '@rx-angular/template/let';
import moment from 'moment';
import { combineLatestWith, finalize } from 'rxjs/operators';

import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { MyProtectionPlanStatus } from '@core/enums/plan/my-protection-plan-status.enum';
import { Plan, PlanDetails } from '@core/interfaces/plan/plan.interface';
import { Pagination } from '@core/interfaces/ui/pagination.interface';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { LoaderModule } from '@shared/modules/loader/loader.module';

import { MyPlanApiService } from '../../../../../../app/core/api/my-plan-api.service';
import { RegisterFppClaimStore } from '../../../../../../app/pages/register-fpp-claim/store/register-fpp-claim.store';
import { PlanItemCardComponent } from '../../../../../shared/components/plan-item-card/plan-item-card.component';
import {
  SectionHeaderWithActionComponent,
} from '../../../../../shared/components/section-header-with-action/section-header-with-action.component';
import {
  RegisterPlanConsumerMobileComponent,
} from '../../../register-plan-consumer-mobile/register-plan-consumer-mobile.component';

interface ISelectPlanForClaimComponentState {
  plans: Plan[];
  pagination: Pagination;
  plansLoadInProgress: boolean;
  showLoadMore: boolean;
}

@Component({
  selector: 'app-select-plan-for-claim',
  standalone: true,
  imports: [
    CommonModule,
    SectionHeaderWithActionComponent,
    MatButtonModule,
    MatIconModule,
    PlanItemCardComponent,
    LetDirective,
    LoaderModule,
    RxIf,
  ],
  templateUrl: './select-plan-for-claim.component.html',
  styleUrls: ['./select-plan-for-claim.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RxState,
  ],
})
export class SelectPlanForClaimComponent implements OnInit {
  vm$ = this.state.select();

  MyProtectionPlanStatus = MyProtectionPlanStatus;

  constructor(
    private readonly state: RxState<ISelectPlanForClaimComponentState>,
    private readonly myPlanApiService: MyPlanApiService,
    private readonly store: RegisterFppClaimStore,
    private readonly modalDialogService: ModalDialogService,
  ) {
    this.state.set({
      plans: [],
    });
    this.state.connect(
      this.state.select('plans').pipe(
        combineLatestWith(
          this.state.select('plansLoadInProgress'),
          this.state.select('pagination'),
        ),
      ),
      (oldState, [plans, loading, pagination]) => ({
        ...oldState,
        plans,
        loading,
        showLoadMore: pagination.page < pagination.pageCount && !loading && plans.length > 0,
      }));
  }

  ngOnInit(): void {
    this._getPlans();
  }

  registerPlanDialog(): void {
    this.modalDialogService.open({
      title: 'Find Your Plan',
    }, RegisterPlanConsumerMobileComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);
  }

  createClaimForPlan(plan: Plan): void {
    if (plan.protectionPlanStatus !== MyProtectionPlanStatus.Registered) {
      return;
    }

    this.store.wizardNavigationStore.setStep('guidance');
    this.store.getPlanDetails({
      planInfo: plan,
    } as PlanDetails);
  }

  loadMore(): void {
    const nextPage = this.state.get('pagination').page + 1;
    if (nextPage <= this.state.get('pagination').pageCount) {
      this._getPlans(nextPage);
    }
  }

  private _getPlans(page = 1): void {
    this.state.set({
      plansLoadInProgress: true,
    });
    const searchRequest = {
      page,
      pageSize: 10,
      orderBy: [],
    };
    this.myPlanApiService.all(searchRequest).pipe(
      finalize(() => {
        this.state.set({
          plansLoadInProgress: false,
        });
      }),
    ).subscribe(result => {
      this.state.set(oldState => ({
        ...oldState,
        plans: oldState.plans.concat(result.items).map(plan => ({
          ...plan,
          isExpired: moment().isAfter(plan.expirationDate, 'day'),
        })),
        pagination: {
          totalCount: result.totalCount,
          pageCount: result.pageCount,
          page: result.page,
        },
      }));
    });
  }
}
