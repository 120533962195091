<div class="consumer-card" *rxLet="vm$; let vm">
  <app-label-value label="Consumer:">
    <p>{{vm.consumerInfo.firstName + ' ' + vm.consumerInfo.lastName | appEmptyValue}}</p>
  </app-label-value>
  <app-label-value label="Contact ID:">
    {{vm.consumerInfo.contactId | appEmptyValue}}
  </app-label-value>
  <app-label-value label="Email:">
    <p>{{vm.consumerInfo.emailAddress | appEmptyValue}}</p>
  </app-label-value>
  <div class="consumer-card__phones">
    <app-label-value label="Mobile Phone:">
      <p>{{vm.consumerInfo.mobilePhone | appEmptyValue}}</p>
    </app-label-value>
    <app-label-value label="Home Phone:">
      <p>{{vm.consumerInfo.homePhone | appEmptyValue}}</p>
    </app-label-value>
    <app-label-value label="Work Phone:">
      <p>{{vm.consumerInfo.workPhone | appEmptyValue}}</p>
    </app-label-value>
  </div>
  <app-label-value label="Address:">
    <p [innerHTML]="vm.addressString | safe:'html'"></p>
  </app-label-value>
  <app-label-value *ngIf="vm.serviceAddressString" label="Service Address:">
    <p [innerHTML]="vm.serviceAddressString | safe:'html'"></p>
  </app-label-value>
</div>
