import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioDefaultOptions } from '@angular/material/radio';

const matRadioDefaultOptions: MatRadioDefaultOptions = {
  color: 'primary',
};

export const MAT3_RADIO_DEFAULT_OPTIONS = {
  provide: MAT_RADIO_DEFAULT_OPTIONS,
  useValue: matRadioDefaultOptions,
};
