import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';

const mat3TooltipsConfig: Partial<MatTooltipDefaultOptions> = {
  disableTooltipInteractivity: true,
  position: 'above',
};

export const MAT3_TOOLTIPS_CONFIG = {
  provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
  useValue: mat3TooltipsConfig,
};
