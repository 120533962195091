<div class="plan-review">
  <p class="plan-review__title">Plan Review</p>
  <app-expandable-card-group sectionTitle="Plan Details">
    <app-expandable-card cardTitle="Plan Purchase Details">
      <div class="plan-card">
        <app-label-value label="Receipt Number:">
          <p>{{formValue.consumerPlan.receiptNumber | appEmptyValue}}</p>
        </app-label-value>
        <app-label-value label="Plan Price:">
          <p>{{formValue.consumerPlan.planPrice | appEmptyValue | currency}}</p>
        </app-label-value>
        <app-label-value label="Purchase Date:">
          <p>{{formValue.consumerPlan.purchaseDate | dateCustomFormat | appEmptyValue}}</p>
        </app-label-value>
        <app-label-value label="Delivery Date:">
          <p>{{formValue.consumerPlan.deliveryDate | dateCustomFormat | appEmptyValue}}</p>
        </app-label-value>
      </div>
    </app-expandable-card>
    <app-expandable-card cardTitle="Contact Info">
      <div class="consumer-card">
        <app-label-value label="Consumer:">
          <p>{{formValue.consumer.info.firstName + ' ' + formValue.consumer.info.lastName | appEmptyValue}}</p>
        </app-label-value>
        <app-label-value label="Contact ID:">
          {{formValue.consumer.info.contactId | appEmptyValue}}
        </app-label-value>
        <app-label-value label="Email:">
          <p>{{formValue.consumer.info.emailAddress | appEmptyValue}}</p>
        </app-label-value>
        <div class="consumer-card__phones">
          <app-label-value label="Mobile Phone:">
            <p>{{formValue.consumer.info.mobilePhone | appEmptyValue}}</p>
          </app-label-value>
          <app-label-value label="Home Phone:">
            <p>{{formValue.consumer.info.homePhone | appEmptyValue}}</p>
          </app-label-value>
          <app-label-value label="Work Phone:">
            <p>{{formValue.consumer.info.workPhone | appEmptyValue}}</p>
          </app-label-value>
        </div>
        <app-label-value label="Address:">
          <p [innerHTML]="addressString | safe:'html'"></p>
        </app-label-value>
        <app-label-value label="Service Address:">
          <p [innerHTML]="serviceAddressString | safe:'html'"></p>
        </app-label-value>
      </div>
    </app-expandable-card>
    <app-expandable-card cardTitle="Receipt">
      <div class="files-card">
        <img *rxFor="let file of files"
             appImageErrorStandalone
             [src]="file.thumbnailUrl" [alt]="file.fileName">
      </div>
      <p *rxIf="files.length === 0">No files</p>
    </app-expandable-card>
  </app-expandable-card-group>
  <ng-container *cdkPortal>
    <button mat-flat-button
            (click)="submit($event)"
            appLoadingButton
            [isLoading]="submitInProgress$ | push">Submit</button>
  </ng-container>
</div>
