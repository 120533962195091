import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';

import { Address } from '@core/interfaces/address.interface';
import { BaseConsumer } from '@core/interfaces/claims/claimDetails.interface';
import { PipesModule } from '@shared/pipes/pipes.module';

import { LabelValueComponent } from '../../label-value/label-value.component';
import { ExpandableCardComponent } from '../expandable-card/expandable-card.component';

interface IConsumerInfoExpandableCardState {
  consumerInfo: BaseConsumer;
  addressString: string;
  serviceAddressString: string;
}

@Component({
  selector: 'app-consumer-info-card',
  standalone: true,
  imports: [CommonModule, ExpandableCardComponent, LabelValueComponent, PipesModule, LetDirective],
  templateUrl: './consumer-info-card.component.html',
  styleUrls: ['./consumer-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumerInfoCardComponent {
  @Input() set consumerInfo(consumerInfo: BaseConsumer) {
    this.state.set({
      consumerInfo,
    });
  }

  @Input() set consumerAddress(consumerAddress: Address) {
    this.state.set({
      addressString: this._parseAddress(consumerAddress),
    });
  }

  @Input() set serviceAddress(serviceAddress: Address) {
    this.state.set({
      serviceAddressString: serviceAddress ? this._parseAddress(serviceAddress) : '',
    });
  }

  vm$ = this.state.select();

  constructor(
    private readonly state: RxState<IConsumerInfoExpandableCardState>,
  ) {
    this.state.set({
      addressString: '',
      serviceAddressString: '',
    });
  }

  private _parseAddress(address: Address): string {
    return [
      [
        address.streetAddress1,
        address.streetAddress2,
      ].filter(value => !!value).join(', '),
      [
        address.city,
        [
          address.stateProvince,
          address.postalCode,
        ].filter(value => !!value).join(' '),
      ].filter(value => !!value).join(', '),
    ].join('<br>');
  }
}
