import { MAT3_CHECKBOX_DEFAULT_OPTIONS } from './checkbox.provider';
import { MAT3_FORM_FIELD_DEFAULT_OPTIONS } from './form-field.provider';
import { MAT3_RADIO_DEFAULT_OPTIONS } from './radio-button.provider';
import { MAT3_TABS_CONFIG } from './tabs.provider';
import { MAT3_TOOLTIPS_CONFIG } from './tooltip.provider';

export const APP_MATERIAL3_PROVIDERS = [
  MAT3_RADIO_DEFAULT_OPTIONS,
  MAT3_CHECKBOX_DEFAULT_OPTIONS,
  MAT3_TABS_CONFIG,
  MAT3_FORM_FIELD_DEFAULT_OPTIONS,
  MAT3_TOOLTIPS_CONFIG,
];
